import React, { useEffect, useState } from "react";
import {
  Image,
  Card,
  Row,
  Col,
  Layout,
  Tree,
  Button,
  Space,
  Modal,
  Form,
  Input,
  Select,
  Divider,
  message,
  Upload,
  Radio,
} from "antd"; //antd components
import axios from "axios";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import selectCard from "../../assets/images/icons/check.svg";
import arrow_left from "../../assets/images/icons/arrow_left.svg";
import arrow_right_disable from "../../assets/images/icons/arrow_right_disable.svg";
import arrow_back from "../../assets/images/icons/arrow_back_ios.svg";
import cornerDownRight from "../../assets/images/icons/corner-down-right.svg";
import frameworkAgreement from "../../assets/images/icons/framework_agreement.svg";
import frameworkAgreementSelected from "../../assets/images/icons/framework_agreement_selected.svg";
import supplement from "../../assets/images/icons/supplement.svg";
import supplementSelected from "../../assets/images/icons/supplement_selected.svg";
import ccn from "../../assets/images/icons/ccn.svg";
import ccnSelected from "../../assets/images/icons/ccn_selected.svg";
import sow from "../../assets/images/icons/sow.svg";
import sowSelected from "../../assets/images/icons/sow_selected.svg";
import cr from "../../assets/images/icons/cr.svg";
import crSelected from "../../assets/images/icons/cr_selected.svg";
import others from "../../assets/images/icons/others.svg";
import othersSelected from "../../assets/images/icons/othersSelected.svg";
import FolderImg from "../../assets/images/icons/folder.svg";
import confirmation from "../../assets/images/icons/confirmation.svg";
import "../../assets/style/createcontract.css";
import { treeDataApi } from "../../api/suppliersApi";
import { getContractTemplateApi } from "../../api/contractTemplateApi";
import { getAllWorkflowsApi } from "../../api/workflowApi";
import {
  getSingleContractApi,
  contractExtractionApi,
} from "../../api/contractsApi";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  SupplierTreeDataAtom,
  HeaderChangerAtom,
  RoleAccessAtom,
  userDataAtom,
  TabChangerAtom,
} from "../../store/store";
import {
  CancelButton,
  UploadButton,
  CreateButton,
  CreateButtonShort,
  NextButton,
} from "../GlobalButton";
import PopupModal from "../../PopupModal";
import ModalMessage from "../../ModalMessage";
import CustomLoader from "../CustomLoader";

const { TreeNode } = Tree;
const renderTreeNodesWithChild = (data) =>
  data &&
  data.length !== 0 &&
  data.map((item) => {
    // console.log("children", item);
    if (
      item.children !== "undefined" && item.children
        ? item.children.length !== 0
        : ""
    ) {
      return (
        <>
          <TreeNode
            title={
              <div
                className="title-div"
                style={{ display: "inline-block", width: "100%" }}
              >
                <span>
                  {item.contract_name ? item.contract_name : item.supplier_name}
                </span>{" "}
              </div>
            }
            icon={
              item.contract_type === "Supplier" ? (
                <>
                  <span>
                    <Image src={FolderImg} preview={false} width={15} />
                  </span>
                </>
              ) : item.contract_type === "MSA" ? (
                <>
                  <span>
                    <Image
                      src={frameworkAgreement}
                      preview={false}
                      width={15}
                    />
                  </span>
                </>
              ) : item.contract_type === "Supplement" ? (
                <>
                  <span>
                    <Image src={supplement} preview={false} width={20} />
                  </span>
                </>
              ) : item.contract_type === "Sub-Supplement" ? (
                <>
                  <span>
                    <Image src={supplement} preview={false} width={20} />
                  </span>
                </>
              ) : item.contract_type === "SOW" ? (
                <>
                  <span>
                    <Image src={sow} preview={false} width={20} />
                  </span>
                </>
              ) : item.contract_type === "CR" ? (
                <>
                  <span>
                    <Image src={cr} preview={false} width={20} />
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <Image src={others} preview={false} width={20} />
                  </span>
                </>
              )
            }
            key={item._id}
            id={item._id}
          >
            {renderTreeNodesWithChild(item.children)}
          </TreeNode>
        </>
      );
    }
  });

function CreateContract() {
  const popupModalInstance = PopupModal();
  // Variable to use navigate function
  const history = useNavigate();
  // Variable to hold tree data
  const treeData = useRecoilValue(SupplierTreeDataAtom);
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom);
  const tabClickCount = useRecoilState(TabChangerAtom);
  const roleUser = useRecoilState(RoleAccessAtom);
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // State variable to hold selected card
  const [selectedCard, setSelectedCard] = useState(
    roleUser &&
      roleUser[0] &&
      roleUser[0].page_access.contracts.create_contract.create
      ? sessionStorage.getItem("cardItem")
        ? sessionStorage.getItem("cardItem")
        : ""
      : ""
  );
  //State variable for holding the counter value to rerender the useeffect
  const [count, setCount] = useState(1);
  // State variable to selected supplier data
  const [selectedSupplier, setSelectedSupplier] = useState("");
  // State variable to hold selected contract data
  const [selectedContract, setSelectedContract] = useState("");
  // State variable to hold selected contract type
  const [contractType, setContractType] = useState("");
  // State variable to hold confirmation modal popup visibility
  const [confirmationModel, setConfirmationModel] = useState(false);
  // State variable to hold selected contract template
  const [contractTemplate, setContractTemplate] = useState([]);
  // State variable to workflow list
  const [workflowList, setWorkflowList] = useState([]);
  const [loader, setLoader] = useState(true);
  // State variable to hold parent data
  const [parentData, setParentData] = useState();
  // State variable to hold create button loading visibility
  const [createBtnLoader, setCreateBtnLoader] = useState(false);
  // store selected workflow
  const [workflow, setWorkflow] = useState();
  const [mockResponseData, setMockResponseData] = useState({});

  const [browseFile, setBrowseFile] = useState(false);
  const [basicdata, setBasicData] = useState();
  // const [onlineAuthoring, setOnlineAuthoring] = useState(false);

  const [createTemplate, setCreateTemplate] = useState(true);
  const [initialClauseContent, setInitialClauseContent] = useState([]);
  const [templateMetaData, setTemplateMetaData] = useState([]);

  const [contractDetails, setContractDetails] = useState(false);
  const navigate = useNavigate();

  sessionStorage.setItem("cardItem", selectedCard);
  // Function to check the selected contract level
  const levelCard = [
    {
      name: "Framework Agreement",
      src:
        selectedCard === "Framework Agreement"
          ? frameworkAgreementSelected
          : frameworkAgreement,
    },
    {
      name: "Supplement",
      src: selectedCard === "Supplement" ? supplementSelected : supplement,
    },
    {
      name: "CCN",
      src: selectedCard === "CCN" ? ccnSelected : ccn,
    },
    {
      name: "SOW",
      src: selectedCard === "SOW" ? sowSelected : sow,
    },
    {
      name: "CR",
      src: selectedCard === "CR" ? crSelected : cr,
    },
    {
      name: "Others",
      src: selectedCard === "Others" ? othersSelected : others,
    },
  ];
  // State variable to hold selected supplier tree data
  const [supplierTreeData, setSupplierTreeData] = useState([]);
  // State variable to hold selected supplier child tree data
  const [supplierWithChildTree, setSupplierWithChildTree] = useState([]);
  // State variable to hold search value
  const [searchVal, setSearchVal] = useState("null");
  // State variable to hold contract id and path
  const [finalPath, setFinalPath] = useState([]);
  // state varible to hold selected supplier data
  const [fwPath, setFwPath] = useState([]);
  // state for google document AI
  const [processorName, setProcessorName] = useState([
    {
      id: "projects/enmatter/locations/us/processors/a9a438f0ccae0e73",
      label: "Contract-Extraction",
    },
  ]);
  const [contractFile, setContractFile] = useState(null);
  const [jsonResult, setJsonResult] = useState(null);
  const [extractionLoader, setExtractionLoader] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [contractModal, setContractModal] = useState(false);

  const [contractAuthoring, setContractAuthoring] = useState(1);

  const onContractAuthoringChange = (e) => {
    console.log("radio checked", e.target.value);
    setContractAuthoring(e.target.value);
  };

  const closeModal = () => {
    setContractModal(false);
  };

  // Fucntion to handle initial render to fetch supplier & supplier child data
  useEffect(() => {
    setLoader(true);
    const treeApi = async () => {
      await treeDataApi(userData[0].id, "ContractTree")
        .then((res) => {
          setLoader(false);
          let supplierMaster = res.map((data) => {
            return data;
          });
          setSupplierTreeData(supplierMaster);
          let supplierWithChild = res.map((data) => {
            return data;
          });
          setSupplierWithChildTree(supplierWithChild);
        })
        .catch((error) => {
          console.log("Error fetching supplier tree data:", error);
          setLoader(false);
        });
    };
    treeApi();
  }, [
    tabClickCount[0],
    selectedSupplier,
    selectedCard,
    selectedContract,
    count,
  ]);
  // Function call when change the selected contract then set the contract template data
  useEffect(() => {
    getContractTemplateApi().then((res) => {
      console.log("res.docs", res.docs);
      setContractTemplate(res.docs);
    });

    getAllWorkflowsApi().then((data) => {
      console.log("workflow", data);
      setWorkflowList(data);
    });
  }, [selectedContract]);
  /* Function called when change the tamplate
   * @Param value - a template value
   */
  const onChangeTemplate = (value) => {
    // console.log("template", value);
  };

  // onChange Processor function
  const handleProcessorChange = (selectedId) => {
    console.log("selectedIddd", selectedId);
    const selectedProcessor = processorName.find(
      (proc) => proc.id === selectedId
    );
    if (selectedProcessor) {
      setProcessorName([selectedProcessor]);
    }
  };

  /* Function  will be called on select of any node in supplier tree
   * @Param modal - selected contract child data
   * @Param id - selected contract id
   */
  const getPath = (model, id) => {
    let path,
      item = {
        id: model._id,
        name: model.contract_name ? model.contract_name : model.supplier_name,
        type: model.contract_type,
      };
    console.log("item", item);

    if (!model || typeof model !== "object") return;

    if (model._id === id) return [item];

    (model.children || []).some((child) => (path = getPath(child, id)));
    return path && [item, ...path];
  };

  /* Function  will be called on select of any node in supplier tree
   * @Param currentId - selected contract id
   * @Param contractTypeVal - selected contract type
   */
  const getTopPath = (currentId, contractTypeVal) => {
    supplierWithChildTree.some((res) => {
      const pathVal = getPath(res, currentId);
      console.log("pathVal", pathVal);
      if (pathVal) {
        setFinalPath([
          ...pathVal,
          { id: "NEW", name: "New Contract", type: contractTypeVal },
        ]);
        return pathVal;
      }
      return false;
    });
  };

  // console.log("finalPath", finalPath);
  // console.log("selectedContract*************", selectedContract);

  /* Fucntion will be called on select the supplier
   * @Param selectedkeys - selected supplier id
   */
  const onSelectSupplier = (e, selectedKeys) => {
    // console.log("selectedKeys", selectedKeys);
    const flatten = flat(supplierTreeData);
    const item = flatten.find((node1) => node1._id === selectedKeys._id);
    // console.log(item);
    setSelectedSupplier(selectedSupplier._id !== item._id ? item : {});
    setFwPath(
      selectedSupplier._id !== item._id
        ? [
            { id: item._id, name: item.supplier_name, type: "Supplier" },
            { id: "NEW", name: "New Contract", type: "Framework Agreement" },
          ]
        : []
    );
    console.log("setSelectedSupplier", selectedSupplier);
  };

  /* Fucntion will be called on select the supplier child contract
   * @Param selectedkeys - selected supplier child id
   */
  const onSelectContractWithChild = (e, selectedKeys) => {
    let renderChild = supplierWithChildTree;
    console.log("selectedKeys:", selectedKeys);
    const flatten = flat(renderChild);
    console.log("flatten", flatten);
    const item = flatten.find(
      (node1) =>
        node1._id ===
        (selectedKeys.supplier_id ? selectedKeys.supplier_id : selectedKeys._id)
    );
    console.log("item", item);
    // const supplier = flatten.find((node) => item.supplier_id === node.id);
    // console.log("supplier", supplier);
    setSelectedContract(
      selectedKeys.contract_type === "Supplier"
        ? { ...selectedKeys }
        : { ...selectedKeys, supplier_name: item && item.supplier_name }
    );
    console.log("loggg", item.contract_type);
    if (selectedCard === "Supplement") {
      const contractTypeVal =
        selectedKeys.contract_type === "MSA"
          ? "Supplement"
          : selectedKeys.contract_type === "Supplement"
          ? "Sub-Supplement"
          : "";
      getTopPath(selectedKeys._id, contractTypeVal);
      setContractType(contractTypeVal);
      // console.log("ContractType: ", contractTypeVal);
    } else if (selectedCard === "CCN") {
      const contractTypeVal =
        selectedKeys.contract_type === "CCN" ||
        selectedKeys.contract_type === "Supplement" ||
        selectedKeys.contract_type === "Sub-Supplement" ||
        selectedKeys.contract_type === "MSA"
          ? "CCN"
          : "";
      getTopPath(selectedKeys._id, contractTypeVal);
      setContractType(contractTypeVal);
    } else if (selectedCard === "SOW") {
      const contractTypeVal =
        selectedKeys.contract_type !== "Supplier" &&
        selectedKeys.contract_type !== "CCN" &&
        selectedKeys.contract_type !== "CR" &&
        selectedKeys.contract_type !== "SOW"
          ? "SOW"
          : "";
      getTopPath(selectedKeys._id, contractTypeVal);
      setContractType(contractTypeVal);
    } else if (selectedCard === "CR") {
      console.log("contract_type", selectedKeys.contract_type);
      const contractTypeVal =
        selectedKeys.contract_type === "CR" ||
        selectedKeys.contract_type === "SOW"
          ? "CR"
          : "";
      getTopPath(selectedKeys._id, contractTypeVal);
      setContractType(contractTypeVal);
    } else if (selectedCard === "Others") {
      console.log("Card", selectedCard);
      console.log("selectedKeys on others", selectedKeys);
      const contractTypeVal =
        selectedKeys.contract_type === "Others" ||
        selectedKeys.contract_type === "CCN" ||
        selectedKeys.contract_type === "Supplement" ||
        selectedKeys.contract_type === "Sub-Supplement" ||
        selectedKeys.contract_type === "SOW" ||
        selectedKeys.contract_type === "CR" ||
        selectedKeys.contract_type === "MSA" ||
        selectedKeys.contract_type === "Supplier"
          ? "Others"
          : "";
      getTopPath(selectedKeys._id, contractTypeVal);
      setContractType(contractTypeVal);
    }
  };

  useEffect(() => {
    console.log("selectedContract", selectedContract);
  }, [selectedContract]);

  useEffect(() => {
    console.log("contractType", contractType);
  }, [contractType]);

  /* Function will be called when search the tree
   * @Param e - event object
   */
  const onSearchTree = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.toLowerCase();
    if (searchValue === "") {
      setSearchVal("null");
    } else {
      setSearchVal(searchValue);
    }
  };

  const onExpand = (expandedKeys) => {
    console.log("expandedKeys", expandedKeys);
  };

  /* Function to handle flat the inner array values
   * @Param array - supplier tree child data
   */
  const flat = (array) => {
    var result = [];
    array.forEach(function (a) {
      result.push({ ...a, children: [] });
      if (Array.isArray(a.children)) {
        result = result.concat(flat(a.children));
      }
    });
    return result;
  };

  /* Function to handle flat the inner array values
   * @Param data - supplier tree data
   */
  const renderTreeNodes = (data) =>
    data &&
    data.length !== 0 &&
    data.map((item) => {
      if (item.children) {
        return (
          <>
            <TreeNode
              title={
                <div
                  className="title-div"
                  style={{ display: "inline-block", width: "100%" }}
                >
                  <span>{item.supplier_name} </span>{" "}
                </div>
              }
              icon={
                item.contract_type === "Supplier" ? (
                  <>
                    <span>
                      <Image src={FolderImg} preview={false} width={15} />
                    </span>
                  </>
                ) : item.contract_type === "MSA" ? (
                  <>
                    <span>
                      <Image
                        src={frameworkAgreement}
                        preview={false}
                        width={15}
                      />
                    </span>
                  </>
                ) : item.contract_type === "Supplement" ? (
                  <>
                    <span>
                      <Image src={supplement} preview={false} width={20} />
                    </span>
                  </>
                ) : item.contract_type === "Sub-Supplement" ? (
                  <>
                    <span>
                      <Image src={supplement} preview={false} width={20} />
                    </span>
                  </>
                ) : item.contract_type === "SOW" ? (
                  <>
                    <span>
                      <Image src={sow} preview={false} width={20} />
                    </span>
                  </>
                ) : item.contract_type === "CR" ? (
                  <>
                    <span>
                      <Image src={cr} preview={false} width={20} />
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <Image src={others} preview={false} width={20} />
                    </span>
                  </>
                )
              }
              key={item._id}
              id={item._id}
            >
              {renderTreeNodes(item.children)}
            </TreeNode>
          </>
        );
      }
      return (
        <TreeNode
          title={<span>{item.supplier_name}</span>}
          key={item._id}
          id={item._id}
        />
      );
    });

  // Function to handle the get parant contract data from selected contract
  const getParentData = () => {
    setCreateBtnLoader(true);
    let parent =
      contractType === "" && selectedSupplier !== "" ? fwPath : finalPath;
    console.log("parent", parent);
    if (
      selectedCard !== "Framework Agreement" ||
      (selectedCard !== "Others" &&
        selectedContract.contract_type !== "Supplier")
    ) {
      getSingleContractApi(parent[parent.length - 2].id).then((res) => {
        console.log("parent contract: ", res);
        setParentData(res.docs[0]);
        setWorkflow(
          res.docs[0] &&
            res.docs[0].workflowData &&
            res.docs[0].workflowData.length !== 0 &&
            res.docs[0].workflowData[0].workflow_id
        );
        if (res) {
          setCreateBtnLoader(false);
        }
      });
    }
    setConfirmationModel(true);
  };

  /* Function to handle the create contract form data stored
   * @param values - a user create form values
   */
  const onFinish = (value) => {
    let data;
    let template =
      contractTemplate &&
      contractTemplate.length !== 0 &&
      contractTemplate.filter((data) => data._id === value.contract_template);

    let parent =
      contractType === "" && selectedSupplier !== "" ? fwPath : finalPath;
    console.log("selectedSupplier", selectedSupplier);
    console.log("selectedContract", selectedContract);
    console.log("parent", parent);
    console.log(" parent[parent.length - 2]", parent[parent.length - 2]);
    if (selectedCard !== "Framework Agreement") {
      data = {
        title: value.title,
        supplier_id:
          contractType === ""
            ? selectedSupplier._id
            : selectedContract.supplier_id
            ? selectedContract.supplier_id
            : selectedContract._id,
        contractType: value.contractType,
        selectedFramework: selectedCard,
        contract_template: template[0],
        parent: parent[parent.length - 2],
        workflow_id: workflow,
      };
    } else {
      data = {
        title: value.title,
        supplier_id:
          contractType === ""
            ? selectedSupplier._id
            : selectedContract.supplier_id,
        contractType: value.contractType,
        selectedFramework: selectedCard,
        contract_template: template[0],
        parent: parent[parent.length - 2],
        workflow_id: workflow,
      };
    }
    console.log("Values**", value);
    setBasicData(data);
    if (contractAuthoring === 1) {
      // setOnlineAuthoring(true);
      setContractModal(false);
      setHeaderChanger({
        header: "contract",
        headerItem: "3",
        headerItemName: headerChanger.headerItemName,
        stateSetter: data,
      });
      history(`/contract/create/onlineauthoring`, { state: data });
      // Ensure the other modal is closed
    } else if (contractAuthoring === 3) {
      setContractModal(true);
      // setOnlineAuthoring(false); // Ensure the other page is not displayed
    } else {
      // setOnlineAuthoring(false);
      setContractModal(false);
      setHeaderChanger({
        header: "contract",
        headerItem: "3",
        headerItemName: headerChanger.headerItemName,
        stateSetter: data,
      });
      history(`/contract/create/general`, { state: data });
      setParentData();
    }
  };

  /* Function to handle error from create contract
   * @param errorInfo - a exception for the request
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    console.log("Selected: ", selectedCard);
  }, [selectedCard]);

  useEffect(() => {
    document.title = "Create Contract | Judo";
  }, []);

  const props = {
    name: "file",
    listType: "picture",
    accept: ["pdf"],
    maxCount: 1,
    // onRemove() {
    //   setTableData([]);
    // },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
        // if (info.fileList.length !== 0) {
        //   setTemplateFile(info.file);
        // } else {
        //   setTemplateFile();
        // }
        if (info.fileList.length !== 0) {
          setContractFile(info.file.originFileObj);
        } else {
          console.log("qqq");
          setContractFile();
        }
      }
      if (info.file.status === "done") {
        console.log(info.file, info.fileList);
        // if (info.fileList.length !== 0) {
        //   setTemplateFile(info.file);
        // } else {
        //   setTemplateFile();
        // }
        if (info.fileList.length !== 0) {
          setContractFile(info.file.originFileObj);
        } else {
          console.log("www");
          setContractFile();
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
    beforeUpload(file) {
      setConfirmationModel(false);
      const acceptedFormats = ["pdf"];
      const formatCheck = acceptedFormats.includes(file.name.split(".")[1]);
      if (!formatCheck) {
        popupModalInstance.errorModal(ModalMessage.Workflow_Excel_Upload_Error);
      } else {
        console.log("ubnuid iubsdbs");
        popupModalInstance
          .infoModal(ModalMessage.CAC_Temp_Info)
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              setBrowseFile(true);
              setCreateTemplate(false);

              const formData = new FormData();
              formData.append("file", file);
              console.log("FormDatavalue", formData);
              let responseData = {
                CLIENT_ENTITY: "client entity1",
                SERVICE_LINE: "Service Line 1",
                APPLICATION_NAME: "App Name 1",
                CONTRACT_DETAILS: "Contract Details 1",
                START_DATE: "2024-04-09",
                END_DATE: "2024-04-10",
                PRIORITY: "High",
                PROJECT_MANAGER_NAME: "John Doe",
                DIRECTOR_NAME: "Jane Smith",
                BUSINESS_OWNER_NAME: "Alice",
                SUPPLIER_ENTITY_NAME: "XYZ Corp",
                VENDOR_MANAGER: "Bob",
                SERVICE_MANAGER: "Eve",
                SCOPE: "Scope Details 1",
                SCOPE_IN_DETAIL: "Scope details 1",
                ADDITIONAL_INFORMATION: "Additional Info 1",
                ASSUMPTIONS: "Assumptions 1",
                DEPENDENCIES: "Dependency details1",
                DELIVERY_CENTER: "Delivery Center 1",
                DEPENDENCY_ON_OTHER_PROGRAMS: "Dependency 1",
                TECHNOLOGY_TOOL: "Tool 1",
                INVOICING_PROFILE: "Profile 1",
                COST_CENTER: "Cost Center 1",
                CONTRACT_CURRENCY: "Currency1",
                CONTRACT_VALUE: "contract value1",
              };
              setMockResponseData(responseData);
              setHeaderChanger({
                header: "contract",
                headerItem: "3",
                headerItemName: headerChanger.headerItemName,
                stateSetter: basicdata,
              });
              // history(`/contract/create/general`, { state: basicdata });
              history(`/contract/create/general`, {
                state: {
                  ...basicdata,
                  mockResponseData: responseData,
                },
              });
              // Process the mock response
              // const setData = Object.values(mockResponseData);
              // console.log("Form Data Values", setData);
              // setTemplateMetaData(setData);
              // setInitialClauseContent(setData);
              setLoader(false);
            }
          });
        // setUploadRule(false);
        // setUploadRuleText("");
      }
      return formatCheck || Upload.LIST_IGNORE;
    },
  };

  // handle contract extraction file
  const handleFileChange = (info) => {
    const uploadFile = info.file.originFileObj;
    if (info.file.status === "done" || info.file.status === "uploading") {
      if (uploadFile.type !== "application/pdf") {
        if (!hasError) {
          message.error("Upload only PDF files");
          setHasError(true);
        }
        return;
      }
      setHasError(false);
      console.log("info", info);
      console.log("info-file", info.file);
      console.log("info-fileList", info.fileList);
      setContractFile(uploadFile);
    } else if (info.file.status === "error") {
      message.error({
        content: `${info.file.name} file upload failed.`,
        top: 150,
      });
    }
  };

  // process contract extraction file
  // const handleProcessDocument = async (file) => {
  //   if (contractFile) {
  //     if (!file) {
  //       message.error("Please select a file first!");
  //       return;
  //     }

  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onloadend = async () => {
  //       const base64Content = reader.result.split(",")[1];

  //       try {
  //         setExtractionLoader(true);
  //         let data = {
  //           processorName: processorName,
  //           documentContent: base64Content,
  //         };
  //         await contractExtractionApi(data).then((res) => {
  //           if (res) {
  //             setExtractionLoader(false);
  //           }
  //           console.log("responseData", res);
  //           setJsonResult(res);
  //           message.success("Document processed successfully!");
  //           history(`/contract/create/general`, {
  //             state: {
  //               ...basicdata,
  //               contractExtractData: res,
  //             },
  //           });
  //         });
  //       } catch (error) {
  //         console.error("Error processing document:", error);
  //         message.error("Error processing document...Try again later");
  //       }
  //     };
  //   } else return;
  // };

  const handleProcessDocument = async (file) => {
    if (!file) {
      message.error("Please select a file first!");
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      const base64Content = reader.result.split(",")[1];

      try {
        setExtractionLoader(true);
        let data = {
          processorName: processorName,
          documentContent: base64Content,
        };

        const res = await contractExtractionApi(data);

        if (res) {
          setExtractionLoader(false);
          console.log("resres", res);
          // Process response to handle arrays
          const processedRes = {};
          for (const [key, value] of Object.entries(res)) {
            if (Array.isArray(value)) {
              processedRes[key] = value.join(", ");
            } else {
              processedRes[key] = value;
            }
          }
          console.log("Processed Response Data:", processedRes);
          console.log("commercialdata", res.commercialProperties);
          setJsonResult(processedRes);
          message.success("Document processed successfully!");
          history(`/contract/create/general`, {
            state: {
              ...basicdata,
              contractExtractData: processedRes.extractedData,
            },
          });
        } else {
          message.error("Try again later");
        }
      } catch (error) {
        setExtractionLoader(false);
        console.error("Error processing document:", error);
        message.error("Error processing document...Try again later");
      }
    };
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
      handleProcessDocument(contractFile);
    }, 0);
  };

  return (
    <Layout className="create-contract-sub-div">
      <Row gutter={[16, 16]}>
        {levelCard &&
          levelCard.map((cards) => {
            return (
              <Col span={4} className="contract-level-card-col">
                <Card
                  className={
                    roleUser[0] &&
                    roleUser[0].page_access.contracts.create_contract.create
                      ? `cursor-pointer contract-level-card ${
                          cards.name === selectedCard && "selectedCard"
                        }`
                      : `contract-level-card`
                  }
                  onClick={() => {
                    if (
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.contracts.create_contract.create
                    ) {
                      setSelectedCard(
                        selectedCard !== cards.name ? cards.name : ""
                      );
                      setSelectedSupplier("");
                      setSelectedContract("");
                      setContractType("");
                      setSearchVal("null");
                      setFinalPath([]);
                    }
                  }}
                  bordered={false}
                >
                  <div className="text-end">
                    {/* {cards.name === selectedCard && ( */}
                    <Image
                      className="float-right"
                      src={selectCard}
                      preview={false}
                      style={{
                        display: `${
                          cards.name !== selectedCard ? "none" : "block"
                        }`,
                      }}
                    />
                    {/* )} */}
                  </div>
                  <div className="text-center">
                    <Image
                      src={cards.src}
                      preview={false}
                      // style={{ padding: "0 40px" }}
                    />

                    <h4
                      className={`mt-5 mb-5 ${
                        cards.name === selectedCard && "selectedCardText"
                      }`}
                    >
                      {cards.name}
                    </h4>
                  </div>
                </Card>
              </Col>
            );
          })}
      </Row>
      {roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.contracts.create_contract.create && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={10} className="card-select-text">
                <div>
                  {selectedCard === "Framework Agreement"
                    ? "Create New Framework Agreement"
                    : selectedCard === "Supplement"
                    ? "Create New Supplement"
                    : selectedCard === "CCN"
                    ? "Create New CCN"
                    : selectedCard === "SOW"
                    ? "Create New SOW"
                    : selectedCard === "CR"
                    ? "Create New CR"
                    : selectedCard === "Others"
                    ? "Create New Other contract"
                    : "Draft Files"}
                </div>
              </Col>
            </Row>
            {selectedCard !== "" && (
              <Row
                className="mt-15"
                gutter={[32, 16]}
                style={{ paddingLeft: 15 }}
              >
                <Col span={14} className="create-contract-tree-col-2">
                  <div className="create-contract-search">
                    <Input
                      placeholder="Search"
                      onChange={onSearchTree}
                      className="mb-10 tree-data-search"
                    />
                  </div>
                  <div className="maincard">
                    <Card className="tree-struc">
                      {!loader && selectedCard === "Framework Agreement" && (
                        <>
                          <Tree
                            className="tree-data"
                            onExpand={onExpand}
                            onClick={onSelectSupplier}
                            treeData={
                              searchVal === "null"
                                ? supplierTreeData
                                : supplierTreeData.filter((item) => {
                                    return item.supplier_name
                                      .toLowerCase()
                                      .includes(searchVal.toLowerCase());
                                  })
                            }
                            // defaultExpandAll
                            // selectable={false}
                            showIcon
                            titleRender={(node) => {
                              const flatten = flat(supplierTreeData);
                              const item = flatten.find(
                                (node1) => node1._id === node._id
                              );
                              return (
                                <div
                                  className="title-div"
                                  style={{
                                    display: "inline-block",
                                    width: "100%",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <span>
                                    {item &&
                                      item.length !== 0 &&
                                      item.supplier_name}{" "}
                                    {item &&
                                      item.length === 0 &&
                                      "No match found"}{" "}
                                  </span>{" "}
                                </div>
                              );
                            }}
                            icon={
                              <Image
                                src={FolderImg}
                                preview={false}
                                width={10}
                              />
                            }
                          >
                            {renderTreeNodes(supplierTreeData)}
                          </Tree>
                        </>
                      )}
                      {!loader && selectedCard !== "Framework Agreement" && (
                        <>
                          {/* <Input
                      placeholder="Search"
                      onChange={onSearchTree}
                      className="mb-10 tree-data-search"
                    /> */}

                          <Tree
                            onExpand={onExpand}
                            onClick={onSelectContractWithChild}
                            treeData={
                              searchVal === "null"
                                ? supplierWithChildTree
                                : supplierWithChildTree.filter((item) => {
                                    return Object.values(item).some((value) => {
                                      return String(value)
                                        .toLowerCase()
                                        .includes(searchVal.toLowerCase());
                                    });
                                  })
                            }
                            defaultExpandAll
                            // selectable={false}
                            showIcon
                            titleRender={(node) => {
                              const flatten = flat(supplierWithChildTree);
                              const item = flatten.find(
                                (node1) => node1._id === node._id
                              );
                              return (
                                <div
                                  className="title-div"
                                  style={{
                                    display: "inline-block",
                                    width: "100%",
                                    borderRadius: "5px",
                                    // padding: "0px 10px",
                                  }}
                                >
                                  {item && item.length !== 0 && (
                                    <span>
                                      {item.contract_name
                                        ? item.contract_name
                                        : item.supplier_name}
                                    </span>
                                  )}
                                  {item && item.length === 0 && (
                                    <span>No match found</span>
                                  )}
                                </div>
                              );
                            }}
                            // icon={(node) => {
                            //   console.log("nodeee", node);
                            //   return node.data.type === "folder" ? (
                            //     <>
                            //       <Image src={FolderImg} preview={false} width={10} />
                            //     </>
                            //   ) : (
                            //     <>
                            //       <FileOutlined style={{ fontSize: "12px" }} />
                            //     </>
                            //   );
                            // }}

                            icon={(node) => {
                              return node.data.contract_type === "Supplier" ? (
                                <>
                                  <span>
                                    <Image
                                      src={FolderImg}
                                      preview={false}
                                      width={15}
                                    />
                                  </span>
                                </>
                              ) : node.data.contract_type === "MSA" ? (
                                <>
                                  <span>
                                    <Image
                                      src={frameworkAgreement}
                                      preview={false}
                                      width={15}
                                    />
                                  </span>
                                </>
                              ) : node.data.contract_type === "Supplement" ? (
                                <>
                                  <span>
                                    <Image
                                      src={supplement}
                                      preview={false}
                                      width={20}
                                    />
                                  </span>
                                </>
                              ) : node.data.contract_type ===
                                "Sub-Supplement" ? (
                                <>
                                  <span>
                                    <Image
                                      src={supplement}
                                      preview={false}
                                      width={20}
                                    />
                                  </span>
                                </>
                              ) : node.data.contract_type === "CCN" ? (
                                <>
                                  <span>
                                    <Image
                                      src={ccn}
                                      preview={false}
                                      width={25}
                                    />
                                  </span>
                                </>
                              ) : node.data.contract_type === "SOW" ? (
                                <>
                                  <span>
                                    <Image
                                      src={sow}
                                      preview={false}
                                      width={20}
                                    />
                                  </span>
                                </>
                              ) : node.data.contract_type === "CR" ? (
                                <>
                                  <span>
                                    <Image
                                      src={cr}
                                      preview={false}
                                      width={20}
                                    />
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span>
                                    <Image
                                      src={others}
                                      preview={false}
                                      width={20}
                                    />
                                  </span>
                                </>
                              );
                            }}
                          >
                            {renderTreeNodesWithChild(supplierWithChildTree)}
                          </Tree>
                        </>
                      )}
                      {loader && <CustomLoader />}
                    </Card>
                  </div>
                </Col>
                <Col span={10} className="create-contract-tree-col-1">
                  <Card className="seleted-tree-detail">
                    <Row align="middle" type="flex">
                      <Col span={8} className="contract-label vertical-align">
                        Contract :
                      </Col>
                      <Col span={16} className="contract-name vertical-align">
                        {selectedCard}
                      </Col>
                    </Row>
                    {selectedSupplier &&
                      Object.keys(selectedSupplier).length !== 0 &&
                      selectedContract.length === 0 &&
                      contractType === "" && (
                        // (console.log(
                        //   "b8ib yibyg",
                        //   Object.keys(selectedSupplier).length
                        // ),
                        <>
                          <Row align="middle" type="flex" className="mt-15">
                            <Col
                              span={8}
                              className="contract-label vertical-align"
                            >
                              Supplier :
                            </Col>
                            <Col
                              span={16}
                              className="contract-name vertical-align"
                            >
                              {selectedSupplier.supplier_name}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              span={24}
                              align="middle"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-end",
                                marginTop: 20,
                                height: "calc(100vh - 510px)",
                              }}
                            >
                              <Col span={6}>
                                <CancelButton
                                  style={{ marginRight: 10 }}
                                  onClick={() => {
                                    setSelectedSupplier("");
                                    setSelectedContract("");
                                    setContractType("");
                                  }}
                                />
                              </Col>
                              <Col span={6}>
                                <NextButton
                                  style={{ marginLeft: 10 }}
                                  onClick={() => {
                                    setConfirmationModel(true);
                                  }}
                                />
                              </Col>
                            </Col>
                          </Row>
                        </>
                      )}
                    {selectedContract &&
                      selectedContract.length !== 0 &&
                      selectedSupplier.length === 0 &&
                      contractType !== "" && (
                        <>
                          <Row align="middle" type="flex" className="mt-15">
                            <Col
                              span={8}
                              className="contract-label vertical-align"
                            >
                              {selectedContract.contract_type} :
                            </Col>
                            <Col
                              span={16}
                              className="contract-name vertical-align"
                            >
                              {selectedContract.contract_name
                                ? selectedContract.contract_name
                                : selectedContract.supplier_name}
                            </Col>
                          </Row>
                          {contractType === "Sub-Supplement" && (
                            <Row align="middle" type="flex" className="mt-15">
                              <Col
                                span={8}
                                className="contract-label vertical-align"
                              >
                                To Create :
                              </Col>
                              <Col
                                span={16}
                                className="contract-name vertical-align"
                              >
                                {contractType}
                              </Col>
                            </Row>
                          )}

                          <Row type="flex" align="middle" className="mt-10">
                            <Col
                              span={24}
                              align="middle"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-end",
                                marginTop: 20,
                                height: "calc(100vh - 520px)",
                              }}
                            >
                              <Col span={6} style={{ marginRight: 5 }}>
                                <CancelButton
                                  onClick={() => {
                                    setSelectedSupplier("");
                                    setSelectedContract("");
                                    setContractType("");
                                  }}
                                />
                              </Col>
                              <Col span={6} style={{ marginLeft: 5 }}>
                                <NextButton
                                  disabled={selectedContract.disabled}
                                  loading={createBtnLoader}
                                  onClick={() => {
                                    getParentData();
                                  }}
                                />
                              </Col>
                            </Col>
                          </Row>

                          {/* <Space align="center"> */}
                          {/* <Row type="flex" className="space-align-block">
                        <Col span={12}>
                          <CancelButton
                            // className="create-cancel-btn"
                            onClick={() => {
                              setSelectedSupplier("");
                              setSelectedContract("");
                              setContractType("");
                            }}
                          />
                        </Col>
                        <Col span={12}>
                          <NextButton
                            loading={createBtnLoader}
                            // className="create-btn"
                            onClick={() => {
                              getParentData();
                            }}
                          />
                        </Col>
                      </Row> */}
                          {/* </Space> */}
                        </>
                      )}
                  </Card>
                </Col>
              </Row>
            )}
          </>
        )}
      {confirmationModel && !createBtnLoader && (
        <>
          <Modal
            centered
            open={confirmationModel}
            onOk={() => setConfirmationModel(false)}
            onCancel={() => {
              setConfirmationModel(false);
              setParentData();
              setCount(count + 1);
            }}
            width={500}
            footer={false}
          >
            <Space align="">
              <Image src={confirmation} preview={false} />
              <div>
                <h3>Confirmation</h3>
                <span style={{ fontSize: "12px" }}>
                  For creating the{" "}
                  {contractType !== "" ? contractType : "Framework Agreement"}
                </span>
              </div>
            </Space>
            <Form
              className="mt-10 confirmation-form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              initialValues={{
                ["supplier"]:
                  contractType === ""
                    ? selectedSupplier.supplier_name
                    : selectedContract.supplier_name,
                ["contractType"]:
                  contractType !== "" ? contractType : "Framework Agreement",
                ["workflow"]:
                  parentData &&
                  parentData.workflowData &&
                  parentData.workflowData.length !== 0 &&
                  parentData.workflowData[0].workflow_name,
              }}
            >
              {/* Contract Authoring */}
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Form.Item
                    name="contract_authoring"
                    label="Contract Authoring"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please select Contract Authoring!",
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={onContractAuthoringChange}
                      value={contractAuthoring}
                      style={{ width: "100%" }}
                    >
                      <Row justify="center">
                        <Col span={8}>
                          <Radio value={1} style={{ whiteSpace: "nowrap" }}>
                            Online Authoring
                          </Radio>
                        </Col>
                        <Col span={8}>
                          <Radio value={2} style={{ whiteSpace: "nowrap" }}>
                            Offline Authoring
                          </Radio>
                        </Col>
                        <Col span={8}>
                          <Radio value={3}>Smart Authoring</Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              {/* Supplier &&  Contract Type */}
              <Row gutter={[16, 8]}>
                <Col span={12}>
                  <Form.Item
                    name="supplier"
                    label="Supplier"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled className="confirmation-input" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="contractType"
                    label="Contract Type"
                    labelCol={{ span: 24 }}
                  >
                    <Input disabled className="confirmation-input" />
                  </Form.Item>
                </Col>
              </Row>

              {/* Title */}
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Form.Item
                    name="title"
                    label="Title"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Title!",
                        pattern: /^(?!\s*$).+/,
                      },
                    ]}
                  >
                    <Input placeholder="Enter your title" className="" />
                  </Form.Item>
                </Col>
              </Row>

              {/* Contract Template */}
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Form.Item
                    name="contract_template"
                    label="Contract Template"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please select template!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      onChange={(value) => onChangeTemplate(value)}
                      showSearch={true}
                      className=""
                      placeholder="Select Contract Template"
                      options={
                        contractTemplate &&
                        contractTemplate
                          .filter((data) =>
                            data.supplier_id === selectedSupplier._id
                              ? selectedSupplier._id
                              : selectedContract._id
                          )
                          .map((data) => ({
                            key: data._id,
                            label: data.contract_template_name,
                            value: data._id,
                          }))
                      }
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>

              {/* Smart Authoring */}
              {contractAuthoring && contractAuthoring === 3 && (
                <Row gutter={[16, 8]}>
                  <Col span={24}>
                    <Form.Item
                      name="processor"
                      label="Processor"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Please select Processor!",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        onChange={(value) => handleProcessorChange(value)}
                        showSearch={true}
                        className=""
                        placeholder="Select Processor"
                        options={
                          processorName &&
                          processorName.map((data) => ({
                            key: data.id,
                            label: data.label,
                            value: data.id,
                          }))
                        }
                      ></Select>
                    </Form.Item>
                  </Col>
                </Row>
              )}

              {/* Workflow */}
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    name="workflow"
                    label="Workflow"
                    rules={[
                      {
                        required: true,
                        message: "Please select workflow!",
                      },
                    ]}
                  >
                    <Select
                      onChange={(value) => setWorkflow(value)}
                      placeholder="Select workflow"
                      className=""
                      allowClear={true}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          >
                            or
                          </Divider>
                          <Button
                            style={{
                              width: "100%",
                            }}
                            className="mb-5 create-btn"
                            icon={<PlusOutlined />}
                            onClick={() => {
                              history(
                                `/system-setup`
                                // {
                                // state: "create-true",
                                // }
                              );
                              localStorage.setItem("wf-create", true);
                              setHeaderChanger({
                                header: "systemSetup",
                                headerItem: "2",
                                headerItemName: "systemSetup",
                              });
                              sessionStorage.setItem("conTabKey", 5);
                            }}
                          >
                            Create New Workflow
                          </Button>
                        </>
                      )}
                      options={
                        workflowList &&
                        workflowList.map((workflow) => ({
                          key: workflow._id,
                          label: workflow.workflow_name,
                          value: workflow._id,
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Creation Flow */}
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Form.Item
                    name="creationFlow"
                    label="Creation Flow"
                    labelCol={{ span: 24 }}
                  >
                    <div className="creation-flow-div">
                      <Space
                        direction="vertical"
                        size="middle"
                        style={{
                          display: "flex",
                        }}
                      >
                        {contractType === "" &&
                          selectedSupplier !== "" &&
                          fwPath.map((item, index) => (
                            <>
                              <div
                                style={{
                                  marginLeft: index > 0 && `${index * 20}px`,
                                  paddingLeft: "10px",
                                  borderLeft: index > 0 && "2px solid black",
                                }}
                                className="flow-btn-top"
                              >
                                <span className="flow-btn">
                                  <Image src={FolderImg} preview={false} />
                                  &nbsp;&nbsp;
                                  {item.name}
                                </span>
                                &nbsp;&nbsp;
                                <span style={{ fontSize: "12px" }}>
                                  {item.type}
                                </span>
                              </div>
                            </>
                          ))}

                        {contractType !== "" &&
                          finalPath.map((item, index) => (
                            <>
                              <div
                                style={{
                                  marginLeft: index > 0 && `${index * 20}px`,
                                  paddingLeft: "10px",
                                  borderLeft: index > 0 && "2px solid black",
                                }}
                                id="flow-btn-bottom"
                              >
                                <span className="flow-btn-new">
                                  <Image src={FolderImg} preview={false} />
                                  &nbsp;&nbsp;
                                  {item.name}
                                </span>
                                &nbsp;&nbsp;
                                <span style={{ fontSize: "12px" }}>
                                  {item.type}
                                </span>
                              </div>
                            </>
                          ))}
                      </Space>
                    </div>
                  </Form.Item>
                </Col>
              </Row>

              <Row type="flex" align="middle">
                <Col
                  span={24}
                  align="middle"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                  <Col span={7} style={{ marginRight: 5 }}>
                    <CancelButton
                      onClick={() => {
                        setConfirmationModel(false);
                        setSelectedSupplier("");
                        setSelectedContract("");
                        setContractType("");
                        setParentData();
                      }}
                    />
                  </Col>
                  <Col span={7} style={{ marginLeft: 5 }}>
                    <CreateButtonShort htmlType="submit" />
                    {/* {onlineAuthoring && (
                      <Layout className="header-layout">
                        <div className="system-setup1">
                          <Row
                            align="middle"
                            type="flex"
                            gutter={[8, 16]}
                            className="m-10"
                          >
                            <Col
                              className="vertical-align"
                              xs={24}
                              sm={16}
                              md={18}
                              lg={18}
                              xl={20}
                            >
                              <Card className="setup-header-card">
                                <Row type="flex" gutter={[8, 16]}>
                                  <Col
                                    className="vertical-align"
                                    xs={6}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    xl={2}
                                  >
                                    <Row type="flex" gutter={[8, 16]}>
                                      <Col span={12}>
                                        <Image
                                          src={arrow_left}
                                          style={{ width: 25 }}
                                          preview={false}
                                          className="cursor-pointer"
                                          onClick={() => {
                                            // navigate(`/contract_authority/create/template`)
                                            setCreateTemplate(true);
                                            setBrowseFile(false);
                                          }}
                                        />
                                      </Col>
                                      <Col span={12}>
                                        <Image
                                          src={arrow_right_disable}
                                          style={{ width: 20 }}
                                          preview={false}
                                          className="cursor-not-allowed"
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          </Row>
                        </div>
                      </Layout>
                    )} */}
                    <Modal
                      open={contractModal}
                      className="maximize-batch-content"
                      onCancel={closeModal}
                      footer={null}
                      centered
                      closeIcon={null}
                      width="90%"
                    >
                      {/* <div className="system-setup1"> */}
                      <Row>
                        <Col span={24} align="center">
                          <div>
                            <h3>Enter Contract Details</h3>
                            <hr className="mt-10 view-supplier-hr" />
                          </div>
                        </Col>
                      </Row>
                      <div className="contract-div">
                        <Row
                          align="middle"
                          gutter={[40, 16]}
                          justify="center"
                          className="mt-25"
                        >
                          <Col
                            span={16}
                            align="right"
                            className="vertical-align"
                          >
                            <div className="choose-box-div">
                              <Form.Item>
                                <Row
                                  type="flex"
                                  gutter={[64, 56]}
                                  align={"middle"}
                                >
                                  <Col
                                    span={24}
                                    align="middle"
                                    className="vertical-align"
                                  >
                                    <span className="upload-note">
                                      Click below to autofill Contract details
                                      through Uploading
                                    </span>
                                  </Col>
                                  <Col
                                    span={24}
                                    align="middle"
                                    className="vertical-align"
                                  >
                                    <Col span={16} style={{ marginRight: 5 }}>
                                      <div className="main-test">
                                        <Upload
                                          // {...props}
                                          accept=".pdf"
                                          showUploadList
                                          listType="picture"
                                          // beforeUpload={() => false}
                                          onChange={handleFileChange}
                                          customRequest={dummyRequest}
                                          className="contract_authority-upload-wrapper"
                                        >
                                          <Col
                                            span={24}
                                            align="middle"
                                            style={{
                                              display: "flex",
                                              justifyContent: "end",
                                              alignItems: "end",
                                            }}
                                          >
                                            <Col
                                              span={24}
                                              style={{ marginRight: 5 }}
                                            >
                                              <UploadButton
                                                className="template-upload"
                                                type="primary"
                                                // onClick={() =>
                                                //   setConfirmationModel(true)
                                                // }
                                              />
                                            </Col>
                                          </Col>
                                        </Upload>
                                        {extractionLoader && (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              margin: 20,
                                            }}
                                          >
                                            <LoadingOutlined
                                              style={{
                                                fontSize: 20,
                                                color:
                                                  "var(--color-solid-darkerblue)",
                                              }}
                                              spin
                                            />
                                            <span
                                              style={{
                                                marginLeft: 10,
                                                color:
                                                  "var(--color-solid-darkerblue)",
                                              }}
                                            >
                                              Extracting Contract Details&nbsp;
                                              <span className="ellipsis"></span>
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </Col>
                                  </Col>
                                </Row>
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/* </div> */}
                    </Modal>
                  </Col>
                </Col>
              </Row>
            </Form>
          </Modal>
        </>
      )}
    </Layout>
  );
}

export default CreateContract;
