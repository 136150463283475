import { axiosInstance } from "../utils/common";
import { axiosInstanceUpload } from "../utils/common";

export const getAllFxTableApi = () => {
  return axiosInstance
    .get("v1/fxTables")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllFxTablePaginationApi = (
  page,
  limit,
  filterObj,
  sortColumn
) => {
  return axiosInstance
    .post(`v1/fxTables/pagination?limit=${limit}&page=${page}`, {
      allFilterVal: filterObj,
      sortColumn,
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllFxTableOnlyApi = () => {
  return axiosInstance
    .get(`v1/fxTables/only`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSingleFxTableDataApi = (fxTableId) => {
  return axiosInstance
    .get(`v1/fxTables/single/${fxTableId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getSupplierFxApi = (supplierId) => {
  return axiosInstance
    .get(`v1/fxTables/supplier/${supplierId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getFxTableReportApi = (fxTableId, fxTableType) => {
  return axiosInstance
    .get(`v1/fxTables/supplier/${fxTableId}/${fxTableType}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const createFxTableAPi = (fxTableDetail) => {
  return axiosInstance
    .post("v1/fxTables", fxTableDetail, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
export const editFxTableAPi = (fxTableId, fxTableDetail) => {
  return axiosInstance
    .patch(`v1/fxTables/${fxTableId}`, fxTableDetail, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const editFxTableDataAPi = (fxTableId, fxTableDetail) => {
  return axiosInstance
    .put(`v1/fxTables/table/${fxTableId}`, fxTableDetail)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getFxBySupplier = (supplierId) => {
  return axiosInstance
    .get(`v1/fxTables/get/fx/association/supplier/${supplierId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

// delete legal entity by ID
export const deleteFXApi = (fxId) => {
  return axiosInstance
    .delete(`v1/fxTables/${fxId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllFxTableFiltersApi = () => {
  return axiosInstance
    .get(`v1/fxTables/filters/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
