module.exports = {
  contracts: "Contracts",
  contractTree: "Contract Tree",
  contractList: "Contract List",
  contractPipeline: "Contract Pipeline",
  createContract: "Create Contract",

  contractAuthority: "Contract Authority",
  clauseLibrary: "Clause Library",
  Template: "Template",

  commercial: "Commercial",
  spendOverview: " Spend Overview",
  contractSetup: "Contract Setup",
  invoicingView: "Invoicing View",

  actionManagement: "Action Management",
  rootAction: "Root Action",
  actionInstance: "Action Instance",

  systemSetup: "System Setup",
  organizationSetup: "Organization Setup",
  contract_Setup: "Contract Setup",

  reports: "Reports",

  userManagement: "User Management",

  tabs: {
    supplierSetup: "Supplier Setup",
    legalEntitySetup: "Legal Entity Setup",
    costCenterSetup: "Cost Center Setup",

    rateCard: "Rate Card",
    fxSetup: "FX Setup",
    pricingProfile: "Pricing Profile",
    invoicingProfile: "Invoicing Profile",
    workflow: "Workflow",

    roles: "Roles",
    users: "Users",
    groups: "Groups",
  },
};
