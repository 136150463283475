import React, { useState, useEffect } from "react";
import { Layout, Tabs, Divider } from "antd"; //antd components
import { useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { RoleAccessAtom, HeaderChangerAtom } from "../store/store";
import TopHeader from "../components/Header";
import "../assets/style/setup.css";
import PriceProfile from "../components/ContractSetup/PricingProfile/PricingProfile";
import WorkFlowSetup from "../components/ContractSetup/WorkFlow/WorkFlowSetup";
import InvoiceProfile from "../components/ContractSetup/InvoicingProfile/InvoicingProfile";
import RateCardSetup from "../components/ContractSetup/RateCardSetup/RateCardSetup";
import FxSetup from "../components/ContractSetup/FxSetup/FxSetup";
import LegalEntitySetup from "../components/OrganizationSetup/LegalEntitySetup/LegalEntitySetup";
import SupplierSetup from "../components/OrganizationSetup/SupplierSetup/SupplierSetup";
import CostCenterSetup from "../components/OrganizationSetup/CostCenterSetup/CostCenterSetup";
import person_active from "../assets/images/icons/person_4.png";
import person_inactive from "../assets/images/icons/person_inactive.png";
import adminPanel_active from "../assets/images/icons/admin_panel_active.png";
import adminPanel_inactive from "../assets/images/icons/admin_panel_settings.png";
import priceChange_active from "../assets/images/icons/cost_center_active.png";
import priceChange_inactive from "../assets/images/icons/cost_center_inactive.png";
import rateCard_active from "../assets/images/icons/rate_card_active.png";
import rateCard_inactive from "../assets/images/icons/rate_card_inactive.png";
import pricingProfile_active from "../assets/images/icons/pricing_profile_active.png";
import pricingProfile_inactive from "../assets/images/icons/pricing_profile_inactive.png";
import invoicingProfile_active from "../assets/images/icons/invoicing_profile_active.png";
import invoicingProfile_inactive from "../assets/images/icons/invoicing_profile_inactive.png";
import fxSetup_active from "../assets/images/icons/fx_setup_active.png";
import fxSetup_inactive from "../assets/images/icons/fx_setup_inactive.png";
import workflow_active from "../assets/images/icons/workflow_active.png";
import workflow_inactive from "../assets/images/icons/workflow_inactive.png";
import config from "../ConfigFile";

function SystemSetup() {
  // Variable to hold manu key value
  const menuKey = "5";
  // Variable to hold searach param value
  const [searchParams] = useSearchParams();
  // Variable to hold back button
  let backToTab = searchParams.get("back");
  // State variable to hold page access data
  const [pageAccess, setPageAccess] = useState();
  // State variable to hold logged user role
  const roleUser = useRecoilState(RoleAccessAtom); //global state
  const systemSetupAccess =
    roleUser && roleUser[0] && roleUser[0].page_access.system_setup;
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // Variable to hold local header data
  let header = headerChanger.header;
  // State variable to hold organization setup tab key values
  const [orgtabkey, setOrgTabKey] = useState(
    sessionStorage.getItem("orgTabKey")
      ? sessionStorage.getItem("orgTabKey")
      : backToTab === null
      ? "1"
      : backToTab === "org-ss"
      ? "1"
      : backToTab === "org-les"
      ? "2"
      : backToTab === "org-ccs"
      ? "3"
      : "1"
  );
  // State variable to hold contract setup tab key values
  const [contabkey, setConTabKey] = useState(
    sessionStorage.getItem("conTabKey")
      ? sessionStorage.getItem("conTabKey")
      : backToTab === null
      ? "1"
      : backToTab === "con-rcs"
      ? "1"
      : backToTab === "con-pp"
      ? "2"
      : backToTab === "con-ip"
      ? "3"
      : backToTab === "con-fx"
      ? "4"
      : backToTab === "con-cts"
      ? "5"
      : "1"
  );
  // Fcuntion to set the page access
  useEffect(() => {
    if (roleUser) {
      setPageAccess(roleUser[0]);
    }
  }, []);
  // Organization Setup Header Item Click Function
  const OrganizationSetupTab = () => {
    const onOrgKeyChange = (key) => {
      setOrgTabKey(key);
      sessionStorage.setItem("orgTabKey", key);
    };

    const showSupplierSetup = systemSetupAccess.supplier_setup.view
      ? false
      : true;
    const showLegalEntitySetup = systemSetupAccess.legal_entity_setup.view
      ? false
      : true;
    const showCostCenterSetup = systemSetupAccess.cost_center_setup.view
      ? false
      : true;

    const TabsIcon = (tab) => {
      // console.log("Inner Tab: ", selectedInnerTab);

      function TabsIconHelper(title) {
        return (
          <div className="tabs-ss-title">
            <img src={title.state.icon} /> <p>{title.state.text}</p>
          </div>
        );
      }
      return (
        <>
          <div className="tabs-title-main">
            {tab.state.selected === "1" ? (
              tab.state.orgtabkey === "1" ? (
                <TabsIconHelper
                  state={{
                    icon: person_active,
                    text: config.tabs.supplierSetup,
                  }} // From config
                />
              ) : (
                <TabsIconHelper
                  state={{
                    icon: person_inactive,
                    text: config.tabs.supplierSetup,
                  }} // From config
                />
              )
            ) : tab.state.selected === "2" ? (
              tab.state.orgtabkey === "2" ? (
                <TabsIconHelper
                  state={{
                    icon: adminPanel_active,
                    text: config.tabs.legalEntitySetup, // From config
                  }}
                />
              ) : (
                <TabsIconHelper
                  state={{
                    icon: adminPanel_inactive,
                    text: config.tabs.legalEntitySetup, // From config
                  }}
                />
              )
            ) : tab.state.selected === "3" ? (
              tab.state.orgtabkey === "3" ? (
                <TabsIconHelper
                  state={{
                    icon: priceChange_active,
                    text: config.tabs.costCenterSetup, // From config
                  }}
                />
              ) : (
                <TabsIconHelper
                  state={{
                    icon: priceChange_inactive,
                    text: config.tabs.costCenterSetup, // From config
                  }}
                />
              )
            ) : (
              ""
            )}
          </div>
        </>
      );
    };

    const supplierItems = {
      key: "1",
      label: <TabsIcon state={{ orgtabkey, selected: "1" }} />,
      children: <SupplierSetup />,
    };

    const legalEntityItems = {
      key: "2",
      label: <TabsIcon state={{ orgtabkey, selected: "2" }} />,
      children: <LegalEntitySetup />,
    };

    const costCenterItems = {
      key: "3",
      label: <TabsIcon state={{ orgtabkey, selected: "3" }} />,
      children: <CostCenterSetup />,
    };

    function addDivider() {
      const dividerItem = {
        key: Math.random(),
        label: <Divider className="tab-divider" type="vertical" />,
        disabled: true,
      };
      return dividerItem;
    }

    const permissions = [
      showSupplierSetup,
      showLegalEntitySetup,
      showCostCenterSetup,
    ];
    const allItems = [supplierItems, legalEntityItems, costCenterItems];
    const permittedItems = [];

    for (let i = 0; i < 3; i++) {
      if (permissions[i] != true) {
        permittedItems.push(allItems[i]);
        permittedItems.push(addDivider());
      }
    }
    permittedItems.pop();

    return (
      <div>
        <div className="org-setup-div">
          <Tabs
            id="system-setup-menu"
            defaultActiveKey={orgtabkey}
            items={permittedItems}
            type="card"
            className="org-setup-tabs"
            onChange={onOrgKeyChange}
          />
        </div>
      </div>
    );
  };
  //  Contracts Setup Header Item Click Function
  const ContractTab = () => {
    const onConKeyChange = (key) => {
      setConTabKey(key);
      sessionStorage.setItem("conTabKey", key);
    };

    const showRateCardSetup = systemSetupAccess.ratecard_setup.view
      ? false
      : true;
    const showPricingProfile = systemSetupAccess.pricing_profile.view
      ? false
      : true;
    const showInvoicingProfile = systemSetupAccess.invoicing_profile.view
      ? false
      : true;
    const showFxSetup = systemSetupAccess.fx_setup.view ? false : true;
    const showWorkFlow = systemSetupAccess.workflow.view ? false : true;

    const TabsIcon = (tab) => {
      // console.log("Inner Tab: ", selectedInnerTab);
      function TabsIconHelper(title) {
        return (
          <div className="tabs-ss-title">
            <img src={title.state.icon} /> <p>{title.state.text}</p>
          </div>
        );
      }
      return (
        <>
          <div className="tabs-title-main">
            {tab.state.selected === "1" ? (
              tab.state.contabkey === "1" ? (
                <TabsIconHelper
                  state={{
                    icon: rateCard_active,
                    text: config.tabs.rateCard,
                  }}
                />
              ) : (
                <TabsIconHelper
                  state={{
                    icon: rateCard_inactive,
                    text: config.tabs.rateCard,
                  }}
                />
              )
            ) : tab.state.selected === "2" ? (
              tab.state.contabkey === "2" ? (
                <TabsIconHelper
                  state={{
                    icon: fxSetup_active,
                    text: config.tabs.fxSetup,
                  }}
                />
              ) : (
                <TabsIconHelper
                  state={{ icon: fxSetup_inactive, text: config.tabs.fxSetup }}
                />
              )
            ) : tab.state.selected === "3" ? (
              tab.state.contabkey === "3" ? (
                <TabsIconHelper
                  state={{
                    icon: pricingProfile_active,
                    text: config.tabs.pricingProfile,
                  }}
                />
              ) : (
                <TabsIconHelper
                  state={{
                    icon: pricingProfile_inactive,
                    text: config.tabs.pricingProfile,
                  }}
                />
              )
            ) : tab.state.selected === "4" ? (
              tab.state.contabkey === "4" ? (
                <TabsIconHelper
                  state={{
                    icon: invoicingProfile_active,
                    text: config.tabs.invoicingProfile,
                  }}
                />
              ) : (
                <TabsIconHelper
                  state={{
                    icon: invoicingProfile_inactive,
                    text: config.tabs.invoicingProfile,
                  }}
                />
              )
            ) : tab.state.selected === "5" ? (
              tab.state.contabkey === "5" ? (
                <TabsIconHelper
                  state={{
                    icon: workflow_active,
                    text: config.tabs.workflow,
                  }}
                />
              ) : (
                <TabsIconHelper
                  state={{
                    icon: workflow_inactive,
                    text: config.tabs.workflow,
                  }}
                />
              )
            ) : (
              ""
            )}
          </div>
        </>
      );
    };

    const rateCardItems = {
      key: "1",
      label: <TabsIcon state={{ contabkey, selected: "1" }} />,
      children: <RateCardSetup />,
    };

    const fxSetupItems = {
      key: "2",
      label: <TabsIcon state={{ contabkey, selected: "2" }} />,
      children: <FxSetup />,
    };

    const pricingProfileItems = {
      key: "3",
      label: <TabsIcon state={{ contabkey, selected: "3" }} />,
      children: <PriceProfile />,
    };

    const invoicingProfileItems = {
      key: "4",
      label: <TabsIcon state={{ contabkey, selected: "4" }} />,
      children: <InvoiceProfile />,
    };

    const workflowItems = {
      key: "5",
      label: <TabsIcon state={{ contabkey, selected: "5" }} />,
      children: <WorkFlowSetup />,
    };

    function addDivider() {
      const dividerItem = {
        key: Math.random(),
        label: <Divider className="tab-divider" type="vertical" />,
        disabled: true,
      };
      return dividerItem;
    }

    const permissions = [
      showRateCardSetup,
      showFxSetup,
      showPricingProfile,
      showInvoicingProfile,
      showWorkFlow,
    ];
    const allItems = [
      rateCardItems,
      fxSetupItems,
      pricingProfileItems,
      invoicingProfileItems,
      workflowItems,
    ];
    const permittedItems = [];

    for (let i = 0; i < 5; i++) {
      if (permissions[i] != true) {
        permittedItems.push(allItems[i]);
        permittedItems.push(addDivider());
      }
    }
    permittedItems.pop();

    return (
      <div>
        <div className="org-setup-div">
          <Tabs
            id="system-setup-menu"
            defaultActiveKey={contabkey}
            items={permittedItems}
            type="card"
            className="org-setup-tabs"
            onChange={onConKeyChange}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="system-setup">
      <Layout className="header-layout">
        <TopHeader
          headerChange={header}
          feedRow={false}
          searchRow={false}
          menuKey={menuKey}
          sticky=""
        />
        <>
          {headerChanger.headerItem === "1" && (
            <div>
              <OrganizationSetupTab />
            </div>
          )}

          {headerChanger.headerItem === "2" && (
            <div>
              <ContractTab />
            </div>
          )}
        </>
      </Layout>
    </div>
  );
}

export default SystemSetup;
