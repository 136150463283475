import React, { useContext, useState, useEffect, useMemo } from "react";
import "./treecomponent.css";
import {
  Layout,
  Tree,
  Splitter,
  Spin,
  Row,
  Col,
  Card,
  Image,
  Table,
  Pagination,
  Empty,
  Divider,
} from "antd";
import {
  SyncOutlined,
  LoadingOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { TabContext } from "./TabContext";
import ContractTab from "./ContractTab";
import Breadcrumbs from "./Breadcrumbs";
import { fetchContractTree } from "../../api/testJudoApi";
import FolderActive from "../../assets/images/icons/folder.svg";
import frameworkAgreement_active from "../../assets/images/icons/framework_agreement_selected.svg";
import supplement from "../../assets/images/icons/supplement_selected.svg";
import ccn from "../../assets/images/icons/ccn_selected.svg";
import sow from "../../assets/images/icons/sow_selected.svg";
import cr from "../../assets/images/icons/cr_selected.svg";
import others from "../../assets/images/icons/others.svg";
import add from "../../assets/images/icons/Add_round.svg";
import { useRecoilState } from "recoil";
import { ContractTreeUpdateAtom } from "../../store/store";
import dayjs from "dayjs";

const TreeComponent = ({ initialBreadcrumbs }) => {
  const {
    tabs,
    setTabs,
    activeKey,
    breadcrumbs,
    setBreadcrumbs,
    setActiveKey,
    handleAddTab,
    handleRemoveTab,
  } = useContext(TabContext);

  const [expandLoader, setExpandLoader] = useState(false);
  const [supplierTree, setSupplierTree] = useState(null);
  const [contractTableData, setContractTableData] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [panelSizes, setPanelSizes] = useState(() => {
    return [
      (window.innerWidth - 40) * 0.17,
      (window.innerWidth - 40) * (1 - 0.17),
    ];
  });
  const [contractTreeUpdate, _] = useRecoilState(ContractTreeUpdateAtom);
  const [selectedColLoc, setSelectedColLoc] = useState(
    contractTreeUpdate.selectedCol
  );
  const [colEditor, openColEditor] = useState(false);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [paginateTreeData, setPaginateTreeData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });
  const [paginateTableData, setPaginateTableData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });
  const [supplierTreeLoader, setSupplierTreeLoader] = useState(true);
  const [contractTreeTableLoader, setContractTreeTableLoader] = useState(true);
  const [breadcrumbsLoader, setBreadcrumbsLoader] = useState(false);
  const [selectedTreeItem, setSelectedTreeItem] = useState(undefined);
  const [tabBreadcrumbs, setTabBreadcrumbs] = useState(initialBreadcrumbs);

  useEffect(() => {
    const handleResize = () => {
      const newSize = [
        (window.innerWidth - 10) * 0.17,
        (window.innerWidth - 10) * (1 - 0.17),
      ];
      setPanelSizes(newSize);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isColumnSorted = (column) => sortedColumn === column;

  const handleTableChange = (_, __, sorter) => {
    if (sorter.column) {
      setSortedColumn(sorter.column.dataIndex);
      setSortOrder(sorter.order);
    } else {
      setSortedColumn(null);
      setSortOrder(null);
    }
  };

  const fetchAndSetSupplierTree = async () => {
    if (!supplierTree) {
      setSupplierTreeLoader(true);
      try {
        const contractTreeRes = await fetchContractTree(
          paginateTreeData.pageNo,
          paginateTreeData.limit,
          undefined,
          undefined,
          "Supplier"
        );
        setPaginateTreeData((prev) => ({
          ...prev,
          total: contractTreeRes.totalDocs,
        }));
        setSupplierTree(contractTreeRes.docs);
      } catch (error) {
        console.error("There was an issue fetching supplier tree: ", error);
      } finally {
        setSupplierTreeLoader(false);
      }
    }
  };

  const fetchAndSetContractTree = async (
    supplier_id,
    contract_id,
    type,
    extraFlag
  ) => {
    setBreadcrumbsLoader(true);
    setContractTreeTableLoader(true);
    try {
      const contractTreeRes = await fetchContractTree(
        1,
        20,
        supplier_id === "undefined" ? undefined : supplier_id,
        contract_id,
        type
      );
      setPaginateTableData({
        pageNo: 1,
        limit: 20,
        total: contractTreeRes.totalDocs,
      });
      setSelectedTreeItem(
        extraFlag === "resetSelectedTreeItem" ? undefined : contractTreeRes.docs
      );
      console.log("contractRes: ", contractTreeRes);

      setContractTableData(contractTreeRes.docs);
    } catch (error) {
      console.error("There was an issue fetching the contract tree: ", error);
    } finally {
      setBreadcrumbsLoader(false);
      setContractTreeTableLoader(false);
    }
  };

  useEffect(() => {
    if (tabBreadcrumbs.length > 1) {
      const { supplier_id, contract_id, type } =
        tabBreadcrumbs[tabBreadcrumbs.length - 1].apiArgs;

      fetchAndSetSupplierTree();
      fetchAndSetContractTree(supplier_id, contract_id, type, "");
    } else if (
      tabBreadcrumbs.length === 1 &&
      tabBreadcrumbs[0].title === "Suppliers"
    ) {
      fetchAndSetSupplierTree();
      fetchAndSetContractTree(
        undefined,
        undefined,
        "Supplier",
        "resetSelectedTreeItem"
      );
    }
    const existingTabIndex = tabs.findIndex((t) => t.id === activeKey);
    const updatedTab = {
      ...tabs[existingTabIndex],
      breadcrumbs: tabBreadcrumbs,
    };
    const updatedTabs = [
      ...tabs.slice(0, existingTabIndex),
      updatedTab,
      ...tabs.slice(existingTabIndex + 1),
    ];

    setTabs(updatedTabs);
  }, [tabBreadcrumbs]);

  const buildNodeMap = (tree) => {
    const nodeMap = new Map();

    const traverseTree = (nodes) => {
      for (const node of nodes) {
        nodeMap.set(node._id, node); // Store each node in the map
        if (
          node.children &&
          node.children.length &&
          typeof node.children !== "boolean"
        ) {
          traverseTree(node.children); // Recursively add child nodes
        }
      }
    };

    traverseTree(tree);
    return nodeMap;
  };

  const addChildrenToTree = (tree, contractId, newChildren) => {
    // Create a map of all nodes in the tree for quick access
    const nodeMap = buildNodeMap(tree);

    console.log("nodeMap: ", nodeMap);

    // Check if the node exists and update its children
    const nodeToUpdate = nodeMap.get(contractId);
    if (nodeToUpdate) {
      nodeToUpdate.children = newChildren; // Update the children directly
    }

    return [...tree]; // Return the original tree (if mutability is a concern)
  };

  const onSelect = (_, info) => {
    console.log("selected", info);
    if (info.node.type !== "Supplier") {
      setSelectedNode({
        id: info.node._id,
        supplier_id: info.node.supplier_id,
        contract_name: info.node.title,
      });

      handleAddTab({
        id: info.node._id,
        title: info.node.title,
        children: <ContractTab contractId={info.node._id} />,
        contractId: info.node._id,
        closable: true,
        metadata: { type: "contract", id: info.node._id },
      });
    }
  };

  const onExpand = async (_, info) => {
    console.log("expanded", info);

    if (info.expanded) {
      // Check if the current node has children loaded
      if (
        info.node.children &&
        Array.isArray(info.node.children) &&
        info.node.children.length > 0 &&
        typeof info.node.children[0] !== "boolean"
      ) {
        // If children are already loaded, no need to make an API call
        console.log("Children already loaded, skipping API call");
        setExpandedKeys((prev) => [...prev, info.node._id]);
        return;
      }

      setExpandLoader(true);
      setSelectedNode(info.node._id);

      // Make an API call only if children are not loaded or if children are true
      const newTree = await fetchContractTree(
        undefined,
        undefined,
        info.node.supplier_name ? info.node._id : undefined,
        info.node._id,
        info.node.type
      ).catch((error) =>
        console.error("There was an error fetching tree children: ", error)
      );

      console.log("newTree: ", newTree);

      setSupplierTree((prevTree) => {
        const updatedTree = addChildrenToTree(
          prevTree,
          info.node._id,
          newTree[0].children
        );
        // console.log("prevTree: ", prevTree);
        // console.log("updatedTree: ", updatedTree);
        return updatedTree;
      });

      setExpandedKeys((prev) => [...prev, info.node._id]);
      setExpandLoader(false);
      setSelectedNode(null);
    } else {
      // If the node is collapsing, just remove it from the expandedKeys
      setExpandedKeys((prev) => prev.filter((key) => key !== info.node._id));
    }
  };

  const getNodeIcon = (nodeType) => {
    switch (nodeType) {
      case "Supplier":
        return (
          <img
            src={FolderActive}
            style={{ width: 14, height: 14, marginRight: 8 }}
            alt="Folder Icon"
          />
        );
      case "MSA":
        return (
          <img
            src={frameworkAgreement_active}
            style={{ width: 20, height: 16, marginRight: 7 }}
            alt="Folder Icon"
          />
        );
      case "Supplement" || "Sub-Supplement":
        return (
          <img
            src={supplement}
            style={{ width: 22, height: 22, marginRight: 6 }}
            alt="Folder Icon"
          />
        );
      case "CCN":
        return (
          <img
            src={ccn}
            style={{ width: 25, height: 25, marginRight: 6 }}
            alt="Folder Icon"
          />
        );
      case "SOW":
        return (
          <img
            src={sow}
            style={{ width: 20, height: 20, marginRight: 6 }}
            alt="Folder Icon"
          />
        );
      case "CR":
        return (
          <img
            src={cr}
            style={{ width: 20, height: 20, marginRight: 6 }}
            alt="Folder Icon"
          />
        );
      default:
        return (
          <img
            src={others}
            style={{ width: 15, height: 15, marginRight: 6 }}
            alt="Folder Icon"
          />
        );
    }
  };

  const treeTitleRender = (node) => {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          padding: "3px 5px",
          color: "var(--color-solid-darkergrey)",
        }}
      >
        {getNodeIcon(node.type)}
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 12,
            fontWeight: 600,
            maxWidth: panelSizes[0] - 74,
          }}
        >
          {node.title}
        </span>
      </span>
    );
  };

  const switcherIcon = (node) => {
    // Return loading icon if this node is currently loading
    if (selectedNode === node._id && expandLoader) {
      return (
        <SyncOutlined
          spin
          style={{ color: "var(--color-solid-darkerblue)", fontSize: 12 }}
        />
      );
    }
    return <DownOutlined />;
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const contractTreeColumns = useMemo(() => {
    return [
      {
        title: renderColumnTitle("Contract Name", "title"),
        dataIndex: "title",
        align: "left",
        show: selectedColLoc.includes("Name") ? true : false,
        sorter: (a, b) =>
          a.name.props.children[1].props.children.localeCompare(
            b.name.props.children[1].props.children
          ),
        sortOrder: isColumnSorted("title") ? sortOrder : false,
        ellipsis: true,
        render: (text, record) => {
          return (
            <span
              className="contract-table-cell"
              onClick={() => {
                console.log("record: ", record);

                setTabBreadcrumbs((prev) => {
                  const newBreadcrumbs = [
                    ...prev,
                    {
                      title: record.title,
                      apiArgs: {
                        supplier_id: record.supplier_name
                          ? record._id
                          : undefined,
                        contract_id: record._id,
                        type: record.type,
                      },
                    },
                  ];
                  return newBreadcrumbs;
                });
              }}
            >
              {getNodeIcon(record.type)}
              {text}
            </span>
          );
        },
      },
      {
        title: renderColumnTitle("Contract Type", "type"),
        dataIndex: "type",
        align: "left",
        show: selectedColLoc.includes("Contract Type") ? true : false,
        sorter: (a, b) => a.contract_type.localeCompare(b.contract_type),
        sortOrder: isColumnSorted("type") ? sortOrder : false,
        ellipsis: true,
        width: 140,
      },
      {
        title: renderColumnTitle("Status", "status"),
        dataIndex: "status",
        align: "left",
        show: selectedColLoc.includes("Status") ? true : false,
        sorter: (a, b) => a.status.localeCompare(b.status),
        sortOrder: isColumnSorted("status") ? sortOrder : false,
        ellipsis: true,
        width: 200,
        render: (text) => (
          <span>
            {text &&
              text
                .replace(/-/g, " ")
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
          </span>
        ),
      },
      {
        title: renderColumnTitle("Created Date", "created_on"),
        dataIndex: "created_on",
        align: "left",
        show: selectedColLoc.includes("Created Date") ? true : false,
        sorter: (a, b) => a.date.localeCompare(b.date),
        sortOrder: isColumnSorted("created_on") ? sortOrder : false,
        ellipsis: true,
        width: 140,
        render: (text) => dayjs(text).format("DD/MM/YYYY"),
      },
      {
        title: (
          <Image
            src={add}
            className="cursor-pointer"
            preview={false}
            onClick={() => openColEditor(true)}
          />
        ),
        dataIndex: "add",
        align: "center",
        width: 50,
      },
    ].filter((col) => col.show !== false);
  }, [selectedColLoc]);

  const onTreePageChange = (page, limit) => {
    setSupplierTreeLoader(true);
    fetchContractTree(page, limit, undefined, undefined, "Supplier")
      .then((contractTreeRes) => {
        setExpandedKeys([]);
        setPaginateTreeData((prev) => ({
          ...prev,
          pageNo: page,
          limit: limit,
          total: contractTreeRes.totalDocs,
        }));
        setSupplierTree(contractTreeRes.docs);
      })
      .catch((error) =>
        console.error("There was an issue fetching supplier tree: ", error)
      )
      .finally(() => setSupplierTreeLoader(false));
  };

  const onSupplierTablePageChange = (page, limit) => {
    setContractTreeTableLoader(true);
    fetchContractTree(page, limit, undefined, undefined, "Supplier")
      .then((contractTreeRes) => {
        setPaginateTableData((prev) => ({
          ...prev,
          pageNo: page,
          limit: limit,
          total: contractTreeRes.totalDocs,
        }));
        setContractTableData(contractTreeRes.docs);
      })
      .catch((error) =>
        console.error("There was an issue fetching supplier tree: ", error)
      )
      .finally(() => setContractTreeTableLoader(false));
  };

  const onChildrenTablePageChange = (page, limit, childrenType) => {
    setBreadcrumbsLoader(true);
    fetchContractTree(
      page,
      limit,
      childrenType === "Supplier" ? selectedTreeItem[0]._id : undefined,
      selectedTreeItem[0]._id,
      selectedTreeItem[0].type
    )
      .then((contractTreeRes) => {
        setPaginateTableData((prev) => ({
          ...prev,
          pageNo: page,
          limit: limit,
          total: contractTreeRes.totalDocs,
        }));
        setSelectedTreeItem(contractTreeRes.docs);
      })
      .catch((error) =>
        console.error("There was an issue fetching supplier tree: ", error)
      )
      .finally(() => setBreadcrumbsLoader(false));
  };

  return (
    <Layout className="tree-component-layout">
      <Row gutter={[8, 8]} align="middle">
        <Col span={24}>
          <Card
            id="breadcrumb-card-id"
            rootClassName="breadcrumb-card"
            bordered={false}
          >
            <Breadcrumbs
              tabBreadcrumbs={tabBreadcrumbs}
              setTabBreadcrumbs={setTabBreadcrumbs}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Splitter
            className="contract-tree-splitter"
            style={{ height: "calc(100vh - 154px)" }}
            onResize={setPanelSizes}
          >
            <Splitter.Panel
              collapsible
              // defaultSize={panelSizes[0]}
              defaultSize="17%"
              min="17%"
              max="50%"
            >
              <Spin
                spinning={supplierTreeLoader}
                indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
              >
                <Tree
                  treeData={supplierTree}
                  className="supplier-contract-tree"
                  rootClassName={
                    paginateTreeData.total <= 20
                      ? "supplier-contract-tree-wrapper-stretched"
                      : "supplier-contract-tree-wrapper"
                  }
                  showIcon
                  showLine
                  switcherIcon={switcherIcon}
                  onSelect={onSelect}
                  onExpand={onExpand}
                  expandedKeys={expandedKeys}
                  titleRender={treeTitleRender}
                />
              </Spin>
              <Pagination
                hideOnSinglePage
                showLessItems
                showSizeChanger={false}
                className="mt-10"
                align="end"
                defaultPageSize={paginateTreeData.limit}
                current={paginateTreeData.pageNo}
                total={paginateTreeData.total}
                onChange={onTreePageChange}
              />
            </Splitter.Panel>
            {!selectedTreeItem && (
              <Splitter.Panel collapsible={false}>
                <Spin
                  spinning={contractTreeTableLoader}
                  indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
                >
                  <Table
                    dataSource={contractTableData}
                    columns={contractTreeColumns}
                    onChange={handleTableChange}
                    showSorterTooltip={false}
                    bordered
                    size="large "
                    expandable={{ showExpandColumn: false }}
                    scroll={{
                      x: "calc(500px + 50%)",
                      y:
                        paginateTableData.total <= 20
                          ? "calc(100vh - 199px)"
                          : "calc(100vh - 243px)",
                    }}
                    pagination={false}
                    className="system-setup-table ml-5"
                    locale={{
                      emptyText: (
                        <Empty
                          style={{ height: "calc(100vh - 243px)" }}
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                      ),
                    }}
                  />
                  <Pagination
                    hideOnSinglePage
                    className="mt-10"
                    align="end"
                    defaultPageSize={paginateTableData.limit}
                    current={paginateTableData.pageNo}
                    total={paginateTableData.total}
                    onChange={onSupplierTablePageChange}
                  />
                </Spin>
              </Splitter.Panel>
            )}
            {selectedTreeItem && selectedTreeItem[0].type === "Supplier" && (
              <Splitter.Panel>
                <Spin
                  spinning={breadcrumbsLoader}
                  indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
                >
                  {selectedTreeItem[0].children.length !== 0 &&
                  Object.keys(selectedTreeItem[0].children[0]).length <= 1 &&
                  selectedTreeItem[0].children[0].children.length === 0 ? (
                    <Empty
                      style={{
                        height: "calc(100vh - 264px)",
                      }}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={
                        <>
                          <span style={{ fontWeight: 600 }}>
                            {`${selectedTreeItem[0].title}`}
                          </span>
                          <span> has no contracts under it</span>
                        </>
                      }
                    />
                  ) : (
                    <>
                      <Table
                        dataSource={selectedTreeItem[0].children}
                        columns={contractTreeColumns}
                        onChange={handleTableChange}
                        showSorterTooltip={false}
                        bordered
                        size="large "
                        expandable={{ showExpandColumn: false }}
                        scroll={{
                          x: "calc(500px + 50%)",
                          y: "calc(100vh - 243px)",
                        }}
                        pagination={false}
                        className="system-setup-table ml-5"
                        locale={{
                          emptyText: (
                            <Empty
                              style={{ height: "calc(100vh - 243px)" }}
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                          ),
                        }}
                      />
                      <Pagination
                        hideOnSinglePage
                        className="mt-10"
                        align="end"
                        defaultPageSize={paginateTableData.limit}
                        current={paginateTableData.pageNo}
                        total={paginateTableData.total}
                        onChange={(page, limit) =>
                          onChildrenTablePageChange(page, limit, "Supplier")
                        }
                      />
                    </>
                  )}
                </Spin>
              </Splitter.Panel>
            )}
            {selectedTreeItem && selectedTreeItem[0].type !== "Supplier" && (
              <Splitter.Panel>
                <Spin
                  spinning={breadcrumbsLoader}
                  indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
                >
                  <Row gutter={[0, 8]}>
                    <Col span={24}>
                      <Card bordered={false} rootClassName="contract-type-card">
                        Parent Contract
                      </Card>
                    </Col>
                    <Col span={24}>
                      <Table
                        dataSource={selectedTreeItem}
                        columns={contractTreeColumns}
                        onChange={handleTableChange}
                        showSorterTooltip={false}
                        bordered
                        size="large "
                        expandable={{ showExpandColumn: false }}
                        scroll={{
                          x: "calc(500px + 50%)",
                          y: "calc(100vh - 243px)",
                        }}
                        pagination={false}
                        className="system-setup-table ml-5"
                        locale={{
                          emptyText: (
                            <Empty
                              style={{ height: "calc(100vh - 243px)" }}
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                          ),
                        }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[0, 8]} className="mt-30">
                    <Col span={24}>
                      <Card rootClassName="contract-type-card">
                        Child Contract
                      </Card>
                    </Col>
                    <Col span={24}>
                      <Table
                        dataSource={selectedTreeItem[0].children}
                        columns={contractTreeColumns}
                        onChange={handleTableChange}
                        showSorterTooltip={false}
                        bordered
                        size="large "
                        expandable={{ showExpandColumn: false }}
                        scroll={{
                          x: "calc(500px + 50%)",
                          y: "calc(100vh - 442px)",
                        }}
                        pagination={false}
                        className="system-setup-table ml-5"
                        locale={{
                          emptyText: (
                            <Empty
                              style={{ height: "calc(100vh - 243px)" }}
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                          ),
                        }}
                      />
                      <Pagination
                        hideOnSinglePage
                        className="mt-10"
                        align="end"
                        defaultPageSize={paginateTableData.limit}
                        current={paginateTableData.pageNo}
                        total={paginateTableData.total}
                        onChange={(page, limit) =>
                          onChildrenTablePageChange(page, limit, "Contract")
                        }
                      />
                    </Col>
                  </Row>
                </Spin>
              </Splitter.Panel>
            )}
          </Splitter>
        </Col>
      </Row>
    </Layout>
  );
};

export default TreeComponent;
