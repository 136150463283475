import React, { createContext, useEffect, useState } from "react";

export const TabContext = createContext();

export const TabProvider = ({ children }) => {
  const [tabs, setTabs] = useState(() => {
    const storedTabs = JSON.parse(localStorage.getItem("tabs") || "[]");
    return storedTabs.length
      ? storedTabs
      : [
          {
            id: "contract-tree",
            breadcrumbs: [
              {
                title: "Suppliers",
                apiArgs: {
                  supplier_id: "undefined",
                  contract_id: "undefined",
                  type: "Supplier",
                },
              },
            ],
            title: "Contracts",
          },
        ];
  });

  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      title: "Suppliers",
      apiArgs: {
        supplier_id: "undefined",
        contract_id: "undefined",
        type: "Supplier",
      },
    },
  ]);
  const [activeKey, setActiveKey] = useState("contract-tree");

  // Store tabs metadata (without components) in localStorage whenever they change
  useEffect(() => {
    const tabsToStore = tabs.map(({ id, title, breadcrumbs }) => ({
      id,
      title,
      breadcrumbs,
    }));
    localStorage.setItem("tabs", JSON.stringify(tabsToStore));
  }, [tabs]);

  const handleAddTab = (tab) => {
    const existingTab = tabs.find((t) => t.id === tab.id);
    if (existingTab) {
      setActiveKey(existingTab.id);
    } else {
      const newTabId = tab.id;
      const newTab = {
        id: newTabId,
        title: tab.title || `New Tab ${tabs.length + 1}`,
        breadcrumbs: tab.breadcrumbs,
        children: tab.children,
        closable: true,
      };
      setTabs((prevTabs) => [...prevTabs, newTab]);
      setActiveKey(newTabId);
    }
  };

  const handleRemoveTab = (targetKey) => {
    setTabs((prevTabs) => {
      const remainingTabs = prevTabs.filter((tab) => tab.id !== targetKey);
      if (targetKey === activeKey) {
        setActiveKey(
          remainingTabs.length > 0 ? remainingTabs[0].id : "contract-tree"
        );
      }
      return remainingTabs;
    });
  };

  return (
    <TabContext.Provider
      value={{
        tabs,
        setTabs,
        activeKey,
        setActiveKey,
        breadcrumbs,
        setBreadcrumbs,
        handleAddTab,
        handleRemoveTab,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};
