import { axiosInstance } from "../utils/common";

export const createReportApi = (reportData) => {
  return axiosInstance
    .post("v1/report", reportData)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllReportData = () => {
  return axiosInstance
    .get("v1/report/only")
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getReportByIdData = (reportId) => {
  return axiosInstance
    .get(`v1/report/single/${reportId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const reportPaginationApi = (page, limit, filterObj) => {
  return (
    axiosInstance
      // .get(`v1/report/reportApi/pagination?limit=${limit}&page=${page}`)
      .post(`v1/report/reportApi/pagination?limit=${limit}&page=${page}`, {
        allFilterVal: filterObj,
      })
      .then((res) => res.data)
      .catch((err) => console.log(err))
  );
};

export const scheduleReportPaginationApi = (page, limit, filterObj) => {
  return (
    axiosInstance
      // .get(`v1/report/reportApi/schedule/pagination?limit=${limit}&page=${page}`)
      .post(
        `v1/report/reportApi/schedule/pagination?limit=${limit}&page=${page}`,
        {
          allFilterVal: filterObj,
        }
      )
      .then((res) => res.data)
      .catch((err) => console.log(err))
  );
};

export const updateReportApi = (reportId, reportData) => {
  return axiosInstance
    .patch(`v1/report/update/${reportId}`, reportData)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllBuildReportFiltersApi = () => {
  return axiosInstance
    .get(`v1/report/filters/build-report/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getAllScheduleReportFiltersApi = () => {
  return axiosInstance
    .get(`v1/report/filters/schedule-report/all/`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const getScheduledReportFileApi = (updateBody) => {
  return axiosInstance
    .post(`v1/report/read/file`, updateBody)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
