import React, { useState, useEffect } from "react";
import { Image, Layout, Tabs } from "antd"; //antd components
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import TopHeader from "../components/Header";
import "../assets/style/contract.css";
import FolderOpen from "../assets/images/icons/folder_open_white.svg";
import Pipeline from "../assets/images/icons/pipeline.svg";
import CreateImg from "../assets/images/icons/create.svg";
import ViewImg from "../assets/images/icons/view.svg";
import ContractPipeline from "../components/Contract/ContractPipeline";
import CreateContract from "../components/Contract/CreateContract";
import ContractTreeTab from "../components/Contract/ContractTreeTab";
import ViewContract from "../components/Contract/ViewContract";
import axios from "axios";
import ContractFile from "../components/Contract/ContractFile";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom } from "../store/store";
import ContractList from "../components/Contract/ContractList";

function Contract() {
  // Variable to hold search param value
  const [searchParams] = useSearchParams();
  // Variable to hold view contract data
  let backToTab = searchParams.get("view");
  // Variable to hold manu key
  const menuKey = "2";
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // Variable to hold local header data
  let header = headerChanger.header;
  // Variable to hold header item value
  const tabkey = headerChanger.headerItem;
  // State variable to hold the screen size
  const [screenSize, setScreenSize] = useState("min");
  // State variable to hold pipeline table data
  const [pipelineTable, setPipelineTable] = useState(true);
  /* Function to handle change the screen size
   * @Param val - a screen size
   */
  const changeScreenSize = (val) => {
    setScreenSize(val);
  };

  return (
    <div className="contract">
      <Layout className="header-layout">
        <div style={{ display: screenSize === "min" ? "block" : "none" }}>
          <TopHeader
            headerChange={header}
            feedRow={false}
            searchRow={false}
            menuKey={menuKey}
            sticky=""
          />
        </div>

        {backToTab !== "con" && (
          <div>
            <div className="contract-tab-content">
              {tabkey === "1" && (
                <div className="tree-div">
                  <ContractTreeTab changeScreenSize={changeScreenSize} />
                </div>
              )}
            </div>
            <div className="contract-tab-content">
              {tabkey === "2" && (
                <div>
                  <ContractPipeline
                    changeScreenSize={changeScreenSize}
                    pipelineTable={pipelineTable}
                  />
                </div>
              )}
            </div>
            <div className="contract-tab-content">
              {tabkey === "3" && (
                <div className="create-contract-div">
                  <CreateContract />
                </div>
              )}
            </div>
            <div className="contract-tab-content">
              {tabkey === "4" && (
                <div>
                  <ContractList />
                </div>
              )}
            </div>
          </div>
        )}
      </Layout>
    </div>
  );
}

export default Contract;
