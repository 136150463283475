import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Image, Row, Col, Layout, Table, Pagination, message } from "antd"; //antd components
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import "../../assets/style/setup.css";
import "../../assets/style/contractpipeline.css";
import edit from "../../assets/images/icons/edit_pen.svg";
import * as XLSX from "xlsx";
import { reportArrays } from "../../utils/data";
import AggregateValue from "../Contract/AggregateValue";
import { userDataAtom, RoleAccessAtom } from "../../store/store";
import {
  scheduleReportPaginationApi,
  getAllScheduleReportFiltersApi,
} from "../../api/reportApi";
import { suppliersOnlyApi } from "../../api/suppliersApi";
import {
  contractFilterApi,
  getAggregateValueApi,
} from "../../api/contractsApi";
import { legalEntitysOnlyApi } from "../../api/legalEntityApi";
import { costCenterOnlyApi } from "../../api/costCenterApi";
import { getRatecardReportApi } from "../../api/ratecardApi";
import { getFxTableReportApi } from "../../api/fxTableApi";
import { getPricingProfileReportApi } from "../../api/pricingProfileApi";
import { getAllInvoicingProfileReportApi } from "../../api/invoicingProfileApi";
import { getSingleWorkflowsReportApi } from "../../api/workflowApi";
import { getFiltersApi } from "../../api/usersApi";
import moment from "moment";
import GlobalFilter from "../GlobalFilter";

const ScheduledReport = () => {
  const userData = useRecoilState(userDataAtom); // Stores logged in user details from global state atom
  const navigate = useNavigate(); // Stores navigate function to navigate to other pages
  const [reportDataSource, setReportDataSource] = useState([]); // Stores API response of the selected report & used when downloading report
  const [rateCardSetup, setRateCardSetup] = useState([]); // Stores RateCard report column names
  /* Stores raw Contract Commercial data from API which is sent to AggregateValue component, to display the Contract Aggregate Table.
   * Object parameters:
   * mode: Set to "view" so that the Aggregate table is in view only mode.
   * report: Set to boolean true when Contract Commercial report is generated.
   * contracts: Key added to commercialData after getting the raw Contract commercial data from API. */
  const [commercialData, setCommercialData] = useState({
    mode: "view",
    report: false,
  });
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  const [selectedColLoc, setSelectedColLoc] = useState([]); // Stores the names of Report Columns that needs to be displayed
  const [download, setDownload] = useState(false); // Set to boolean true when the report needs to be downloaded
  const [generateReportData, SetGenerateReportData] = useState(); // Stores report details when report needs to be downloaded
  const [scheduleTabletData, setScheduleTabletData] = useState([]); // Stores the Schedule Report table list of reports & passed to AntD table
  const [loader, setLoader] = useState(false); // Stores boolean value on whether to trigger the loading animation of table
  /* Stores the processed Contract Commercial Aggregate data.
   * setAggregateData function is passed to AggregateValue component as a custom prop,
     so that aggregate data can be passed down to this component to view the Aggregate table or download the excel. */
  const [aggregateData, setAggregateData] = useState({});
  const aggregateOrder = reportArrays["aggregateOrder"]; // Stores the correct order of Aggregate table columns
  const aggregateExpandedOrder = reportArrays["aggregateExpandedOrder"]; // Stores the correct order of expanded Aggregate table columns
  const monthMapping = reportArrays["monthMapping"]; // Stores the names of months of the year
  const [allFilterVal, setAllFilterVal] = useState({}); // Stores all the filter conditions
  const [savedFilters, setSavedFilters] = useState([]); // Stores all saved filter values (Public & Private)
  const [filterDynamic, setFilterDynamic] = useState([]); // Stores all possible filter conditions for the table
  const [messageApi, saveMessageContextHolder] = message.useMessage(); // Displays success message upon successfully saving filter

  /* Function to handle getting data from API according to type of report
   * @Param data - Holds the selected report details
   */
  const getReportDetails = (data) => {
    const getAllContractData = (resData) => {
      const reportApiData =
        resData &&
        resData.map((i, index) => {
          let tableRow = {
            key: i._id,
            supplier: i.supplier_name,
            contract_type: i.contract_type,
            parent_contract: i.parent_contract,
            contract_name: i.contract_name,
            // contract_description: "",
            start_date: i.start_date
              ? moment(i.start_date).format("DD MMMM YYYY")
              : "",
            end_date: i.end_date
              ? moment(i.end_date).format("DD MMMM YYYY")
              : "",
            "acv_(eur)": i.contract_value_acv,
            "tcv_(eur)": i.contract_value_tcv,
            initiator: i.initiator,
            approval_levels: i.approval_levels,
            current_approval_level: i.current_approval_level,
            current_approver: i.current_approver,
            submitted_date: i.submitted_date
              ? moment(i.submitted_date).format("DD MMMM YYYY")
              : "",
            ageing: i.ageing,
            approved_date: i.updatedAt
              ? moment(i.updatedAt).format("DD MMMM YYYY")
              : "",
            rejection_level: i.rejection_level,
            rejected_date: i.rejected_date,
            reason_for_rejection: i.reason_for_rejection,
          };
          return tableRow;
        });
      console.log("Contract Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllSupplierData = (resData) => {
      const reportApiData = resData.map((i, index) => {
        let tableRow = {
          supplier_id: i.id,
          supplier_name: i.supplier_name,
          supplier_name_short: i.supplier_name_short,
          supplier_entity: i.supplier_entity,
          supplier_status: i.supplier_status,
          supplier_address: i.supplier_address,
          supplier_country: i.supplier_country,
        };
        return tableRow;
      });
      console.log("Supplier Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllLegalData = (resData) => {
      const reportApiData = resData.map((i, index) => {
        let tableRow = {
          legal_entity_id: i._id,
          legal_entity_name: i.le_name,
          legal_entity_status: i.le_status,
          legal_entity_address: i.le_address,
          legal_entity_country: i.le_country,
          created_on: i["createdAt"]
            ? moment(i["createdAt"]).format("DD MMMM YYYY")
            : "",
          last_updated_on: i.updatedAt
            ? moment(i.updatedAt).format("DD MMMM YYYY")
            : "",
        };
        return tableRow;
      });
      console.log("Legal Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllCostData = (resData) => {
      const reportApiData = resData.map((i, index) => {
        let tableRow = {
          cost_center_id: i._id,
          cost_center_name: i.cc_name,
          cost_center_number: i.cc_nbr,
          cost_center_status: i.cc_status,
          legal_entity_id: i.le_id,
          legal_entity_name: i.le_name,
          created_on: i["createdAt"]
            ? moment(i["createdAt"]).format("DD MMMM YYYY")
            : "",
          last_updated_on: i.updatedAt
            ? moment(i.updatedAt).format("DD MMMM YYYY")
            : "",
        };
        return tableRow;
      });
      console.log("Cost Center Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllRateCardData = (resData) => {
      const rateColName = resData[0].rt_items_desc;
      setRateCardSetup(rateColName);
      console.log("RateColName: ", rateColName);
      const rateData = resData[0].rt_items_detail;
      const reportApiData = rateData.map((row) => {
        const rowData = {};
        rateColName.forEach((colHeader, index) => {
          const formattedKey = colHeader.toLowerCase().replace(/\s+/g, "_"); // Convert to lowercase and replace spaces with underscores
          rowData[formattedKey] = row[index];
        });
        return rowData;
      });
      console.log("Rate Card Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllFxData = (resData, fx_type) => {
      console.log(resData, fx_type);
      const reportApiData = resData.map((i, index) => {
        let tableRow =
          fx_type === "contractual"
            ? {
                billing_currency: i.billing_currency,
                year: i.year,
              }
            : fx_type === "spot"
            ? {
                billing_currency: i.billing_currency,
                jan: i.Jan,
                feb: i.Feb,
                mar: i.Mar,
                apr: i.Apr,
                may: i.May,
                jun: i.Jun,
                jul: i.Jul,
                aug: i.Aug,
                sep: i.Sep,
                oct: i.Oct,
                nov: i.Nov,
                dec: i.Dec,
              }
            : "";
        return tableRow;
      });
      console.log("FX Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllPricingData = (resData) => {
      const reportApiData = resData.map((i, index) => {
        let contract_cc = i.contract_cc.join(", ");
        let delivery_cc = i.delivery_cc.join(", ");
        let fx_table = i.fx_table.join(", ");
        let contract_pricing = i.contract_pricing
          .map((ele) => {
            return ele === 1
              ? "Fixed"
              : ele === 2
              ? "Variable"
              : ele === 3
              ? "Agreed"
              : ele === 4
              ? "All"
              : "";
          })
          .join(", ");
        let tableRow = {
          pricing_profile_id: i._id,
          pricing_profile_name: i.price_profile_name,
          pricing_profile_status: i.status,
          supplier_id: i.supplier_id,
          supplier_name: i.supplier_name,
          contract_pricing: contract_pricing,
          contract_currency: contract_cc,
          delivery_currency: delivery_cc,
          fx_table: fx_table,
          created_on: i.created_on
            ? moment(i.created_on).format("DD MMMM YYYY")
            : i.createdAt
            ? moment(i.createdAt).format("DD MMMM YYYY")
            : "",
          last_updated_on: i.updated_on
            ? moment(i.updated_on).format("DD MMMM YYYY")
            : i.updatedAt
            ? moment(i.updatedAt).format("DD MMMM YYYY")
            : "",
        };
        return tableRow;
      });
      console.log("Pricing Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllInvoicingData = (resData) => {
      const reportApiData = resData.map((i, index) => {
        let tableRow = {
          from_entity: i.from_entity,
          to_entity: i.to_entity,
          allocation_percentage: i.allocation_p,
        };
        return tableRow;
      });
      console.log("Invoicing Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    const getAllWorkflowData = (resData) => {
      const levelData = resData[0].workflow_metadata;
      const reportApiData = [];
      Object.keys(levelData).forEach((level, levelIndex) => {
        levelData[level].forEach((approverData) => {
          let fromNode = approverData.from.join(", ");
          let toNode = approverData.to.join(", ");
          const rowData = {
            step: levelIndex + 1,
            step_fulfillment: approverData.stepFulfillment,
            from: fromNode,
            to: toNode,
            approver: approverData.approver,
            approval_type: approverData.approvalType,
            email_id: approverData.emailId,
          };
          reportApiData.push(rowData);
        });
      });
      console.log("Workflow Data: ", reportApiData);
      setReportDataSource(reportApiData);
    };

    console.log(data, "data");
    if (data.report_category === "contract_data") {
      contractFilterApi(
        data.type_of_report,
        data.supplier_id,
        data.contract_id !== undefined ? data.contract_id : "null",
        data.contract_start_date !== undefined
          ? data.contract_start_date
          : "null",
        data.contract_end_date !== undefined ? data.contract_end_date : "null",
        data.contract_status
      ).then((res) => {
        console.log("contractFilterApi response", res);
        getAllContractData(res);
      });
    } else if (data.report_category === "commercial_data") {
      if (data.type_of_report === "commercial") {
        getAggregateValueApi(data.contract_id, data.contract_type).then(
          (res) => {
            console.log("commercialFilterApi response", res);
            setCommercialData({
              ...commercialData,
              report: true,
              download: true,
              contracts: res.docs[0],
            });
          }
        );
      }
    } else if (data.report_category === "setup_data") {
      if (data.setup_report === "supplier_setup") {
        suppliersOnlyApi().then((res) => {
          console.log("supplierFilterApi response", res);
          getAllSupplierData(res);
        });
      } else if (data.setup_report === "legal_setup") {
        legalEntitysOnlyApi().then((res) => {
          console.log("legalFilterApi response", res);
          getAllLegalData(res);
        });
      } else if (data.setup_report === "cost_setup") {
        costCenterOnlyApi().then((res) => {
          console.log("costFilterApi response", res);
          getAllCostData(res);
        });
      } else if (data.setup_report === "ratecard_setup") {
        console.log("rateCardId", data.ratecard_id);
        getRatecardReportApi(data.ratecard_id).then((res) => {
          console.log("rateCardFilterApi response", res);
          getAllRateCardData(res);
        });
      } else if (data.setup_report === "fx_setup") {
        console.log(
          "fxTableId, fxTableType",
          data.fx_table_id,
          data.fx_table_type
        );
        getFxTableReportApi(data.fx_table_id, data.fx_table_type).then(
          (res) => {
            console.log("fxFilterApi response", res);
            getAllFxData(res, data.fx_table_type);
          }
        );
      } else if (data.setup_report === "pricing_setup") {
        getPricingProfileReportApi(data.pricing_profile_id).then((res) => {
          console.log("pricingFilterApi response", res);
          getAllPricingData(res);
        });
      } else if (data.setup_report === "invoicing_setup") {
        getAllInvoicingProfileReportApi(data.invoicing_profile_id).then(
          (res) => {
            console.log("invoicingFilterApi response", res);
            getAllInvoicingData(res);
          }
        );
      } else if (data.setup_report === "workflow_setup") {
        getSingleWorkflowsReportApi(data.workflow_id).then((res) => {
          console.log("workflowFilterApi response", res);
          getAllWorkflowData(res);
        });
      }
    }
  };

  /* Function to handle processing of report data into a format which can be downloaded as an Excel file
   * @Param data - Holds the selected report details
   */
  const exportExcel = (data) => {
    let excelData = [];
    let columnWidths = [];
    const capitalize = (column_name) => {
      //Capitalize first letter and remove "_"
      let words = column_name.split("_");
      let capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      let result = capitalizedWords.join(" ");
      let temp = result.split(" ");
      if (result === "Acv (eur)" || result === "Tcv (eur)") {
        result = result.toUpperCase();
      } else if (result.split(" ")[result.split(" ").length - 1] === "Id") {
        temp[temp.length - 1] = temp[temp.length - 1].toUpperCase();
        result = temp.join(" ");
      } else if (result.split(" ")[0] === "Cr") {
        temp[0] = temp[0].toUpperCase();
        result = temp.join(" ");
      }
      return result;
    };
    console.log("Aggregate Data: ", aggregateData);
    if (
      Object.keys(aggregateData).length !== 0 &&
      generateReportData.type_of_report === "commercial"
    ) {
      //Executes for Commercial Report
      const flattenData = (data, order) => {
        return data.reduce((result, item) => {
          const {
            children,
            contract,
            original_value,
            cr_value,
            net_value,
            jan,
            feb,
            mar,
            apr,
            may,
            jun,
            jul,
            aug,
            sep,
            oct,
            nov,
            dec,
            total,
          } = item;
          const currentContract = contract;
          const orderedObject = {
            contract: currentContract,
            original_value,
            cr_value,
            net_value,
            jan,
            feb,
            mar,
            apr,
            may,
            jun,
            jul,
            aug,
            sep,
            oct,
            nov,
            dec,
            total,
          };
          const flattenedItem = Object.fromEntries(
            order.map((key) => [monthMapping[key] || key, orderedObject[key]])
          );
          result.push(flattenedItem);
          if (children) {
            result = result.concat(flattenData(children, order));
          }
          return result;
        }, []);
      };

      const aggregateDataSource = flattenData(
        aggregateData.tableData,
        aggregateData.expanded ? aggregateExpandedOrder : aggregateOrder
      );
      console.log("AggregateDataSource: ", aggregateDataSource);
      excelData = aggregateDataSource.map((row) => {
        let rowArr = Object.entries(row);
        rowArr.map((column) => {
          column[0] = capitalize(column[0]);
          return column;
        });
        return Object.fromEntries(rowArr);
      });
      columnWidths = Object.entries(excelData[0]).map((column) => ({
        wch: column[0].length + 8,
      }));
      console.log("Aggregate ExcelData: ", excelData);
    } else {
      //Executes for Contract/Setup Report
      excelData = reportDataSource.map((row) => {
        let rowArr = Object.entries(row);
        let selectedRowArr = rowArr.filter((column) => {
          if (selectedColLoc.includes(capitalize(column[0]))) {
            column[0] = capitalize(column[0]);
            return column;
          }
        });
        return Object.fromEntries(selectedRowArr);
      });

      console.log("ExcelData: ", excelData);

      columnWidths = selectedColLoc.map((columnName) => ({
        wch: columnName.length + 8, //Adjusting Column Width in the Excel Sheet
      }));
    }

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(excelData, {
      cols: columnWidths,
    });
    workSheet["!cols"] = columnWidths;

    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      //This parameter is for setting the Excel Sheet Name
      `${capitalize(
        data.setup_report !== "setup_data"
          ? data.type_of_report === "cr_report" ||
            data.type_of_report === "msa_report"
            ? data.type_of_report
            : `${data.type_of_report + "_report"}`
          : data.type_of_report
      )}`
    );
    XLSX.writeFile(workBook, `${data.report_name}.xlsx`);
    console.log("Exported");
    setDownload(false);
    setLoader(false);
  };

  /* Precursor function to exportExcel function which prepares the selected report data
   * @Param data - Holds the selected report details
   */
  const downloadExcel = (data) => {
    setSelectedColLoc(
      data.column_desc.length !== 0
        ? data.column_desc
        : data.type_of_report === "fx_setup"
        ? reportArrays[data.type_of_report][data.fx_table_type]
        : data.type_of_report === "ratecard_setup"
        ? rateCardSetup
        : reportArrays[data.type_of_report]
    );
    console.log(data);
    getReportDetails(data);
  };

  // Function which initiates download of report as Excel after selected report data is ready
  useEffect(() => {
    if (
      download &&
      (generateReportData.report_category === "contract_data" ||
        generateReportData.report_category === "setup_data")
    ) {
      exportExcel(generateReportData);
    }
  }, [reportDataSource]);

  // Function which initiates download of Contract Commercial Report after aggregate data is ready
  useEffect(() => {
    if (
      download &&
      generateReportData.type_of_report === "commercial" &&
      aggregateData.tableData.length !== 0 &&
      aggregateData.tableData[0]
    ) {
      exportExcel(generateReportData);
    }
  }, [aggregateData]);

  // Stores the column descriptions of Schedule Report Table
  const scheduleReportColumns = [
    {
      title: "Report Name",
      dataIndex: "report_name",
      align: "left",
      width: 150,
      show: true,
      // sorter: (a, b) => a.supplier_id - b.supplier_id,
    },
    {
      title: "Generated On",
      dataIndex: "generated_on",
      align: "left",
      width: 150,
      show: true,
      // sorter: (a, b) => a.supplier_id - b.supplier_id,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 50,
      show:
        roleUser &&
        roleUser[0] &&
        (roleUser[0].page_access.reports.scheduled_report.view ||
          roleUser[0].page_access.reports.scheduled_report.create)
          ? true
          : false,
    },
  ];
  // State variabel to hold filter the all column datas
  const columns = scheduleReportColumns.filter((col) => col.show !== false);

  // Stores Pagination data for switching between pages of table
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });

  /* Function which gets report pagination data & processes it to display on the Build Report Table
   * @param page - Current active page number
   * @param limit - Number of records per page
   * @param allFilterVal - All applied filters of the table
   */
  const listScheduleReport = (page, limit, allFilterVal) => {
    setLoader(true);
    scheduleReportPaginationApi(page, limit, allFilterVal).then((res) => {
      setLoader(false);
      console.log("res--", res);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.totalDocs,
      });
      let reportMaster = res.docs.map((data) => {
        const tableRow = {
          key: data._id,
          report_name: data.report_name,
          generated_on: moment(data.createdAt).format("DD MMMM YYYY"),
          action: (
            <>
              <div
                style={{
                  justifyContent: "space-evenly",
                  display: "flex",
                }}
              >
                {roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.reports.scheduled_report.view && (
                    <EyeOutlined
                      title="View"
                      style={{
                        fontSize: "20px",
                        color: "var(--color-solid-darkgrey)",
                      }}
                      className="cursor-pointer"
                      onClick={() => {
                        navigate(`/reports/build-report/view/report`, {
                          state: data,
                        });
                      }}
                    />
                  )}
                {roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.reports.scheduled_report.create && (
                    <Image
                      title="Edit"
                      src={edit}
                      preview={false}
                      style={{ width: "20px" }}
                      className="cursor-pointer"
                      onClick={() => {
                        navigate(`/reports/edit/report`, { state: data });
                      }}
                    />
                  )}
                {roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.reports.scheduled_report.view && (
                    <DownloadOutlined
                      title="Download"
                      style={{
                        fontSize: "20px",
                        color: "var(--color-solid-darkgrey)",
                      }}
                      className="cursor-pointer"
                      onClick={() => {
                        setLoader(true);
                        setDownload(true);
                        SetGenerateReportData(data);
                        downloadExcel(data);
                      }}
                    />
                  )}
              </div>
            </>
          ),
        };
        return tableRow;
      });
      setScheduleTabletData(reportMaster);
    });
  };

  /* Function to handle switching between pages of the table
   * @param page - Selected page number
   * @param limit - Number of records per page
   */
  const onSchedulePageChange = (page, limit) => {
    listScheduleReport(page, limit, allFilterVal);
  };

  // Function which fetches all the reports and it's details, on first page render
  useEffect(() => {
    getAllScheduleReportFiltersApi().then((res) => {
      console.log("filterRes: ", res);
      setFilterDynamic(res);
    });
  }, []);

  // Function which fetches all the saved filters (Public & Private)
  const getAllFilters = () => {
    getFiltersApi(userData[0].id).then((res) => {
      if (res !== "") {
        console.log("filters res", res);
        let systemSetupFilterData =
          res &&
          res.length !== 0 &&
          res[0].report_filter_data &&
          // (res[0].report_filter_data.build_report_filter ||
          //   res[0].report_filter_data.schedule_report_filter) &&
          res
            .flatMap((item) => item.report_filter_data?.schedule_report_filter)
            .filter((n) => n);
        setSavedFilters(systemSetupFilterData);
      } else {
        setAllFilterVal({});
        setSavedFilters([]);
      }
    });
  };

  // Function which initiates fetching of saved filters, on first page render
  useEffect(() => {
    getAllFilters();
  }, []);

  // Function which processes the selected filter & updates the table, everytime a filter is applied
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      listScheduleReport(1, 20, allFilterVal);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        listScheduleReport(1, 20, allFilterVal);
      } else {
        listScheduleReport(1, 20, allFilterVal);
      }
    }
  }, [allFilterVal]);

  useEffect(() => {
    document.title = "Schedule Report | Judo";
  }, []);

  return (
    <Layout className="header-layout">
      <div id="sys-setup-id" className="contract-pipeline">
        {saveMessageContextHolder}
        <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
          <Col
            className="p-0 vertical-align"
            xs={24}
            sm={24}
            md={15}
            lg={15}
            xl={24}
          >
            <GlobalFilter
              selectedTab={"scheduleReport"}
              initialTableData={scheduleTabletData}
              allFilterVal={allFilterVal}
              setAllFilterVal={setAllFilterVal}
            />
          </Col>
        </Row>
        <Row align="middle" type="flex" className="mt-10">
          <Col className="p-0 vertical-align">
            <Table
              dataSource={scheduleTabletData}
              columns={columns}
              bordered
              size="large"
              loading={loader}
              scroll={{ x: "calc(300px + 50%)", y: "calc(100vh - 255px)" }}
              pagination={false}
              className="system-setup-table"
            />
            <div className="float-right mb-15 mt-15">
              <Pagination
                // pageSizeOptions={[5, 10, 20, 50, 100]}
                defaultPageSize={paginateData.limit}
                responsive
                current={paginateData.pageNo}
                total={paginateData.total}
                showSizeChanger
                onShowSizeChange={onSchedulePageChange}
                onChange={onSchedulePageChange}
              />
            </div>
          </Col>
        </Row>
        {download && commercialData && commercialData.contracts && (
          <AggregateValue
            contractData={commercialData}
            sendAggregate={setAggregateData}
          />
        )}
      </div>
    </Layout>
  );
};

export default ScheduledReport;
