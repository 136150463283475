import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Table,
  Image,
  Row,
  Col,
  Input,
  Checkbox,
  Button,
  Modal,
  Switch,
  Pagination,
} from "antd"; //antd components
import { RightOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import "../../assets/style/contractpipeline.css";
import "../../assets/style/setup.css";
import add from "../../assets/images/icons/Add_round.svg";
import edit from "../../assets/images/icons/edit_pen.svg";
import arrow_left from "../../assets/images/icons/arrow_left.svg";
import {
  contractPipelineApi,
  getAcvTcvApi,
  searchContractsApi,
} from "../../api/contractsApi";
import ContractFile from "./ContractFile";
import {
  userDataAtom,
  HeaderChangerAtom,
  RoleAccessAtom,
  SupplierTreeDataAtom,
  ContractPipelineUpdateAtom,
} from "../../store/store";
import { getLogApi } from "../../api/logApi";
import { treeDataApi } from "../../api/suppliersApi";
import GlobalFilter from "../GlobalFilter";

const ContractPipeline = ({
  changeScreenSize,
  pipelineTable,
  switchChecked,
}) => {
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const roleUser = useRecoilState(RoleAccessAtom);
  const history = useNavigate();
  let { state } = useLocation();
  // Variable to hold logged user data
  const userData = useRecoilState(userDataAtom);
  // Variable to hold supplier tree data
  const supplierTreeData = useRecoilValue(SupplierTreeDataAtom);
  //
  const [supplierPipelineData, setSupplierPipelineData] = useState(null);
  useEffect(() => {
    treeDataApi(userData[0].id, "ContractPipeline").then((res) => {
      console.log("tree res: ", res);
      setSupplierPipelineData(res);
    });
  }, []);
  // State variable to hold contract pipeline data
  const [tableData, setTableData] = useState([]);
  // State variable to hold all filter data
  const [allFilterVal, setAllFilterVal] = useState({});
  // State variable to hold search value
  const [searchVal, setSearchVal] = useState("null");
  // State variable to hold selected contract data
  const [selectedContract, setSelectedContract] = useState();
  // State variable to hold screen size
  const [screenSize, setScreenSize] = useState("min");
  // State variable to hold contract id and path
  const [finalPath, setFinalPath] = useState([]);
  // State variable to hold column editer visibility
  const [colEditor, openColEditor] = useState(false);
  // State variable to hold checked or unchecked
  const [checked, setChecked] = useState(state ? state.switchChecked : false);
  // State variable to hold contract pipeline data
  const [contractPipelineUpdate, setContractPipelineUpdate] = useRecoilState(
    ContractPipelineUpdateAtom
  );
  // State variable to hold contract pipeline data
  const [cPData, setCPData] = useState([]);
  // State variable to hold table loader visibility status
  const [tableLoader, setTableLoader] = useState(true);
  // State variable to hold sorter the data
  const [sortOrder, setSortOrder] = useState(null);
  // State variable to hold column name on which data is sorted
  const [sortedColumn, setSortedColumn] = useState(null);
  // State variable to hold listed column names
  const [listedCol, setListedCol] = useState(
    contractPipelineUpdate.selectedCol.concat(
      contractPipelineUpdate.unSelectedCol
    )
  );
  // State variable to hold selected contract pipeline columns
  const [selected, setSelected] = useState(contractPipelineUpdate.selectedCol);
  // State variable to hold available contract pipeline columns
  const [selectedCol, setSelectedCol] = useState(
    contractPipelineUpdate.selectedCol
  );
  // State variable to hold search query data
  const [searchQuery, setSearchQuery] = useState("");
  // State variable to hold final change for filtering of columns
  const [selectedColLoc, setSelectedColLoc] = useState(
    contractPipelineUpdate.selectedCol
  );
  // State variable hold a selected dynamic filter
  const [filterDynamic, setFilterDynamic] = useState([]);
  //State Variable hold the all filter column
  const [filterAllColumn, setFilterAllColumn] = useState([]);
  // State variable to hold last approver data
  const [latestApprovedData, setLatestApprovedData] = useState();
  // State variable to hold expanded row keys
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  // State variable to hold acv data
  const [acv, setAcv] = useState(null);
  // State variable to hold tcv data
  const [tcv, setTcv] = useState(null);
  const [sortColumnOptions, setSortColumnOptions] = useState({});

  // Variablr to hold filter column with sorted
  const isColumnSorted = (column) => sortedColumn === column;

  /* Function to handle add sort condition to set the state
   * @Param sorter - a hold sorting condition
   */
  const handleTableChange = (_, __, sorter) => {
    const { column, order } = sorter;
    const newOrder = order || (sortOrder === "ascend" ? "descend" : "ascend");

    const sortOptions = column
      ? {
          column_name: column.dataIndex,
          order: newOrder,
        }
      : {
          ...sortColumnOptions,
          order: newOrder,
        };

    if (column) {
      setSortedColumn(column.dataIndex);
    }

    setSortColumnOptions(sortOptions);
    setSortOrder(newOrder);

    initialRender(paginateData.pageNo, paginateData.limit, sortOptions);
  };

  useEffect(() => {
    /* Please be cautious when changing these conditions as it affects 
    showing contract from Global Search, showing contract from My Queue
    and back button functionality when clicking Contract Pipeline in the header 
    */

    if (headerChanger.headerItemName === "contractPipeline") {
      setSelectedContract();
    }

    if (headerChanger.headerItem !== "2") {
      setSelectedContract();
    } else if (
      headerChanger.headerItem === "2" &&
      state &&
      state.selectedContract
    ) {
      setSelectedContract(state.selectedContract);
    }
    // else if (headerChanger.headerItem === "2" && !state) {
    //   initialRender(paginateData.pageNo, paginateData.limit);
    // }
  }, [headerChanger, state]);

  useEffect(() => {
    console.log("selectedContract: ", selectedContract);
  }, [selectedContract]);

  // Function to handle get all pipeline data to set the use state variable
  // useEffect(() => {
  //   // getAllActionOwner().then((res) => {
  //   //   setActionOwner(res);
  //   // });

  //   // getAllPipelineContractsAPi(userData[0].id).then((res) => {
  //   //   setAllPipelineData(res);
  //   // });
  //   setTimeout(() => {
  //     getContractPipelineFiltersApi(userData[0].id).then((res) => {
  //       console.log("filterRes: ", res);
  //       setFilterAllColumn(res);
  //       setFilterDynamic(res.filter((data) => selectedCol.includes(data.name)));
  //       setFilterLoader(false);
  //     });
  //   }, 2000);
  // }, []);

  useEffect(() => {
    setFilterDynamic(
      filterAllColumn.filter((data) => selectedCol.includes(data.name))
    );
  }, [selectedCol]);

  // Fucntion call for change the pipelineTable use state, set the selected contract data
  // useEffect(() => {
  //   if (state && state.selectedContract) {
  //     setSelectedContract(state.selectedContract);
  //   } else {
  //     setSelectedContract();
  //   }
  // }, [pipelineTable]);

  /* Fucntion to handle set the expanded row keys when a row is clicked
   * @param expanded - The row that was clicked on
   * @Param record - The data for the row that was clicked on
   */
  const onTableRowExpand = (expanded, record) => {
    const keys = [];
    if (expanded) {
      keys.push(record.key); // I have set my record.id as row key. Check the documentation for more details.
    }
    setExpandedRowKeys(keys);
    setAcv("Calculating...");
    setTcv("Calculating...");
    console.log("reckkkk", record);
    console.log("reckkkk", record.key);

    getAcvTcvApi(record.key).then((res) => {
      setAcv(res.acv);
      console.log("resqqqq", res);
      setTcv(res.tcv);
    });

    getLogApi(record.key).then((res) => {
      const latestApproved = res.reduce((acc, curr) => {
        if (curr.action === "Draft") {
          // Convert the 'updated_on' string to a Date object for comparison
          const currentDate = new Date(curr.updated_on);

          // Check if the current object is the latest 'Draft' action
          if (!acc || currentDate > new Date(acc.updated_on)) {
            return curr;
          }
        }
        return acc;
      });
      setLatestApprovedData(latestApproved);
    });
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  // Variable to hold all column data
  const allColumns = [
    {
      title: renderColumnTitle("Contract Name", "contract_name"),
      dataIndex: "contract_name",
      align: "left",
      show: selectedColLoc.includes("Contract Name") ? true : false,
      sorter: true,
      sortOrder: isColumnSorted("contract_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Contract Type", "contract_type"),
      dataIndex: "contract_type",
      align: "left",
      show: selectedColLoc.includes("Contract Type") ? true : false,
      sorter: true,
      sortOrder: isColumnSorted("contract_type") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Supplier Name", "supplier_name"),
      dataIndex: "supplier_name",
      align: "left",
      show: selectedColLoc.includes("Supplier Name") ? true : false,
      sorter: true,
      sortOrder: isColumnSorted("supplier_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Contract Status", "contract_status"),
      dataIndex: "contract_status",
      show: selectedColLoc.includes("Contract Status") ? true : false,
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("contract_status") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Created On", "created_on"),
      dataIndex: "created_on",
      show: selectedColLoc.includes("Created On") ? true : false,
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("created_on") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Current Owner", "action_owner"),
      dataIndex: "action_owner",
      show: selectedColLoc.includes("Action Owner") ? true : false,
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("action_owner") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Contract Currency", "contract_currency"),
      dataIndex: "contract_currency",
      show: selectedColLoc.includes("Contract Currency") ? true : false,
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("contract_currency") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Created By", "created_by"),
      dataIndex: "created_by",
      show: selectedColLoc.includes("Created By") ? true : false,
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("created_by") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Contract Start Date", "contract_start_date"),
      dataIndex: "contract_start_date",
      show: selectedColLoc.includes("Contract Start Date") ? true : false,
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("contract_start_date") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Contract End Date", "contract_end_date"),
      dataIndex: "contract_end_date",
      show: selectedColLoc.includes("Contract End Date") ? true : false,
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("contract_end_date") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Parent CID", "parent_cid"),
      dataIndex: "parent_cid",
      show: selectedColLoc.includes("Parent CID") ? true : false,
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("parent_cid") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Parent Type", "parent_type"),
      dataIndex: "parent_type",
      show: selectedColLoc.includes("Parent Type") ? true : false,
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("parent_type") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Signed On", "signed_on"),
      dataIndex: "signed_on",
      show: selectedColLoc.includes("Signed On") ? true : false,
      align: arrow_left,
      sorter: true,
      sortOrder: isColumnSorted("signed_on") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Workflow Status", "workflow_status"),
      dataIndex: "workflow_status",
      show: selectedColLoc.includes("Workflow Status") ? true : false,
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("workflow_status") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: (
        <Image
          src={add}
          className="cursor-pointer"
          preview={false}
          onClick={() => openColEditor(true)}
        />
      ),
      dataIndex: "add",
      align: "center",
      width: 100,
    },
  ];

  /* Function to handle search the filter data
   *  @param Value - Search value from user input
   */
  const filterSearch = (value) => {
    const searched = listedCol.filter((col) =>
      col.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const unselected = searched.filter((element) => !value.includes(element));
    const newSelected = [...new Set([...selectedCol, ...value])];
    const updatedSelectedCol = newSelected.filter(
      (element) => !unselected.includes(element)
    );
    setSelectedCol(updatedSelectedCol);
  };

  // Variable to hold filter the column
  const columnToShow = allColumns.filter((col) => col.show !== false);

  /* Function to handle set the table data
   * @param contractPipelineData - Table data recieved from API
   */
  const contractPipeline = (contractPipelineData) => {
    console.log("contractPipelineData: ", contractPipelineData);
    const pipelineData = contractPipelineData.map((i, index) => {
      let tableRow = {
        key: i._id,
        contract_name: (
          <span
            onClick={() => {
              if (
                roleUser &&
                roleUser[0] &&
                roleUser[0].page_access.contracts.contract_pipeline.view
              ) {
                getTopPath(i);
              }
            }}
            style={{
              color:
                roleUser &&
                roleUser[0] &&
                roleUser[0].page_access.contracts.contract_pipeline.view
                  ? "var(--color-solid-darkblue)"
                  : "var(--color-solid-black)",
              fontWeight: "100",
              cursor:
                roleUser &&
                roleUser[0] &&
                roleUser[0].page_access.contracts.contract_pipeline.view
                  ? "pointer"
                  : "default",
            }}
          >
            {i.contract_name}
          </span>
        ),
        contract_type: i.contract_type,
        supplier_name: i.supplier_name,
        contract_status: i.contract_status,
        created_on: i.created_on
          ? moment(i.created_on).format("DD MMMM YYYY")
          : "",
        action_owner:
          i.owner_name.length !== 0
            ? i.owner_name.join(", ")
            : i.created_user_name,
        contract_value_acv: i.contract_value_acv,
        contract_value_tcv: i.contract_value_tcv,
        billing_profile: i.billing_profile,
        budget_approver: i.budget_approver,
        contract_currency: i.contract_currency,
        created_by: i.created_user_name,
        demand_details: i.demand_details,
        contract_start_date: i.start_date
          ? moment(i.start_date).format("DD MMMM YYYY")
          : "",
        contract_end_date: i.end_date
          ? moment(i.end_date).format("DD MMMM YYYY")
          : "",
        for_legal_entity: i.for_legal_entity,
        parent_cid: i.parent_cid,
        parent_type: i.parent_type,
        service_manager: i.service_manager,
        signed_on: i.signed_on
          ? moment(i.signed_on).format("DD MMMM YYYY")
          : "",
        system_currency: i.system_currency,
        workflow_status: i.workflow_status,
        add: (
          <>
            {(i.contract_status === "Contract-in-Draft" ||
              i.contract_status === "Contract-Returned") &&
              userData[0].id === i.created_by && (
                <Image
                  title="Edit"
                  src={edit}
                  preview={false}
                  style={{ width: "20px" }}
                  className="cursor-pointer"
                  onClick={() => {
                    // if (i.supplier_name !== "Wayne Internationals") {
                    setHeaderChanger({
                      header: "contract",
                      headerItem: "2",
                      headerItemName: headerChanger.headerItemName,
                      stateSetter: i,
                    });
                    history(`/contract/edit/general`, { state: i });
                    // } else {
                    //   setHeaderChanger({
                    //     header: "contract",
                    //     headerItem: "2",
                    //     headerItemName: headerChanger.headerItemName,
                    //     stateSetter: i,
                    //   });
                    //   history(`/contract/edit/wayne`, { state: i });
                    // }
                  }}
                />
              )}
          </>
        ),
      };
      return tableRow;
      // }
    });
    setTableData(pipelineData);
  };

  // Fucntion call when change the screen size to set the use state
  useEffect(() => {
    changeScreenSize(screenSize);
  }, [screenSize]);

  const getPath = (model, id) => {
    let path,
      item = {
        id: model.id,
        name: model.contract_name
          ? model.contract_name
          : model[`${model.contract_type.toLowerCase()}_name`],
      };
    if (!model || typeof model !== "object") return;
    if (model.id === id) return [item];
    (model.children || []).some((child) => (path = getPath(child, id)));
    return path && [item, ...path];
  };

  const getTopPath = (contract) => {
    supplierTreeData.some((res) => {
      const pathVal = getPath(res, contract._id);
      if (pathVal) {
        setFinalPath([...pathVal]);
        return pathVal;
      }
      return false;
    });
    setSelectedContract(contract);
  };

  // State variable to hold pagination data
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });

  /* Function to handle the get contract pipeline data with pagination
   * @param pageNo - current active page number
   * @param limit - number of records per page
   */
  const initialRender = (page, limit, sortColumn) => {
    setTableLoader(true);
    if (searchVal === "null") {
      contractPipelineApi(
        page,
        limit,
        checked ? userData[0].id : "all",
        searchVal,
        allFilterVal,
        userData[0].id,
        sortColumn
      ).then((res) => {
        setTableLoader(false);
        contractPipeline(res.docs);
        setCPData(res.docs);
        setPaginateData({
          ...paginateData,
          pageNo: page,
          total: res.totalDocs,
        });
      });
    } else {
      searchContractsApi(
        searchVal,
        page,
        limit,
        checked ? userData[0].id : "all",
        "pipeline",
        userData[0].id
      ).then((res) => {
        setTableLoader(false);
        searchResData(res);
        setPaginateData({
          ...paginateData,
          pageNo: page,
          total: res.total,
        });
      });
    }
  };

  /* Fucntion call when get the data from search contract api
   * @Param data - a data from search contract api
   */
  const searchResData = (data) => {
    const pipelineData = data.data.map((i, index) => {
      let tableRow = {
        key: i.key,
        contract_name: (
          <span
            onClick={() => getTopPath(i)}
            style={{
              cursor: "pointer",
              color: "var(--color-solid-darkblue)",
              fontWeight: "100",
            }}
          >
            {i.contract_name}
          </span>
        ),
        contract_type: i.contract_type,
        supplier_name: i.supplier_name,
        contract_status: i.contract_status,
        created_on: moment(i.created_on ? i.created_on : i.createdAt).format(
          "DD MMMM YYYY"
        ),
        action_owner: i.action_owner,
        contract_value_acv: i.contract_value_acv,
        contract_value_tcv: i.contract_value_tcv,
        billing_profile: i.billing_profile,
        budget_approver: i.budget_approver,
        contract_currency: i.contract_currency,
        created_by: i.created_user_name,
        demand_details: i.demand_details,
        contract_start_date: i.contract_start_date
          ? moment(i.contract_start_date).format("DD MMMM YYYY")
          : "",
        contract_end_date: i.contract_end_date
          ? moment(i.contract_end_date).format("DD MMMM YYYY")
          : "",
        for_legal_entity: i.for_legal_entity,
        parent_cid: i.parent_cid,
        parent_type: i.parent_type,
        service_manager: i.service_manager,
        signed_on: i.signed_on
          ? moment(i.signed_on).format("DD MMMM YYYY")
          : "",
        system_currency: i.system_currency,
        workflow_status: i.workflow_status,
        add: (
          <>
            {i.contract_status === "Contract-in-Draft" && (
              <Image
                title="Edit"
                src={edit}
                preview={false}
                style={{ width: "20px" }}
                className="cursor-pointer"
                onClick={() =>
                  history(`/contract/edit/general`, {
                    state: i,
                  })
                }
              />
            )}
          </>
        ),
      };
      return tableRow;
    });
    setTableData(pipelineData);
  };

  // Function to call the initialRender method to sent page number and limit
  // useEffect(() => {
  //   initialRender(paginateData.pageNo, paginateData.limit);
  //   console.log("check 1");
  // }, []);

  // Fucntion call when change the searchVal, checked, allFilterval
  useEffect(() => {
    setPaginateData({
      ...paginateData,
      pageNo: 1,
      limit: 20,
      total: 0,
    });
    handleTableChange("", "", {
      column: { dataIndex: "created_on" },
      order: "descend",
    });
  }, [checked, allFilterVal]);

  /* Function to handle after select the filter data to filter the table data, after the change the
     allFilterVal state
   */
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      initialRender(1, 20, sortColumnOptions);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        initialRender(1, 20, sortColumnOptions);
      } else {
        initialRender(1, 20, sortColumnOptions);
      }
    }
  }, [checked, allFilterVal]);

  /* Function to handle switch the checked value
   * @Param value - checked the new value
   */
  const switchOnChange = (value) => {
    setChecked(value);
  };

  // Function call when click the back button
  const backButton = () => {
    setSelectedContract();
    initialRender(paginateData.pageNo, paginateData.limit, sortColumnOptions);
  };

  const changeHomeTab = () => {
    backButton();
  };

  useEffect(() => {
    document.title = "Contract Pipeline | Judo";
  }, []);

  return (
    <div className={selectedContract ? "pipeline-click" : "contract-pipeline"}>
      {!selectedContract && (
        <>
          <Row align="middle" type="flex" gutter={[0, 0]}>
            <Col className="p-0 vertical-align" span={1}>
              <div className="switch-main">
                <div className="switch-text">
                  <p>{checked ? "With Me" : "All"}</p>
                </div>
                <div className="switch-handler">
                  <Switch
                    defaultChecked={checked}
                    onChange={switchOnChange}
                    className="fx-setup-switch"
                  />
                </div>
              </div>
            </Col>
            <Col
              className="p-0 vertical-align"
              xs={24}
              sm={24}
              md={15}
              lg={15}
              xl={23}
            >
              <GlobalFilter
                selectedTab={"contractPipeline"}
                initialTableData={tableData}
                allFilterVal={allFilterVal}
                setAllFilterVal={setAllFilterVal}
              />
            </Col>
          </Row>

          <Table
            loading={tableLoader}
            dataSource={tableData}
            columns={columnToShow}
            onChange={handleTableChange}
            bordered
            size="large "
            scroll={{ x: "calc(300px + 50%)", y: "calc(100vh - 230px)" }}
            pagination={false}
            id="supplier-setup-table"
            className="system-setup-table mt-10"
            showSorterTooltip={false}
            onExpand={onTableRowExpand}
            expandable={{
              expandedRowKeys: expandedRowKeys,
              expandedRowRender: (record) => {
                let pipelineData =
                  cPData &&
                  cPData.length !== 0 &&
                  cPData.filter((data) => data._id === record.key)[0];
                console.log("pipelineData***", pipelineData);
                let spenddataIndex =
                  pipelineData.contract_metadata.length !== 0 &&
                  pipelineData.contract_metadata[0].metadata_details.financial.indexOf(
                    "Type Of Spend"
                  );
                let businessOwnerIndex =
                  pipelineData.contract_metadata.length !== 0 &&
                  pipelineData.contract_metadata[0].metadata_details.contract.indexOf(
                    "Business Owner Name"
                  );

                return (
                  <div className="float-left text-left collapse-details">
                    <h4>Details About the Contract</h4>
                    <Row
                      align="middle"
                      type="flex"
                      gutter={[24, 0]}
                      className="m-0"
                    >
                      <Col className="p-0" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <p>
                          Contract Start Date :{" "}
                          {record.contract_start_date
                            ? record.contract_start_date
                            : "<<Data Not Available>>"}
                        </p>
                      </Col>
                      <Col className="p-0" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <p>
                          Contract End Date :{" "}
                          {record.contract_end_date
                            ? record.contract_end_date
                            : "<<Data Not Available>>"}
                        </p>
                      </Col>
                      <Col className="p-0" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <p>ACV : {acv && acv}</p>
                      </Col>
                      <Col className="p-0" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <p>TCV : {tcv && tcv}</p>
                      </Col>
                      <Col className="p-0" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <p>
                          Spend Type :{" "}
                          {pipelineData.contract_metadata.length !== 0
                            ? spenddataIndex !== -1
                              ? pipelineData.contract_metadata[0]
                                  .metadata_description.financial[
                                  spenddataIndex
                                ]
                              : "<<Data Not Available>>"
                            : "<<Data Not Available>>"}
                        </p>
                      </Col>
                      <Col className="p-0" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <p>
                          Business Owner :{" "}
                          {pipelineData.contract_metadata.length !== 0
                            ? businessOwnerIndex !== -1
                              ? pipelineData.contract_metadata[0]
                                  .metadata_description.basic[
                                  businessOwnerIndex
                                ]
                              : "<<Data Not Available>>"
                            : "<<Data Not Available>>"}
                        </p>
                      </Col>
                      <Col className="p-0" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <p>
                          Contract Owner :{" "}
                          {pipelineData.owner_name
                            ? pipelineData.owner_name
                            : "<<Data Not Available>>"}
                        </p>
                      </Col>
                      <Col className="p-0" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <p>
                          Last Approved By :
                          {latestApprovedData
                            ? latestApprovedData.authorDetails.first_name
                            : "<<Data Not Available>>"}
                        </p>
                      </Col>
                      <Col className="p-0" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <p>
                          Standard Template :
                          {pipelineData.contract_metadata.length !== 0
                            ? pipelineData.contract_metadata[0]
                                .contract_template_name
                            : "<<Data Not Available>>"}
                        </p>
                      </Col>
                    </Row>
                  </div>
                );
              },
            }}
          />
          <div className="float-right mb-10 mt-15">
            <Pagination
              defaultPageSize={paginateData.limit}
              responsive
              current={paginateData.pageNo}
              total={paginateData.total}
              showSizeChanger
              onShowSizeChange={initialRender}
              onChange={(page, limit) =>
                initialRender(page, limit, sortColumnOptions)
              }
            />
          </div>
        </>
      )}
      {selectedContract && (
        <>
          <Row
            align="top"
            type="flex"
            gutter={[8, 0]}
            className="m-10"
            style={{ display: "none" }}
          >
            <Col className="" xs={24} sm={24} md={23} lg={23} xl={24}>
              <div className="pipeline-breadcrumb mt-10">
                <span>
                  <Image
                    src={arrow_left}
                    style={{ width: 25 }}
                    preview={false}
                    onClick={() => {
                      setSelectedContract();
                      initialRender(
                        paginateData.pageNo,
                        paginateData.limit,
                        {}
                      );
                    }}
                    className="cursor-pointer"
                  />
                  <span className="breadcrumb-path">
                    {finalPath &&
                      finalPath.map((item, i) => {
                        return (
                          <>
                            <span key={item.id} className="path-text-pipeline">
                              {item.name === "home" ? "Suppliers" : item.name}
                            </span>
                            <span
                              style={{
                                display:
                                  i === finalPath.length - 1
                                    ? "none"
                                    : "inline-block",
                              }}
                              className="path-icon"
                            >
                              <RightOutlined />
                            </span>
                          </>
                        );
                      })}
                  </span>
                </span>
              </div>
            </Col>
          </Row>
          <Row type="flex" gutter={[8, 0]}>
            <Col span={24}>
              <ContractFile
                pipelinePath={finalPath}
                backButton={backButton}
                selected={selectedContract}
                screenSize={screenSize}
                changeHomeTab={changeHomeTab}
                supplierPipeline={supplierPipelineData}
              />
            </Col>
          </Row>
        </>
      )}
      {colEditor && (
        <Modal
          className="col-edit-modal"
          centered
          open={colEditor}
          onOk={() => {
            openColEditor(false);
          }}
          onCancel={() => {
            setSearchQuery("");
            openColEditor(false);
            setSelectedCol(selected);
            setSelectedColLoc(selected);
          }}
          closable={true}
          footer={null}
        >
          <div>
            <h3>Add Column</h3>
            <span className="col-editor-head">
              Select or Deselect the tag to show in Contract Pipeline
            </span>
          </div>
          <Row type="flex">
            <Col span={24} className="mt-20">
              <Input
                placeholder="Search column tags"
                allowClear
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Col>
          </Row>
          <div className="mt-20">
            <span className="col-editor-head">
              Different options in choosing the tag for Contract Pipeline
            </span>
          </div>
          <div className="col-list p-10">
            <Row gutter={[8, 16]} type="flex">
              <Col span={24}>
                {listedCol &&
                listedCol.length !== 0 &&
                listedCol.filter((col) =>
                  col.toLowerCase().includes(searchQuery.toLowerCase())
                ).length !== 0 ? (
                  <Checkbox.Group
                    style={{
                      display: "block",
                      overflowY: "scroll",
                      height: 250,
                    }}
                    options={listedCol
                      .filter((col) =>
                        col.toLowerCase().includes(searchQuery.toLowerCase())
                      )
                      .map((col) => ({
                        label: col,
                        value: col,
                        disabled:
                          selectedCol.length === 1 && selectedCol.includes(col),
                      }))}
                    defaultValue={selectedCol}
                    onChange={(value) => {
                      if (searchQuery != "") {
                        filterSearch(value);
                        return;
                      }
                      setSelectedCol(value);
                    }}
                  />
                ) : (
                  <p>No column name matches search query</p>
                )}
              </Col>
            </Row>
          </div>
          <Row type="flex">
            <Col span={24} align="middle" className="mt-20">
              <Button
                className="save-btn"
                onClick={() => {
                  setSearchQuery("");
                  setSelected(selectedCol);
                  setSelectedColLoc(selectedCol);
                  setContractPipelineUpdate({
                    selectedCol: selectedCol,
                    unSelectedCol: listedCol.filter(
                      (element) => !selectedCol.includes(element)
                    ),
                  });
                  openColEditor(false);
                }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
    </div>
  );
};

export default ContractPipeline;
