import React from "react";
import { Card, Row, Col, Image } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import arrow_left from "../assets/images/icons/arrow_left.svg";
import arrow_back from "../assets/images/icons/arrow_back_ios.svg";

const HeaderCard = ({
  mainMenu,
  subMenu,
  itemName,
  itemStatus,
  goBackLink,
  state,
  showDetails,
}) => {
  const navigate = useNavigate();
  console.log("state-hc", state);

  return (
    <Card className="setup-header-card">
      <Row align="middle" type="flex" gutter={[8, 16]}>
        <Col className="vertical-align" xs={1} sm={1} md={1} lg={1} xl={1}>
          <Row align="middle" type="flex" gutter={[8, 16]}>
            <Col span={24}>
              <Image
                src={arrow_left}
                style={{ width: 25 }}
                preview={false}
                className="cursor-pointer"
                onClick={() => {
                  if (typeof goBackLink === "object") {
                    let backFunction = goBackLink.func;
                    backFunction(goBackLink.params);
                  } else {
                    navigate(goBackLink);
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col className="vertical-align" xs={23} sm={23} md={23} lg={11} xl={11}>
          <span>{mainMenu}</span> &nbsp; &nbsp;
          <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
          <span
            className="cursor-pointer"
            onClick={() => {
              if (typeof goBackLink === "object") {
                let backFunction = goBackLink.func;
                backFunction(goBackLink.params);
              } else {
                navigate(goBackLink);
              }
            }}
          >
            {subMenu}
          </span>
          &nbsp; &nbsp;
          <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
          <span>{itemName}</span>
        </Col>
        {showDetails && (
          <Col
            className="vertical-align"
            xs={23}
            sm={23}
            md={23}
            lg={11}
            xl={11}
          >
            <Row align="middle" type="flex" className="m-0">
              <Col span={8}>
                <span className="text-overflow">
                  <span className="last-update-text">Last Update on: </span>
                  <span
                    title={moment(
                      state.updatedAt ? state.updatedAt : ""
                    ).format("DD/MM/YY")}
                  >
                    {moment(state.updatedAt ? state.updatedAt : "").format(
                      "DD/MM/YY"
                    )}
                  </span>
                </span>
              </Col>
              <Col span={8} align="right">
                <span className="text-overflow">
                  <span className="last-update-text">Last Update by: </span>
                  <span
                    title={
                      state && state.updated_user_name
                        ? state.updated_user_name
                        : state.updatedBy[0].first_name +
                          " " +
                          state.updatedBy[0].last_name
                    }
                  >
                    {state && state.updated_user_name
                      ? state.updated_user_name
                      : state.updatedBy[0].first_name +
                        " " +
                        state.updatedBy[0].last_name}
                  </span>
                </span>
              </Col>
              <Col span={8} align="right">
                <span className="text-overflow">
                  <span className="last-update-text">Status: </span>
                  <span
                    className="status-update-text"
                    style={{ color: "#fab911" }}
                    title={itemStatus}
                  >
                    {itemStatus}
                  </span>
                </span>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default HeaderCard;
