const key = {
  TABCHANGER_UPDATE: "TABCHANGER_UPDATE",
  HEADERCHANGER_UPDATE: "HEADERCHANGER_UPDATE",
  NOTIFICATIONLIST_UPDATE: "NOTIFICATIONLIST_UPDATE",
  NOTIFICATIONUNREAD_UPDATE: "NOTIFICATIONUNREAD_UPDATE",
  SPENDOVERVIEW_UPDATE: "SPENDOVERVIEW_UPDATE",
  CONTRACTSETUP_UPDATE: "CONTRACTSETUP_UPDATE",
  INVOICINGVIEW_UPDATE: "INVOICINGVIEW_UPDATE",
  SUPPLIERSETUP_UPDATE: "SUPPLIERSETUP_UPDATE",
  LEGALENTITY_UPDATE: "LEGALENTITY_UPDATE",
  USER_TOKENS: "USER_TOKENS",
  SUPPLIER_TREE_DATA: "SUPPLIER_TREE_DATA",
  CONTRACT_TREE_UPDATE: "CONTRACT_TREE_UPDATE",
  CONTRACT_PIPELINE_UPDATE: "CONTRACT_PIPELINE_UPDATE",
  ROLE_ACCESS_DATA: "ROLE_ACCESS_DATA",
  DASHBOARD_DATA: "DASHBOARD_DATA",
  ROLE_USER: "ROLE_USER",
  USER_DATA: "USER_DATA",
  CONTRACTADDTAB_UPDATE: "CONTRACTADDTAB_UPDATE",
  SUPPLIER_NAME: "SUPPLIER_NAME",
  LEGAL_ENTITY_NAME: "LEGAL_ENTITY_NAME",
  COST_CENTER_NAME: "COST_CENTER_NAME",
  RATECARD_NAME: "RATECARD_NAME",
  FX_NAME: "FX_NAME",
  PRICING_PROFILE_NAME: "PRICING_PROFILE_NAME",
  INVOICING_PROFILE_NAME: "INVOICING_PROFILE_NAME",
  WORKFLOW_NAME: "WORKFLOW_NAME",
  GROUP_NAME: "GROUP_NAME",
};
export default key;
