// Api URL Common path
export const basePath =
  process.env.NODE_ENV === "production"
    ? "http://13.235.24.144:3003/api"
    : "http://13.235.24.144:3003/api";
// Api URL for Airflow
export const airflowUrl =
  process.env.NODE_ENV === "production"
    ? "http://65.1.191.157:8080/api/v1"
    : "http://65.1.191.157:8080/api/v1";
