import React from "react";
import TreeComponent from "./TreeComponent";

const ContractTab = ({ breadcrumbs }) => {
  // console.log("breadcrumbs: ", breadcrumbs);

  return (
    <div>
      {breadcrumbs ? (
        <TreeComponent initialBreadcrumbs={breadcrumbs} />
      ) : (
        <>
          <h2>Contract Details for {breadcrumbs.title}</h2>
          <p>Here goes the contract content...</p>
        </>
      )}
    </div>
  );
};

export default ContractTab;
