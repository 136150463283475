import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Image, Row, Col, Modal, Table, Button, Pagination } from "antd"; //antd components
import { EyeOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import {
  RoleAccessAtom,
  HeaderChangerAtom,
  WorkflowNameAtom,
  userDataAtom,
} from "../../../store/store";
import "../../../assets/style/setup.css";
import "../../../assets/style/contractpipeline.css";
import flowsheet from "../../../assets/images/icons/flowsheet.svg";
import {
  workflowPaginationApi,
  getAllWorkflowsOnlyApi,
} from "../../../api/workflowApi";
import { createActivityLogApi } from "../../../api/logApi";
import moment from "moment";
import { CreateButton } from "../../GlobalButton";
import delete_disable from "../../../assets/images/icons/delete_disable.png";
import deleteIcon from "../../../assets/images/icons/delete.svg";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import { deleteWFApi } from "../../../api/workflowApi";
import GlobalFilter from "../../GlobalFilter";

function WorkFlowTemplateSetup() {
  const popupModalInstance = PopupModal();
  //global state varaiable hold the dashboard user data to display
  const userData = useRecoilState(userDataAtom);
  //global state variable hold the navigating different routes
  const history = useNavigate();
  // state variable hold the save work flow name from input field
  const [workflowName, setWorkflowName] = useRecoilState(WorkflowNameAtom);
  // state variable hold the table data
  const [tableData, setTableData] = useState([]);
  // state variable hold the loader on api integration
  const [loader, setLoader] = useState(false);
  // state variable  hold the search value make its has null
  const [searchVal, setSearchVal] = useState("null");
  // state varaiable hold the role user value
  const [systemSetupAccess, setSystemSetupAccess] = useState();
  // global state hold the role user  access in userecoil
  const roleUser = useRecoilState(RoleAccessAtom);
  //state variable hold the custom work flow show
  const [showCustomWorkflow, setShowCustomWorkflow] = useState();
  //stae variable hold the crate mode in true or false
  const [createModel, setCreateModel] = useState(
    localStorage.getItem("wf-create") ? true : false
  );
  // state variable hold the header change value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // state variable hold the sort order in table
  const [sortOrder, setSortOrder] = useState(null);
  // state variable hold the sort column in table
  const [sortedColumn, setSortedColumn] = useState(null);
  // state varaib;e hold the filter all in table
  const [allFilterVal, setAllFilterVal] = useState({});
  const [sortColumnOptions, setSortColumnOptions] = useState({});

  const isColumnSorted = (column) => sortedColumn === column;

  /* function handle the table change in sorter
   *  @param sorter - sorting the table value
   */
  const handleTableChange = (_, __, sorter) => {
    const { column, order } = sorter;
    const newOrder = order || (sortOrder === "ascend" ? "descend" : "ascend");

    const sortOptions = column
      ? {
          column_name: column.dataIndex,
          order: newOrder,
        }
      : {
          ...sortColumnOptions,
          order: newOrder,
        };

    if (column) {
      setSortedColumn(column.dataIndex);
    }

    setSortColumnOptions(sortOptions);
    setSortOrder(newOrder);

    WorkFlowTemplateSetuplist(
      paginateData.pageNo,
      paginateData.limit,
      searchVal,
      allFilterVal,
      sortOptions
    );
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const allColumns = [
    {
      title: renderColumnTitle("Workflow Name", "workflow_name"),
      dataIndex: "workflow_name",
      align: "left",
      width: 400,
      show: true,
      sorter: true,
      sortOrder: isColumnSorted("workflow_name") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Status", "status"),
      dataIndex: "status",
      align: "left",
      show: true,
      sorter: true,
      sortOrder: isColumnSorted("status") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Created By", "created_by"),
      dataIndex: "created_by",
      align: "left",
      show: true,
      sorter: true,
      sortOrder: isColumnSorted("created_by") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Created On", "created_on"),
      dataIndex: "created_on",
      align: "left",
      show: true,
      sorter: true,
      sortOrder: isColumnSorted("created_on") ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 250,
      show:
        roleUser &&
        roleUser[0] &&
        (roleUser[0].page_access.system_setup.workflow.view ||
          roleUser[0].page_access.system_setup.workflow.create)
          ? true
          : false,
    },
  ];
  // state varaiable hold the pagination data
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });

  /* function handle the table pagination
   * @param page - number of page in table
   * @param limit- number of row to show in single page
   * @param allfilterval - no of filter row
   */
  const WorkFlowTemplateSetuplist = (
    page,
    limit,
    searchVal,
    allFilterVal,
    sortColumn
  ) => {
    setLoader(true);
    workflowPaginationApi(
      page,
      limit,
      searchVal,
      allFilterVal,
      sortColumn
    ).then((res) => {
      console.log("res wf", res);
      setLoader(false);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.totalDocs,
      });
      const WorkFlowTemplateSetupData = res.docs.map((item) => {
        let tableRow = {
          key: item._id ? item._id : item.id,
          workflow_name: (
            <span
              onClick={() => {
                if (
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.workflow.view
                ) {
                  setHeaderChanger({
                    header: "systemSetup",
                    headerItem: "2",
                    headerItemName: "systemSetup",
                    stateSetter: item,
                  });
                  console.log("Data: ", item);
                  let ssLog = {
                    ss_id: item._id ? item._id : item.id,
                    ss_name: item.workflow_name,
                    code: "workflow",
                    author: userData[0].id,
                    action: "view",
                    notes: "Viewed Workflow",
                    updated_on: new Date(),
                  };
                  createActivityLogApi(ssLog).then((res) => {
                    console.log("LogRes: ", res);
                  });
                  history(`/contract-setup/view/workflow`, {
                    state: item,
                  });
                }
              }}
              style={{
                color:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.workflow.view
                    ? "var(--color-solid-darkblue)"
                    : "var(--color-solid-black)",
                fontWeight: "100",
                cursor:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.workflow.view
                    ? "pointer"
                    : "default",
              }}
            >
              {item.workflow_name}
            </span>
          ),
          status: item.status,
          created_by: item.created_user_name,
          created_on: item.createdAt
            ? moment(item.createdAt).format("DD-MMM-YY")
            : "",
          action: (
            <>
              <div
                style={{
                  justifyContent: "space-evenly",
                  display: "flex",
                }}
              >
                {roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.workflow.view && (
                    <EyeOutlined
                      title="View"
                      style={{
                        fontSize: "20px",
                        color: "var(--color-solid-darkgrey)",
                      }}
                      className="cursor-pointer"
                      onClick={() => {
                        setHeaderChanger({
                          header: "systemSetup",
                          headerItem: "2",
                          headerItemName: "systemSetup",
                          stateSetter: item,
                        });
                        console.log("Data: ", item);
                        let ssLog = {
                          ss_id: item._id ? item._id : item.id,
                          ss_name: item.workflow_name,
                          code: "workflow",
                          author: userData[0].id,
                          action: "view",
                          notes: "Viewed Workflow",
                          updated_on: new Date(),
                        };
                        createActivityLogApi(ssLog).then((res) => {
                          console.log("LogRes: ", res);
                        });
                        history(`/contract-setup/view/workflow`, {
                          state: item,
                        });
                      }}
                    />
                  )}
                {roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.workflow.create && (
                    <>
                      {item.status === "In Draft" ? (
                        <>
                          <Image
                            title="Delete"
                            src={deleteIcon}
                            preview={false}
                            style={{ width: "20px" }}
                            className="cursor-pointer"
                            onClick={() => {
                              popupModalInstance
                                .confirModal(ModalMessage.WF_Delete_Info)
                                .then(async (userchoice) => {
                                  if (userchoice.action === "delete") {
                                    handleDeleteWF(
                                      item.id ? item.id : item._id,
                                      item.workflow_name,
                                      userchoice.inputValue
                                    );
                                  }
                                });
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Image
                            title="Delete"
                            src={delete_disable}
                            style={{ width: "20px" }}
                            preview={false}
                            className="cursor-not-allowed"
                          />
                        </>
                      )}
                    </>
                  )}
              </div>
            </>
          ),
        };
        return tableRow;
      });
      setTableData(WorkFlowTemplateSetupData);
    });
  };

  // store pricingprofile name in global state
  const getAllWF = async () => {
    try {
      const res = await getAllWorkflowsOnlyApi();
      setWorkflowName(res.map((i) => i.workflow_name));
    } catch (err) {
      console.log(err);
    } finally {
      console.log("Finished fetching Workflow");
    }
  };

  useEffect(() => {
    getAllWF();
  }, []);

  const handleDeleteWF = (wfId, wf_name, inputWFName) => {
    if (inputWFName === wf_name) {
      deleteWFApi(wfId).then((res) => {
        let umLog = {
          ss_id: wfId,
          ss_name: wf_name,
          code: "delete",
          author: userData[0].id,
          action: "delete-wf",
          notes: "Deleted Workflow",
          updated_on: new Date(),
        };
        createActivityLogApi(umLog);
        popupModalInstance
          .successModal(wf_name, ModalMessage.WF_Delete_Success, "")
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              WorkFlowTemplateSetuplist(
                1,
                20,
                searchVal,
                {},
                sortColumnOptions
              );
            }
          });
      });
    } else {
      popupModalInstance.errorModal(ModalMessage.WF_Delete_Error);
    }
  };

  // use effect handle work flow template
  useEffect(() => {
    if (roleUser) {
      setSystemSetupAccess(roleUser[0].entity_rights.create);
      setShowCustomWorkflow(
        roleUser &&
          roleUser[0] &&
          roleUser[0].role_name &&
          roleUser[0].role_name === "Admin"
          ? true
          : false
      );
    }
  }, []);

  // use effect handle call pagination method and send data
  useEffect(() => {
    //To set default sorting on load, for Updated On column of the table
    handleTableChange("", "", {
      column: { dataIndex: "created_on" },
      order: "descend",
    });
  }, [searchVal]);

  /* Function to handle pade data in table
   * @paran page - number of pages to show
   * @param limit- no of  role need to diaplay
   */
  const onPageChange = (page, limit) => {
    WorkFlowTemplateSetuplist(
      page,
      limit,
      searchVal,
      allFilterVal,
      sortColumnOptions
    );
  };

  // Function which processes the selected filter & updates the table, everytime a filter is applied
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      WorkFlowTemplateSetuplist(
        1,
        20,
        searchVal,
        allFilterVal,
        sortColumnOptions
      );
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        WorkFlowTemplateSetuplist(
          1,
          20,
          searchVal,
          allFilterVal,
          sortColumnOptions
        );
      } else {
        WorkFlowTemplateSetuplist(
          1,
          20,
          searchVal,
          allFilterVal,
          sortColumnOptions
        );
      }
    }
  }, [allFilterVal]);

  useEffect(() => {
    document.title = "Workflow | Judo";
  }, []);

  return (
    <>
      <div id="sys-setup-id" className="contract-pipeline">
        <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
          <Col
            className="p-0 vertical-align"
            xs={24}
            sm={24}
            md={
              roleUser &&
              roleUser[0] &&
              roleUser[0].page_access.system_setup.workflow.create
                ? 15
                : 24
            }
            lg={
              roleUser &&
              roleUser[0] &&
              roleUser[0].page_access.system_setup.workflow.create
                ? 15
                : 24
            }
            xl={
              roleUser &&
              roleUser[0] &&
              roleUser[0].page_access.system_setup.workflow.create
                ? 22
                : 24
            }
          >
            <GlobalFilter
              selectedTab={"workflow"}
              initialTableData={tableData}
              allFilterVal={allFilterVal}
              setAllFilterVal={setAllFilterVal}
            />
          </Col>
          {roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.workflow.create && (
              <Col xl={2} md={4} xs={24} className="vertical-align">
                <CreateButton onClick={() => setCreateModel(true)} />
              </Col>
            )}
        </Row>

        <Row className="mt-10">
          <Table
            dataSource={tableData}
            columns={allColumns}
            onChange={handleTableChange}
            showSorterTooltip={false}
            bordered
            size="large"
            scroll={{ x: "calc(300px + 50%)", y: "calc(100vh - 255px)" }}
            loading={loader}
            pagination={false}
            className="system-setup-table"
            onRow={(record, rowIndex) => {
              return {};
            }}
          />
        </Row>
        <div className="float-right mb-15 mt-15">
          <Pagination
            defaultPageSize={paginateData.limit}
            responsive
            current={paginateData.pageNo}
            total={paginateData.total}
            showSizeChanger
            onShowSizeChange={onPageChange}
            onChange={onPageChange}
          />
        </div>
      </div>
      {createModel && (
        <Modal
          className="create-wf-modal"
          footer={null}
          open={createModel}
          onCancel={() => {
            setCreateModel(false);
            localStorage.removeItem("wf-create");
          }}
        >
          <Row className="modal-image mb-10">
            <Col span={24} align="middle">
              <Image src={flowsheet} alt="Flowsheet" preview={false} />
            </Col>
          </Row>
          <h2 className="modal-title">Select Workflow</h2>
          <div className="modal-content">
            <p>Capture A Way For Your Workflow process</p>
            <div className="modal-popup-footer-wf mt-25">
              <Row type="flex">
                {showCustomWorkflow ? (
                  <>
                    <Col span={12} align="middle">
                      <Button
                        className="wf-type-btn"
                        onClick={() =>
                          history("/contract-setup/Excel/excel-Work-Flow")
                        }
                      >
                        Excel Workflow
                      </Button>
                    </Col>
                    <Col span={12} align="middle">
                      <Button
                        disabled
                        className="wf-type-btn"
                        onClick={() =>
                          history("/contract-setup/Online/online-Work-Flow")
                        }
                      >
                        Online Workflow
                      </Button>
                    </Col>
                    {/* <Col span={8} align="middle">
                      <Button
                        className="wf-type-btn"
                        onClick={() =>
                          history("/contract-setup/Excel/custom-Work-Flow")
                        }
                      >
                        Custom Workflow
                      </Button>
                    </Col> */}
                  </>
                ) : (
                  <>
                    <Col span={12} align="middle">
                      <Button
                        className="wf-type-btn"
                        onClick={() =>
                          history("/contract-setup/Excel/excel-Work-Flow")
                        }
                      >
                        Excel WorkFlow
                      </Button>
                    </Col>
                    <Col span={12} align="middle">
                      <Button
                        disabled
                        className="wf-type-btn"
                        onClick={() =>
                          history("/contract-setup/Online/online-Work-Flow")
                        }
                      >
                        Online WorkFlow
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default WorkFlowTemplateSetup;
