import React, { useEffect, useState } from "react";
import TopHeader from "../../Header";
import { HeaderChangerAtom } from "../../../store/store";
import {
  Layout,
  Row,
  Col,
  Card,
  Image,
  Form,
  Input,
  Button,
  Table,
  Space,
  Popconfirm,
} from "antd"; //antd components
import { FullscreenOutlined, LoadingOutlined } from "@ant-design/icons"; //antd icons
import {
  CancelButton,
  SaveButton,
  SubmitButton,
  BatchButton,
} from "../../GlobalButton";
import { Checkbox } from "antd";
import { useRecoilState } from "recoil";
import arrow_right_disable from "../../../assets/images/icons/arrow_right_disable.svg";
import workspace_premium from "../../../assets/images/icons/workspace_premium.svg";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_ios.svg";
import { createClauseLibrary } from "../../../api/contractAuthorityApi";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import { userDataAtom, RoleAccessAtom } from "../../../store/store";
import edit from "../../../assets/images/icons/edit_pen.svg";
import deleteIcon from "../../../assets/images/icons/delete.svg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../../assets/style/clauselibrary.css";
import NotAuthorized from "../../NotAuthorized";
import { dataTable } from "../../../utils/common";
import DataTable from "../../Tables/DataTable";
import { createActivityLogApi } from "../../../api/logApi";

function CreateClauseLibrary() {
  const userData = useRecoilState(userDataAtom); //global state
  const roleUser = useRecoilState(RoleAccessAtom);
  const { TextArea } = Input;
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };
  const [confirmationModel, setConfirmationModel] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const popupModalInstance = PopupModal();
  const [recordKey, setRecordKey] = useState("");
  const history = useNavigate();

  const navigate = useNavigate();
  const state = {
    button: 1,
  };
  const [clauseLibrary, setClauseLibrary] = useState({
    header_name: null,
    header_desc: null,
  });

  const [dataSource, setDataSource] = useState([]);
  const [counter, setCounter] = useState(0);
  const [batchActionMode, setBatchAction] = useState("");
  const [initialFormValues, setInitialFormValues] = useState({
    // header_name: state.header_name || "", // Set default value to empty string if state.header_name is undefined
    // header_desc: state.header_desc || "", // Set default value to empty string if state.header_desc is undefined
    batch_name: "",
    batch_desc: "",
    batch_content: "",
  });
  const [editorDelta, setEditorDelta] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);

  const handleEditorChange = (content, delta, source, editor) => {
    console.log("editor", editor);
    setEditorDelta(editor.getContents());
  };
  console.log("editorDelta", editorDelta);

  useEffect(() => {
    // Update editorDelta when initialFormValues change
    setEditorDelta(initialFormValues.batch_content);
  }, [initialFormValues]);

  const openModal = () => {
    setModalVisible(true);
    setBatchAction("create");
  };

  const closeModal = () => {
    setModalVisible(false);
    // Reset initial form values and editor content
    setInitialFormValues({
      batch_name: "",
      batch_desc: "",
      batch_content: "",
    });
    setEditorDelta("");
  };

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    ["link", "image"],
    [{ list: "ordered" }, { list: "bullet" }],
    // [{ script: "sub" }, { script: "super" }],
    // [{ indent: "-1" }, { indent: "+1" }],
    // [{ size: ["small", false, "large", "huge"] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ];

  const onCreateclauseLibrary = (label, e) => {
    console.log("header_name..", e.target.value);
    if (label === "header_name") {
      setClauseLibrary({
        ...clauseLibrary,
        header_name: e.target.value,
      });
    } else if (label === "header_description") {
      console.log("header_description..", e.target.value);
      setClauseLibrary({
        ...clauseLibrary,
        header_desc: e.target.value,
      });
    }
  };

  //on submit form
  const onFinish = async (values) => {
    console.log("values....", values);
    values = {
      ...values,
      batch_details: dataSource.map(({ key, ...rest }) => ({
        ...rest,
      })),
      created_on: new Date(),
      updated_on: new Date(),
      created_by: userData[0].id,
      updated_by: userData[0].id,
    };
    if (state.button === 1) {
      setSaveTableLoader(true);
      values["status"] = "Draft";
      await createClauseLibrary(values).then((res) => {
        console.log("res-draft", res);
        let ssLog = {
          ss_id: res.id ? res.id : res._id,
          ss_name: values["header_name"],
          code: "clause_library",
          author: userData[0].id,
          action: "view",
          notes: "created Clause Library",
          updated_on: new Date(),
        };
        console.log("activity log", ssLog);

        createActivityLogApi(ssLog);
        console.log("res-draft", res);
        setTableLoader(false);
        popupModalInstance.successModal(
          values.header_name,
          ModalMessage.CL_Create_Save
          // `/contract_authority/clause-library`
        );
      });
    }
    // submit button handler
    if (state.button === 2) {
      setTableLoader(true);
      values["status"] = "Active";
      popupModalInstance
        .infoModal(ModalMessage.CL_Create_Submit)
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            await createClauseLibrary(values).then((res) => {
              console.log("res-submit", res);
              let ssLog = {
                ss_id: res.id ? res.id : res._id,
                ss_name: values["header_name"],
                code: "clause_library",
                author: userData[0].id,
                action: "view",
                notes: "created Clause Library",
                updated_on: new Date(),
              };
              createActivityLogApi(ssLog);
              console.log("res-submit", res);
              setTableLoader(false);
              navigate(`/contract_authority/clause-library`);
            });
          }
          if (userChoice === "cancel") {
            setTableLoader(false);
          }
        });
    }
  };
  //error on form submit
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };

  let header = headerChanger.header;
  const menuKey = "5";

  const handleDeleteBatch = (record) => {
    console.log("delete batch data:", record);
    let filtererdRecord = dataSource.filter((item) => item.key !== record.key);
    let aligned =
      filtererdRecord &&
      filtererdRecord.length !== 0 &&
      filtererdRecord.map((item, index) => {
        return {
          ...item,
          ["key"]: index,
        };
      });
    console.log("aligned", aligned);
    setDataSource(aligned ? aligned : []);
  };

  const handleViewBatch = (batchData) => {
    console.log("View batch data:", batchData);
    setRecordKey(batchData.key);
    setInitialFormValues({
      batch_name: batchData.batch_name,
      batch_desc: batchData.batch_desc,
      batch_content: batchData.batch_content,
    });
    setEditorDelta(batchData.batch_content); // Ensure editor content is updated
    setConfirmationModel(true);
  };

  const col = [
    {
      title: "Batch Name",
      dataIndex: "batch_name",
      key: "batch_name",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (text, record) => (
        <span>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <Image
              title="Edit"
              src={edit}
              preview={false}
              style={{ width: "20px", marginRight: "30px" }}
              className="cursor-pointer"
              onClick={() => {
                handleViewBatch(record);
                setBatchAction("edit");
              }}
            />

            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDeleteBatch(record)}
            >
              <Image
                title="Delete"
                src={deleteIcon}
                style={{ width: "20px" }}
                preview={false}
                className="cursor-pointer"
              />
            </Popconfirm>
          </div>
        </span>
      ),
    },
  ];

  // const onCreateBatch = (values) => {
  //   console.log("values batch: ", values);
  //   if (batchActionMode === "create") {
  //     values = {
  //       ...values,
  //       batch_content: editorDelta,
  //       key: counter,
  //     };

  //     setDataSource([...dataSource, values]);
  //     setCounter(counter + 1);
  //     setConfirmationModel(false);
  //     setBatchAction("");
  //   } else {
  //     // Map over the dataSource array
  //     const updatedDataSource = dataSource.map((item) => {
  //       console.log("itemm", item);
  //       // Check if the current item's row_key matches the rowKeyToUpdate
  //       if (item.key === recordKey) {
  //         // If it matches, update the properties with new values
  //         return {
  //           ...item,
  //           batch_name: values.batch_name,
  //           batch_desc: values.batch_desc,
  //           // batch_content: values.batch_content,
  //           batch_content: editorDelta,
  //         };
  //       }
  //       // If the row_key doesn't match, return the item unchanged
  //       return item;
  //     });

  //     console.log("batchContent: ", updatedDataSource);

  //     // Set the updated dataSource
  //     setDataSource(updatedDataSource);
  //     setConfirmationModel(false);
  //     setBatchAction("");
  //   }
  // };

  const onCreateBatch = (values) => {
    const exitsBatchName = dataSource.find(
      (data) => data.batch_name === values.batch_name
    );
    if (exitsBatchName) {
      popupModalInstance.warningModal(ModalMessage.Cl_Name_Warning);
    } else {
      if (batchActionMode === "create") {
        values = {
          ...values,
          batch_content: editorDelta || "",
          key: counter,
        };
        setDataSource([...dataSource, values]);
        setCounter(counter + 1);
      } else if (batchActionMode === "edit") {
        const updatedDataSource = dataSource.map((item) => {
          if (item.key === recordKey) {
            return {
              ...item,
              batch_name: values.batch_name,
              batch_desc: values.batch_desc,
              batch_content: editorDelta,
            };
          }
          return item;
        });
        setDataSource(updatedDataSource);
      }
      // Reset form and close modal
      setConfirmationModel(false);
      setBatchAction("");
      setInitialFormValues({
        batch_name: "",
        batch_desc: "",
        batch_content: "",
      });
      setEditorDelta("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <div className="system-setup1">
          <div className="create-clause-library">
            <Form
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onKeyDown={handleKeyDown}
            >
              <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
                <Col
                  className="vertical-align"
                  xs={24}
                  sm={16}
                  md={18}
                  lg={18}
                  xl={18}
                >
                  <Card className="setup-header-card">
                    <Row type="flex" gutter={[8, 16]}>
                      <Col
                        className="vertical-align"
                        xs={6}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={2}
                      >
                        <Row type="flex" gutter={[8, 16]}>
                          <Col span={12}>
                            <Image
                              src={arrow_left}
                              style={{ width: 25 }}
                              preview={false}
                              className="cursor-pointer"
                              onClick={() =>
                                navigate(`/contract_authority/clause-library`)
                              }
                            />
                          </Col>
                          <Col span={12}>
                            <Image
                              src={arrow_right_disable}
                              style={{ width: 20 }}
                              preview={false}
                              className="cursor-not-allowed"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        className="vertical-align"
                        xs={17}
                        sm={17}
                        md={19}
                        lg={19}
                        xl={22}
                      >
                        <span>Contract Authority</span> &nbsp; &nbsp;
                        <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                        <span
                          className="cursor-pointer"
                          onClick={() =>
                            navigate(`/contract_authority/clause-library`)
                          }
                        >
                          Clause Library
                        </span>
                        &nbsp; &nbsp;
                        <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                        <span>Create Clause Header</span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col
                  className="vertical-align"
                  xs={8}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                >
                  <CancelButton
                    onClick={() =>
                      history(`/contract_authority/clause-library`)
                    }
                  />
                </Col>
                <Col
                  className="vertical-align"
                  xs={8}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                >
                  <SaveButton
                    type="primary"
                    htmlType="submit"
                    onClick={() => (state.button = 1)}
                    //  loading={saveTableLoader}
                    // disabled={rateCardField.rateCardName ? false : true}
                  />
                </Col>
                <Col
                  className="vertical-align"
                  xs={8}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                >
                  <SubmitButton
                    onClick={() => (state.button = 2)}
                    htmlType="submit"
                    name="submit-btn"
                    // loading={loader}
                  />
                </Col>
              </Row>
              <div className="price-profile-content m-10">
                <Row
                  align="middle"
                  type="flex"
                  gutter={[8, 16]}
                  className="m-0"
                >
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row>
                      <Col span={24}>
                        <div className="create-supplier-div">
                          <div className="m-20">
                            <Row align="middle" type="flex">
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="header_name"
                                  label="ENTER HEADER NAME"
                                  colon={false}
                                  rules={[
                                    {
                                      message: "Please enter header name",
                                      pattern: /^(?!\s*$).+/,
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="AXY Company"
                                    onChange={(e) =>
                                      onCreateclauseLibrary("header_name", e)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="header_desc"
                                  label="ENTER HEADER DESCRIPTION"
                                  colon={false}
                                  rules={[
                                    {
                                      message:
                                        "Please enter header description",
                                      pattern: /^(?!\s*$).+/,
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="XYZ"
                                    onInput={(e) =>
                                      (e.target.value = e.target.value)
                                    }
                                    onChange={(e) =>
                                      onCreateclauseLibrary(
                                        "header_description",
                                        e
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <div className="create-entity-div mt-15">
                              <Row
                                align="middle"
                                type="flex"
                                style={{ padding: "10px 30px 0px" }}
                              >
                                <Col span={18} className="vertical-align">
                                  <h4 className="entity-text">
                                    Content Batches{" "}
                                  </h4>
                                </Col>
                                <Col span={6} className="vertical-align"></Col>
                              </Row>
                              <hr className="mt-10 view-supplier-hr" />
                              <Row
                                align="middle"
                                type="flex"
                                style={{ padding: "10px 0px 30px 15px" }}
                                gutter={[8, 16]}
                              >
                                <Col span={24} align="center">
                                  <Col span={24} className="vertical-align">
                                    {dataSource.length === 0 && (
                                      <span className="upload-note">
                                        No Batches To View
                                      </span>
                                    )}
                                    {dataSource.length > 0 && (
                                      <Row>
                                        <DataTable
                                          type="clause-library"
                                          dataTableData={dataSource}
                                          colData={col}
                                          showSorterTooltip={false}
                                        />
                                      </Row>
                                    )}
                                    <Col span={24} align="middle">
                                      <Col
                                        span={3}
                                        align="middle"
                                        className="vertical-align"
                                        style={{
                                          marginTop: "10px",
                                        }}
                                      >
                                        <BatchButton
                                          onClick={() => {
                                            setConfirmationModel(true);
                                            setBatchAction("create");
                                            setInitialFormValues({
                                              batch_name: "",
                                              batch_desc: "",
                                              batch_content: "",
                                            });
                                            setEditorDelta("");
                                          }}
                                        />
                                      </Col>
                                    </Col>
                                  </Col>
                                </Col>
                              </Row>
                              {confirmationModel && (
                                <Modal
                                  centered
                                  open={confirmationModel}
                                  onOk={() => setConfirmationModel(false)}
                                  onCancel={() => {
                                    setConfirmationModel(false);
                                  }}
                                  width={690}
                                  footer={false}
                                >
                                  <Form
                                    onFinish={onCreateBatch}
                                    onKeyDown={handleKeyDown}
                                    initialValues={initialFormValues}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <img
                                        src={workspace_premium}
                                        style={{ width: 25, height: 25 }}
                                      />
                                      <p
                                        style={{
                                          paddingTop: 1,
                                          paddingLeft: 6,
                                          fontWeight: 600,
                                        }}
                                      >
                                        Create Batch
                                      </p>
                                    </div>

                                    <Row
                                      align="middle"
                                      type="flex"
                                      gutter={[40, 4]}
                                      style={{ marginTop: "10px" }}
                                    >
                                      <Col span={24}>
                                        <Row gutter={[16, 8]}>
                                          <Col span={24}>
                                            <Form.Item
                                              name="batch_name"
                                              label="Batch Name"
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  message:
                                                    "Please enter batch name",
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder=""
                                                className=""
                                                style={{
                                                  borderColor: "#0092ff",
                                                }}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>

                                    {/* </Col> */}
                                    <Row
                                      align="middle"
                                      type="flex"
                                      gutter={[40, 4]}
                                    >
                                      <Col span={24}>
                                        <Row gutter={[16, 8]}>
                                          <Col span={24}>
                                            <Form.Item
                                              name="batch_desc"
                                              label="Batch Description"
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  message:
                                                    "Please enter batch description",
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder=""
                                                className=""
                                                style={{
                                                  borderColor: "#0092ff",
                                                }}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                    <Row
                                      align="middle"
                                      type="flex"
                                      gutter={[40, 4]}
                                    >
                                      <Col span={24}>
                                        <Form.Item
                                          name="batch_content"
                                          labelCol={{ span: 24 }}
                                        >
                                          <Row
                                            justify="space-between"
                                            align="middle"
                                            className="mb-10"
                                          >
                                            <Col span={12}>
                                              <span>Batch Content</span>
                                            </Col>
                                            <Col span={12} align="right">
                                              <Button
                                                type="primary"
                                                icon={<FullscreenOutlined />}
                                                onClick={openModal}
                                              />
                                            </Col>
                                          </Row>
                                          <ReactQuill
                                            id="min-quill"
                                            theme="snow"
                                            value={editorDelta}
                                            onChange={handleEditorChange}
                                            modules={{
                                              toolbar: toolbarOptions,
                                            }}
                                            placeholder="Batch Content..."
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                    <Modal
                                      className="maximize-batch-content"
                                      title="Batch Content"
                                      open={modalVisible}
                                      onCancel={closeModal}
                                      footer={null}
                                      width="90%"
                                      styles={{
                                        body: {
                                          height: "80vh",
                                          overflow: "auto",
                                        },
                                      }}
                                      // closeIcon={
                                      //   <FullscreenExitOutlined className="exit-fullscreen-icon" />
                                      // }
                                      centered
                                    >
                                      <ReactQuill
                                        id="max-quill"
                                        theme="snow"
                                        value={editorDelta}
                                        onChange={handleEditorChange}
                                        modules={{
                                          toolbar: toolbarOptions,
                                        }}
                                        style={{ height: "100%" }}
                                        placeholder="Batch Content..."
                                      />
                                    </Modal>
                                    {/* <Checkbox onChange={onChange}>
                                      Make as default Batch
                                    </Checkbox> */}
                                    <Row gutter={[16, 8]} className="mt-15">
                                      <Col span={16} offset={4}>
                                        <Row type="flex" align={"middle"}>
                                          <Col span={10} offset={2}>
                                            <CancelButton
                                              onClick={() =>
                                                setConfirmationModel(false)
                                              }
                                            />
                                          </Col>
                                          <Col span={10} offset={1}>
                                            <SaveButton htmlType="submit" />
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Form>
                                </Modal>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      ) : (
        <NotAuthorized redirect={`/contract_authority/clause-library`} />
      )}
    </Layout>
  );
}

export default CreateClauseLibrary;
