// PopupModal.jsx
import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Image, Row, Col, Button, Input } from "antd";
import "./assets/style/modal.css";
import successIcon from "./assets/images/icons/Success_model_icon.svg";
import warningIcon from "./assets/images/icons/Warning_model_icon.svg";
import errorIcon from "./assets/images/icons/Error_model_icon.svg";
import informationIcon from "./assets/images/icons/Information_model_icon.svg";
import { CheckOutlined } from "@ant-design/icons";
import {
  CancelButton,
  DeleteButton,
  OkButton,
} from "./components/GlobalButton";
import { HeaderChangerAtom } from "./store/store";
import { useRecoilState } from "recoil";

const PopupModal = () => {
  const navigate = useNavigate();
  // state variable hold the header change value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);

  const successModal = (name, content_data, okRedirect) => {
    console.log("okRedirect 1", okRedirect);
    return new Promise((resolve) => {
      Modal.success({
        // title: "Success",
        className: "common_succeess_model",
        centered: true,
        icon: null,
        footer: null,
        content: (
          <div>
            <div>
              <Row align="middle" type="flex">
                <Col span={2} className="vertical-align">
                  <Image src={successIcon} preview={false} />
                </Col>
                <Col span={22} className="vertical-align res-modal-title">
                  Success
                </Col>
              </Row>
            </div>
            <div className="mt-10">{`${name} ${content_data}`}</div>
            {/* <div className="text-center mt-10">
              <Button
                className="modal-info-btn"
                type="primary"
                icon={<CheckOutlined />}
                onClick={() => {
                  Modal.destroyAll();
                  resolve("ok");
                  if (okRedirect !== "") {
                    navigate(okRedirect);
                  }
                }}
              >
                Ok
              </Button>
            </div> */}
            <Row type="flex" align="middle" className="mt-10">
              <Col
                span={24}
                align="middle"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <Col span={8} style={{ marginRight: 5 }}>
                  <OkButton
                    type="primary"
                    onClick={() => {
                      Modal.destroyAll();
                      resolve("ok");
                      if (okRedirect !== "") {
                        navigate(okRedirect);
                      }
                    }}
                  />
                </Col>
              </Col>
            </Row>
          </div>
        ),
      });
    });
  };

  const warningModal = (content_data) => {
    return new Promise((resolve) => {
      Modal.success({
        // title: "Success",
        className: "common_succeess_model",
        centered: true,
        icon: null,
        footer: null,
        content: (
          <div>
            <div>
              <Row align="middle" type="flex">
                <Col span={2} className="vertical-align">
                  <Image src={warningIcon} preview={false} />
                </Col>
                <Col span={22} className="vertical-align res-modal-title">
                  Warning
                </Col>
              </Row>
            </div>
            <div className="mt-10">{content_data}</div>
            {/* <div className="text-center mt-10">
            <Button
              className="modal-info-btn"
              type="primary"
              icon={<CheckOutlined />}
              onClick={() => {
                Modal.destroyAll();
              }}
            >
              Ok
            </Button>
          </div> */}
            <Row type="flex" align="middle" className="mt-10">
              <Col
                span={24}
                align="middle"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <Col span={8}>
                  <OkButton
                    type="primary"
                    onClick={() => {
                      Modal.destroyAll();
                      resolve("ok");
                    }}
                  />
                </Col>
              </Col>
            </Row>
          </div>
        ),
      });
    });
  };

  const errorModal = (content_data, okRedirect) => {
    return new Promise((resolve) => {
      Modal.success({
        // title: "Success",
        className: "common_succeess_model",
        centered: true,
        icon: null,
        footer: null,
        content: (
          <div>
            <div>
              <Row align="middle" type="flex">
                <Col span={2} className="vertical-align">
                  <Image src={errorIcon} preview={false} />
                </Col>
                <Col span={22} className="vertical-align res-modal-title">
                  Error
                </Col>
              </Row>
            </div>
            <div className="mt-10">{content_data}</div>
            {/* <div className="text-center mt-10">
            <Button
              className="modal-info-btn"
              type="primary"
              icon={<CheckOutlined />}
              onClick={() => {
                Modal.destroyAll();
              }}
            >
              Ok
            </Button>
          </div> */}
            <Row type="flex" align="middle" className="mt-10">
              <Col
                span={24}
                align="middle"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <Col span={8}>
                  <OkButton
                    type="primary"
                    onClick={() => {
                      Modal.destroyAll();
                      resolve("ok");
                    }}
                  />
                </Col>
              </Col>
            </Row>
          </div>
        ),
      });
    });
  };

  const errorModalOfSupplier = (content_data, okRedirect) => {
    Modal.success({
      // title: "Success",
      className: "common_succeess_model",
      centered: true,
      icon: null,
      footer: null,
      content: (
        <div>
          <div>
            <Row align="middle" type="flex">
              <Col span={2} className="vertical-align">
                <Image src={errorIcon} preview={false} />
              </Col>
              <Col span={22} className="vertical-align res-modal-title">
                Error
              </Col>
            </Row>
          </div>
          <div className="mt-10">
            {content_data.map((err, index) => {
              return (
                <div>
                  {index + 1}.&nbsp; {err}
                </div>
              );
            })}
          </div>
          {/* <div className="text-center mt-10">
            <Button
              className="modal-info-btn"
              type="primary"
              icon={<CheckOutlined />}
              onClick={() => {
                Modal.destroyAll();
              }}
            >
              Ok
            </Button>
          </div> */}
          <Row type="flex" align="middle" className="mt-10">
            <Col
              span={24}
              align="middle"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Col span={8}>
                <OkButton
                  type="primary"
                  onClick={() => {
                    Modal.destroyAll();
                  }}
                />
              </Col>
            </Col>
          </Row>
        </div>
      ),
    });
  };

  const infoModal = (content_data) => {
    return new Promise((resolve) => {
      Modal.success({
        // title: "Success",
        className: "common_succeess_model",
        centered: true,
        icon: null,
        footer: null,
        content: (
          <div>
            <div>
              <Row align="middle" type="flex">
                <Col span={2} className="vertical-align">
                  <Image src={informationIcon} preview={false} />
                </Col>
                <Col span={22} className="vertical-align res-modal-title">
                  Information
                </Col>
              </Row>
            </div>
            <div className="mt-10">{content_data}</div>
            {/* <div className="text-center mt-10">
              <Button
                className="modal-info-btn"
                type="primary"
                icon={<CheckOutlined />}
                onClick={() => {
                  Modal.destroyAll();
                  resolve("ok");
                }}
              >
                Ok
              </Button>
              <Button
                className="modal-info-btn ml-10"
                type="primary"
                icon={<CheckOutlined />}
                onClick={() => {
                  Modal.destroyAll();
                  resolve("cancel");
                }}
              >
                Cancel
              </Button>
            </div> */}
            <Row type="flex" align="middle" className="mt-10">
              <Col
                span={24}
                align="middle"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <Col span={8} style={{ marginRight: 5 }}>
                  <OkButton
                    type="primary"
                    onClick={() => {
                      Modal.destroyAll();
                      resolve("ok");
                    }}
                  />
                </Col>
                <Col span={8} style={{ marginLeft: 5 }}>
                  <CancelButton
                    type="primary"
                    onClick={() => {
                      Modal.destroyAll();
                      resolve("cancel");
                    }}
                  />
                </Col>
              </Col>
            </Row>
          </div>
        ),
      });
    });
  };

  const confirModal = (content_data) => {
    return new Promise((resolve) => {
      let inputVal = "";
      Modal.success({
        // title: "Success",
        className: "common_succeess_model",
        centered: true,
        icon: null,
        footer: null,
        content: (
          <div>
            <div>
              <Row align="middle" type="flex">
                <Col span={2} className="vertical-align">
                  <Image src={informationIcon} preview={false} />
                </Col>
                <Col span={22} className="vertical-align res-modal-title">
                  Information
                </Col>
              </Row>
            </div>
            <div className="mt-10">{content_data}</div>
            <div className="text-left mt-10">Name</div>
            <div className="text-center ">
              <Input
                placeholder="Enter Name..."
                onChange={(e) => {
                  inputVal = e.target.value;
                }}
              />
            </div>
            {/* <div className="text-center mt-10">
              <Button
                className="modal-info-btn"
                type="primary"
                icon={<CheckOutlined />}
                onClick={() => {
                  Modal.destroyAll();
                  resolve({ action: "delete", inputValue: inputVal });
                }}
              >
                Delete
              </Button>
              <Button
                className="modal-info-btn ml-10"
                type="primary"
                icon={<CheckOutlined />}
                onClick={() => {
                  Modal.destroyAll();
                  resolve("cancel");
                }}
              >
                Cancel
              </Button>
            </div> */}
            <Row type="flex" align="middle" className="mt-10">
              <Col
                span={24}
                align="middle"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <Col span={8} style={{ marginRight: 5 }}>
                  <CancelButton
                    type="primary"
                    onClick={() => {
                      Modal.destroyAll();
                      resolve("cancel");
                    }}
                  />
                </Col>
                <Col span={8} style={{ marginLeft: 5 }}>
                  <DeleteButton
                    type="primary"
                    onClick={() => {
                      Modal.destroyAll();
                      resolve({ action: "delete", inputValue: inputVal });
                    }}
                  />
                </Col>
              </Col>
            </Row>
          </div>
        ),
      });
    });
  };

  const accessDeniedModal = (content_data, okRedirect) => {
    console.log("okRedirect 1", okRedirect);
    return new Promise((resolve) => {
      Modal.success({
        // title: "Success",
        className: "common_succeess_model",
        centered: true,
        icon: null,
        footer: null,
        content: (
          <div>
            <div>
              <Row align="middle" type="flex">
                <Col span={2} className="vertical-align">
                  <Image src={successIcon} preview={false} />
                </Col>
                <Col span={22} className="vertical-align res-modal-title">
                  Warning
                </Col>
              </Row>
            </div>
            <div className="mt-10">{`${content_data}`}</div>
            <Row type="flex" align="middle" className="mt-10">
              <Col
                span={24}
                align="middle"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <Col span={8} style={{ marginRight: 5 }}>
                  <OkButton
                    type="primary"
                    onClick={() => {
                      Modal.destroyAll();
                      resolve("ok");
                      if (okRedirect !== "") {
                        navigate(okRedirect);
                      }
                    }}
                  />
                </Col>
              </Col>
            </Row>
          </div>
        ),
      });
    });
  };

  const authorChangeModal = (name, content_data, okRedirect) => {
    return new Promise((resolve) => {
      Modal.success({
        className: "common_succeess_model",
        centered: true,
        icon: null,
        footer: null,
        content: (
          <div>
            <div>
              <Row align="middle" type="flex">
                <Col span={2} className="vertical-align">
                  <Image src={successIcon} preview={false} />
                </Col>
                <Col span={22} className="vertical-align res-modal-title">
                  Success
                </Col>
              </Row>
            </div>
            <div className="mt-10">{`${name} ${content_data}`}</div>
            <Row type="flex" align="middle" className="mt-10">
              <Col
                span={24}
                align="middle"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <Col span={8} style={{ marginRight: 5 }}>
                  <OkButton
                    type="primary"
                    onClick={() => {
                      Modal.destroyAll();
                      resolve("ok");
                      if (okRedirect !== "") {
                        navigate(
                          okRedirect,
                          setHeaderChanger({
                            header: "contract",
                            headerItem: "2",
                            headerItemName: "contractPipeline",
                          })
                        );
                      }
                    }}
                  />
                </Col>
              </Col>
            </Row>
          </div>
        ),
      });
    });
  };

  return {
    successModal,
    errorModal,
    infoModal,
    warningModal,
    confirModal,
    accessDeniedModal,
    authorChangeModal,
    errorModalOfSupplier,
  };
};

export default PopupModal;
