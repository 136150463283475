import React, { useContext, useEffect, useState } from "react";
import "./tabmanager.css";
import { Tabs, Button, Divider, Layout, Image } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TabContext } from "./TabContext";
import ContractTab from "./ContractTab";
import TopHeader from "../Header";
import { HeaderChangerAtom } from "../../store/store";
import { useRecoilState } from "recoil";
import FolderActive from "../../assets/images/icons/folder.svg";
import FolderInactive from "../../assets/images/icons/folder_inactive.svg";

const TabManager = () => {
  const {
    tabs,
    setTabs,
    activeKey,
    breadcrumbs,
    setBreadcrumbs,
    setActiveKey,
    handleAddTab,
    handleRemoveTab,
  } = useContext(TabContext);

  const [headerChanger, _] = useRecoilState(HeaderChangerAtom);
  let header = headerChanger.header;

  const tabItems = [
    ...tabs.reduce((acc, tab) => {
      acc.push({
        key: tab.id,
        label: (
          <span style={{ display: "flex", alignItems: "center" }}>
            <img
              src={activeKey === tab.id ? FolderActive : FolderInactive}
              style={{ width: 15, height: 15, marginRight: 10 }}
            />
            {tab.title}
          </span>
        ),
        children: <ContractTab breadcrumbs={tab.breadcrumbs} />,
        closable: tab.id !== "contract-tree",
      });
      // Add a divider after each tab
      acc.push({
        key: "divider" + tab.id, // Ensure unique key
        label: <Divider className="contract-tab-divider" type="vertical" />,
        disabled: true,
      });
      return acc;
    }, []),
  ];

  const onChangeTab = (clickedTab) => {
    setActiveKey(clickedTab);
  };

  return (
    <Layout className="contract-tab-layout">
      <TopHeader
        headerChange={header}
        menuKey={"5"}
        logo={true}
        feedRow={false}
        searchRow={false}
      />
      <Tabs
        hideAdd
        id="contract-refactor-tab"
        className={"fade-in-left"}
        activeKey={activeKey}
        onChange={(clickedTab) => onChangeTab(clickedTab)}
        type="editable-card"
        onEdit={(targetKey, action) => {
          if (action === "remove") {
            handleRemoveTab(targetKey);
          }
        }}
        items={tabItems}
        tabBarExtraContent={
          <PlusOutlined
            className="contract-tab-add-btn"
            onClick={() =>
              handleAddTab({
                id: `newTab_${Date.now()}`,
                title: "Contracts",
                breadcrumbs: [
                  {
                    title: "Suppliers",
                    apiArgs: {
                      supplier_id: "undefined",
                      contract_id: "undefined",
                      type: "Supplier",
                    },
                  },
                ],
                children: (
                  <ContractTab
                    breadcrumbs={[
                      {
                        title: "Suppliers",
                        apiArgs: {
                          supplier_id: "undefined",
                          contract_id: "undefined",
                          type: "Supplier",
                        },
                      },
                    ]}
                  />
                ),
              })
            }
          />
        }
      />
    </Layout>
  );
};

export default TabManager;
