import { axiosInstance } from "../utils/common";

export const countSeededContracts = () => {
  return axiosInstance
    .get(`v1/test/count/seed`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const seedDatabaseApi = (
  supplierCount,
  childCount,
  levels,
  userId,
  userEmail,
  operation
) => {
  return axiosInstance
    .get(
      `v1/test/database?supplierCount=${supplierCount}&childCount=${childCount}&levels=${levels}&userId=${userId}&userEmail=${userEmail}&flag=${operation}`
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const fetchContractTree = (
  page,
  limit,
  supplierId,
  contractId,
  parentType
) => {
  return axiosInstance
    .get(
      `v1/test/database/fetch/tree?page=${page}&limit=${limit}&supplier_id=${supplierId}&contract_id=${contractId}&parent_type=${parentType}`
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const oldFetchAcvTcv = (contractId) => {
  return axiosInstance
    .get(`v1/test/database/fetch/old/contract/value?contract_id=${contractId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const newFetchAcvTcv = (contractId) => {
  return axiosInstance
    .get(`v1/test/database/fetch/new/contract/value?contract_id=${contractId}`)
    .then((res) => res.data)
    .catch((err) => console.log(err));
};

export const oldPipelineApi = (page, limit, switchFilter, loggedInUser) => {
  return axiosInstance
    .get(
      `v1/test/database/fetch/old/pipeline?limit=${limit}&page=${page}&switchFilter=${switchFilter}&loggedInUser=${loggedInUser}`
    )
    .then((res) => res.data)
    .catch((err) => console.log(err));
};
