import React, { useEffect, useState } from "react";
import {
  Image,
  Row,
  Col,
  Button,
  Form,
  Select,
  Input,
  Layout,
  Table,
  Modal,
  Popconfirm,
  Checkbox,
  Upload,
  message,
  Radio,
  Divider,
  Tabs,
} from "antd"; //antd components
import {
  DeleteOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useNavigate, Link, useLocation } from "react-router-dom";
import moment from "moment";
import TopHeader from "../../Header";
import add from "../../../assets/images/icons/Add_round.svg";
import "../../../assets/style/setup.css";
import { ContractCurrency } from "../../../utils/data";
import { suppliersCreateApi, editSupplierApi } from "../../../api/suppliersApi";
import { getRatecardBySupplier } from "../../../api/ratecardApi";
import { getFxBySupplier } from "../../../api/fxTableApi";
import { getPricingProfileBySupplier } from "../../../api/pricingProfileApi";
import { getInvoicingProfileBySupplier } from "../../../api/invoicingProfileApi";
import { getContractBySupplier } from "../../../api/contractsApi";
import { createActivityLogApi } from "../../../api/logApi";
import {
  userDataAtom,
  SupplierNameAtom,
  HeaderChangerAtom,
  RoleAccessAtom,
  SupplierTreeDataAtom,
} from "../../../store/store";
import supplierEntityTemplate from "../../../template/Supplier_Entity_Template.xlsx";
import duplicate from "../../../assets/images/icons/duplicate.svg";
import { useRecoilState, useRecoilValue } from "recoil";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import {
  CancelButton,
  SaveButton,
  SubmitButton,
  ExcelButton,
  TableButton,
} from "../../GlobalButton";
import NotAuthorized from "../../NotAuthorized";
import HeaderCard from "../../HeaderCard";
import { dataTable, setupDataTableForm } from "../../../utils/common";
import DataTable from "../../Tables/DataTable";
import NotesAndComments from "../../NotesAndComments";
import {
  deleteS3FileApi,
  updateNotesCommentsApi,
} from "../../../api/commonApi";

function SupplierSetupController() {
  //global state varaiable hold the dashboard user data to display
  const userData = useRecoilState(userDataAtom);
  // state variable hold the header change value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  //global state variable hold the header
  let header = headerChanger.header;
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom); //global state
  // global state variable hold the popup modal
  const popupModalInstance = PopupModal();
  // global state variable hold the key while switch header name
  const menuKey = "5";
  //global state variable hold the navigating different routes
  const navigate = useNavigate();
  let { state } = useLocation();
  console.log("state", state);
  if (state.data === null || state.data === undefined) {
    state.data = headerChanger.stateSetter;
  }
  // state variable hold the supplier name given in  input field
  const [supplierName, setSupplierName] = useState(
    state.data && state.data.supplier_name
  );
  // state variable hold the supplier entity given in  input field
  const [supplierEntity, setSupplierEntity] = useState(
    state.data &&
      state.data.supplier_entities &&
      state.data.supplier_entities.length > 0 &&
      state.data.supplier_entities[0].supplier_entity
  );
  // state variable hold the supplier Country given in  input field
  const [supplierCountry, setSupplierCountry] = useState(
    state.data &&
      state.data.supplier_entities &&
      state.data.supplier_entities.length > 0 &&
      state.data.supplier_entities[0].supplier_country
  );
  // state variable hold the supplier address given in  input field
  const [supplierAddress, setSupplierAddress] = useState(
    state.data &&
      state.data.supplier_entities &&
      state.data.supplier_entities.length > 0 &&
      state.data.supplier_entities[0].supplier_address
  );
  // state variable hold the multicheck box in input field
  const [dirty, setDirty] = useState(false);
  // state variable hold the loading icon while fetch the response from API
  const [tableLoader, setTableLoader] = useState(false);
  // state variable hold the loading while create the data and send to API
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  const [supId, setSupId] = useState("");
  // state variable hold the define value 1 means draft and value 2 means submit
  const stateButton = {
    button: 1,
  };
  const [associationData, setAssociationData] = useState([]);
  //state variable hold the file information
  const [supplierEntityFile, setSupplierEntityFile] = useState([]);
  //state variable hold the value upload and table in state
  const [entityField, setEntityField] = useState("upload");
  //state variable hold the upload validation required true or false
  const [uploadRule, setUploadRule] = useState(false);
  //state variable hold the upload validation error text
  const [uploadRuleText, setUploadRuleText] = useState(); //  upload validation error text
  // state variable hold the inital value for table
  const [dataSourceClone, setDataSourceClone] = useState(
    state && state.data && state.data.supplier_entities
      ? state.data.supplier_entities.map((data, index) => ({
          key: index,
          supplier_entity: data.supplier_entity,
          supplier_country: data.supplier_country,
          supplier_address: data.supplier_address,
        }))
      : [
          {
            key: 0,
            supplier_entity: "",
            supplier_country: "",
            supplier_address: "",
          },
        ]
  );
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state
  const [finalPath, setFinalPath] = useState([]);
  const [selectedContract, setSelectedContract] = useState();
  const supplierTreeData = useRecoilValue(SupplierTreeDataAtom);
  let disableVal = "disable-btn";
  const [disable, setDisable] = useState(disableVal);
  const [status, setStatus] = useState(
    state.data && state.data.supplier_status
  );
  const [viewedFile, setViewedFile] = useState(false);
  // state for store new comments
  const [comments, setComments] = useState([]);
  const [url, setUrl] = useState([
    {
      fileType: "docx",
    },
  ]);
  // uploaded contract file
  const [supFile, setSupFile] = useState([]);
  const [fileKeysToDelete, setFileKeysToDelete] = useState([]);
  const [leTabKey, setLeTabKey] = useState("1");
  const [notesSaveLoader, setNotesSaveLoader] = useState(false);

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);

  /*
   * Function to check the excel format and indicate file success or unsuccess
   *
   */
  const props = {
    name: "file",
    listType: "picture",
    accept: ["xlsx", "xls"],
    maxCount: 1,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
        if (info.fileList.length !== 0) {
          setSupplierEntityFile(info.fileList);
        } else {
          setSupplierEntityFile([]);
        }
      }
      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          setSupplierEntityFile(info.fileList);
        } else {
          setSupplierEntityFile([]);
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
    beforeUpload(file) {
      const acceptedFormats = ["xls", "xlsx"];
      const formatCheck = acceptedFormats.includes(file.name.split(".")[1]);
      // console.log("formatCheck", formatCheck);
      if (!formatCheck) {
        setUploadRule(true);
        setUploadRuleText("You can only upload the excel files.");
        popupModalInstance.errorModal(ModalMessage.SS_Excel_Upload_Error);
      } else {
        setUploadRule(false);
        setUploadRuleText("");
      }
      return formatCheck || Upload.LIST_IGNORE;
    },
  };
  /* Function to uplaod the file  and set succes
   * @param file - upload file imformation
   * @param onSuccess - indicate success while file upload correctly
   */
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  /*function to handle supplier name input
   *@param e - input value is given
   */
  const handleSupplierName = (e) => {
    setSupplierName(e.target.value);
    e.target.value !== "" &&
    state.data &&
    state.data.supplier_name !== e.target.value
      ? setDisable("")
      : setDisable(disableVal);
  };

  /*function to handle supplier entity input
   *@param e - input value is given
   * @param key - corresponding selected entity id
   */
  const handleSupplierEntity = (e, key) => {
    setSupplierEntity(e.target.value);
    console.log("key", key, typeof key);
    const updatedArray = dataSourceClone.map((obj) => {
      if (obj.key === key) {
        return { ...obj, supplier_entity: e.target.value };
      }
      return obj;
    });
    setDataSourceClone(updatedArray);

    let editedEntity =
      state.data &&
      state.data.supplier_entities &&
      state.data.supplier_entities.length > 0 &&
      state.data.supplier_entities.find(
        (data) => Number(data.key) === Number(key)
      );
    editedEntity &&
    editedEntity.supplier_entity &&
    editedEntity.supplier_entity !== e.target.value
      ? setDisable("")
      : setDisable(disableVal);
  };

  /*function to handle supplier Country input
   *@param key - corresponding selected Country id
   *@param value - corresponding selected Country name
   */
  const handleSupplierCountry = (value, key) => {
    setSupplierCountry(value);
    setDirty(true);

    const updatedArray = dataSourceClone.map((obj) => {
      if (obj.key === key) {
        return { ...obj, supplier_country: value };
      }
      return obj;
    });
    setDataSourceClone(updatedArray);

    let editedEntity =
      state.data &&
      state.data.supplier_entities &&
      state.data.supplier_entities.length > 0 &&
      state.data.supplier_entities.find(
        (data) => Number(data.key) === Number(key)
      );
    editedEntity &&
    editedEntity.supplier_country &&
    editedEntity.supplier_country !== value
      ? setDisable("")
      : setDisable(disableVal);
  };

  /*function to handle supplier address input
   *@param e - input value is given
   * @param key - corresponding selected address id
   */
  const handleSupplierAddress = (e, key) => {
    setSupplierAddress(e.target.value);

    const updatedArray = dataSourceClone.map((obj) => {
      if (obj.key === key) {
        return { ...obj, supplier_address: e.target.value };
      }
      return obj;
    });
    setDataSourceClone(updatedArray);

    let editedEntity =
      state.data &&
      state.data.supplier_entities &&
      state.data.supplier_entities.length > 0 &&
      state.data.supplier_entities.find(
        (data) => Number(data.key) === Number(key)
      );
    editedEntity &&
    editedEntity.supplier_address &&
    editedEntity.supplier_address !== e.target.value
      ? setDisable("")
      : setDisable(disableVal);
  };

  // state variable hold the inital value for table
  const [dataSource, setDataSource] = useState([
    {
      key: 0,
      supplier_entity: "",
      supplier_country: "",
      supplier_address: "",
    },
  ]);
  // state variable hold the count value while clone data is performe
  const [count, setCount] = useState(
    state.data.supplier_entities && state.data.supplier_entities.length !== 0
      ? state.data.supplier_entities.length
      : 1
  );

  /*function to delete column in table
   *@param key - corresponding row id
   */
  const handleDelete = (key) => {
    const newData = dataSourceClone.filter((item) => item.key !== key);
    setDataSource(newData);
    setDataSourceClone(newData);
    setDisable("");
  };
  /*function to handle column for table
   */
  const defaultColumns = [
    {
      title: "Supplier Entity",
      dataIndex: "supplier_entity",
      align: "left",
      width: "19em",
      ellipsis: true,
      show: true,
      render: (index, record) => (
        <>
          <Form.Item
            name={[record.key, "supplier_entity"]}
            initialValue={
              state && state.flag === "create"
                ? dataSource.find((obj) => obj.key === record.key)
                    .supplier_entity
                : dataSourceClone.find((obj) => obj.key === record.key)
                    .supplier_entity
            }
            rules={[
              {
                required: true,
                message: "Please enter supplier entity",
                pattern: /^(?!\s*$).+/,
              },
            ]}
          >
            <Input
              readOnly={state.flag === "view"}
              placeholder="Enter Supplier Entity Name"
              onChange={(e) => handleSupplierEntity(e, record.key)}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Supplier Country",
      dataIndex: "supplier_country",
      align: "left",
      width: "19em",
      ellipsis: true,
      show: true,
      render: (index, record) => (
        <Form.Item
          name={[record.key, "supplier_country"]}
          initialValue={
            state && state.flag === "create"
              ? dataSource.find((obj) => obj.key === record.key)
                  .supplier_country
              : dataSourceClone.find((obj) => obj.key === record.key)
                  .supplier_country
          }
          rules={[
            {
              required: true,
              message: "Please select supplier Country",
            },
          ]}
        >
          <Select
            disabled={state.flag === "view"}
            onChange={(value) => handleSupplierCountry(value, record.key)}
            showSearch={true}
            placeholder="Choose country"
            allowClear={true}
            options={ContractCurrency.map((item) => ({
              label: (
                <Checkbox
                  onClick={(e) => {
                    if (dirty) {
                      e.stopPropagation();
                    }
                    setDirty(false);
                  }}
                >
                  {item.country}
                </Checkbox>
              ),
              value: item.country,
            }))}
          ></Select>
        </Form.Item>
      ),
    },
    {
      title: "Supplier Address",
      dataIndex: "supplier_address",
      align: "left",
      width: "19em",
      ellipsis: true,
      show: true,
      render: (index, record) => (
        <>
          <Form.Item
            name={[record.key, "supplier_address"]}
            initialValue={
              state && state.flag === "create"
                ? dataSource.find((obj) => obj.key === record.key)
                    .supplier_address
                : dataSourceClone.find((obj) => obj.key === record.key)
                    .supplier_address
            }
            rules={[
              {
                required: true,
                message: "Please enter supplier address",
                pattern: /^(?!\s*$).+/,
              },
            ]}
          >
            <Input
              readOnly={state.flag === "view"}
              placeholder="Enter Supplier Address"
              onChange={(e) => handleSupplierAddress(e, record.key)}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: <Image src={add} className="hide-add" preview={false} />,
      dataIndex: "action",
      align: "center",
      width: 50,
      show: state.flag !== "view",
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {dataSource.length > 1 && (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record.key)}
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          )}
          {/* {state.flag !== "view" && ( */}
          <a>
            <Image
              title="Clone"
              src={state.flag !== "view" ? duplicate : ""}
              style={{ width: "20px" }}
              preview={false}
              className="cursor-pointer"
              onClick={() => {
                handleAddClone(record.key);
              }}
            />
          </a>
        </div>
      ),
    },
  ].filter((col) => col.show);

  /*function to add column for table
   */
  const handleAdd = () => {
    const newData = {
      key: count,
      supplier_entity: "",
      supplier_country: "",
      supplier_address: "",
    };
    setDataSource([...dataSource, newData]);
    setDataSourceClone([...dataSourceClone, newData]);
    setCount(count + 1);
    setDisable("");
  };
  /*function to add clone data for table
   */
  const handleAddClone = (key) => {
    const findNewData = dataSourceClone.filter((data) => data.key === key);
    let newData = {
      key: count,
      supplier_entity: findNewData[0]?.supplier_entity
        ? findNewData[0].supplier_entity
        : "",
      supplier_country: findNewData[0]?.supplier_country
        ? findNewData[0].supplier_country
        : "",
      supplier_address: findNewData[0]?.supplier_address
        ? findNewData[0].supplier_address
        : "",
    };
    setDataSource([...dataSource, newData]);
    setDataSourceClone([...dataSourceClone, newData]);
    setCount(count + 1);
    setDisable("");
  };

  /*function to handle save data after given enter  in table
   */

  const handleSave = (row) => {
    const newData = [...dataSourceClone];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  /* Function to handle edit value in column while existing is present
   */
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const getPath = (model, id) => {
    let path,
      item = {
        id: model.id,
        name: model.contract_name
          ? model.contract_name
          : model[`${model.contract_type.toLowerCase()}_name`],
      };
    if (!model || typeof model !== "object") return;
    if (model.id === id) return [item];
    (model.children || []).some((child) => (path = getPath(child, id)));
    return path && [item, ...path];
  };

  const getTopPath = (contract) => {
    supplierTreeData.some((res) => {
      const pathVal = getPath(res, contract._id);
      if (pathVal) {
        setFinalPath([...pathVal]);
        return pathVal;
      }
      return false;
    });
    setSelectedContract(contract);
  };

  useEffect(() => {
    if (state.flag === "edit" || state.flag === "view") {
      let allAssociations = [];
      getRatecardBySupplier(state && state.data && state.data._id).then(
        (res) => {
          console.log("rc res", res);
          let association = res.map((data) => {
            let tableRow = {
              associated_item: "Rate Card",
              name: (
                <span
                  onClick={() => {
                    if (
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.system_setup.ratecard_setup.view
                    ) {
                      setHeaderChanger({
                        header: "systemSetup",
                        headerItem: "1",
                        headerItemName: "systemSetup",
                        stateSetter: data,
                      });
                      navigate(`/contract-setup/view/rate-card`, {
                        state: data,
                      });
                    }
                  }}
                  style={{
                    color:
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.system_setup.ratecard_setup.view
                        ? "var(--color-solid-darkblue)"
                        : "var(--color-solid-black)",
                    fontWeight: "100",
                    cursor:
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.system_setup.ratecard_setup.view
                        ? "pointer"
                        : "default",
                  }}
                >
                  {data.ratetable_name}
                </span>
              ),
              association_date: moment(data.createdAt).format("DD-MMM-YY"),
            };
            allAssociations.push(tableRow);
            // return tableRow;
          });
        }
      );
      getFxBySupplier(state && state.data && state.data._id).then((res) => {
        console.log("fx res", res);
        let association = res.map((data) => {
          let tableRow = {
            associated_item: "Fx Table",
            name: (
              <span
                onClick={() => {
                  if (
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.fx_setup.view
                  ) {
                    setHeaderChanger({
                      header: "systemSetup",
                      headerItem: "2",
                      headerItemName: "systemSetup",
                      stateSetter: data,
                    });
                    navigate(`/contract-setup/view/fx-setup`, {
                      state: data,
                    });
                  }
                }}
                style={{
                  color:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.fx_setup.view
                      ? "var(--color-solid-darkblue)"
                      : "var(--color-solid-black)",
                  fontWeight: "100",
                  cursor:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.fx_setup.view
                      ? "pointer"
                      : "default",
                }}
              >
                {data.fx_table_name}
              </span>
            ),
            association_date: moment(data.createdAt).format("DD-MMM-YY"),
          };
          // return tableRow;
          allAssociations.push(tableRow);
        });
      });
      getPricingProfileBySupplier(state && state.data && state.data._id).then(
        (res) => {
          console.log("pp res", res);
          let association = res.map((data) => {
            let tableRow = {
              associated_item: "Pricing Profile",
              name: (
                <span
                  onClick={() => {
                    if (
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.system_setup.pricing_profile.view
                    ) {
                      setHeaderChanger({
                        header: "systemSetup",
                        headerItem: "2",
                        headerItemName: "systemSetup",
                        stateSetter: data,
                      });
                      navigate(`/contract-setup/view/pricing-profile`, {
                        state: data,
                      });
                    }
                  }}
                  style={{
                    color:
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.system_setup.pricing_profile.view
                        ? "var(--color-solid-darkblue)"
                        : "var(--color-solid-black)",
                    fontWeight: "100",
                    cursor:
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.system_setup.pricing_profile.view
                        ? "pointer"
                        : "default",
                  }}
                >
                  {data.price_profile_name}
                </span>
              ),
              association_date: moment(data.createdAt).format("DD-MMM-YY"),
            };
            // return tableRow;
            allAssociations.push(tableRow);
          });
        }
      );
      getInvoicingProfileBySupplier(state && state.data && state.data._id).then(
        (res) => {
          console.log("ip res", res);
          let association = res.map((data) => {
            let tableRow = {
              associated_item: "Invoicing Profile",
              name: (
                <span
                  onClick={() => {
                    if (
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.system_setup.invoicing_profile
                        .view
                    ) {
                      setHeaderChanger({
                        header: "systemSetup",
                        headerItem: "2",
                        headerItemName: "systemSetup",
                        stateSetter: data,
                      });
                      navigate(`/contract-setup/view/invoicing-profile`, {
                        state: data,
                      });
                    }
                  }}
                  style={{
                    color:
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.system_setup.invoicing_profile
                        .view
                        ? "var(--color-solid-darkblue)"
                        : "var(--color-solid-black)",
                    fontWeight: "100",
                    cursor:
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.system_setup.invoicing_profile
                        .view
                        ? "pointer"
                        : "default",
                  }}
                >
                  {data.invoice_profile_name}
                </span>
              ),
              association_date: moment(data.createdAt).format("DD-MMM-YY"),
            };
            // return tableRow;
            allAssociations.push(tableRow);
          });
        }
      );
      getContractBySupplier(state && state.data && state.data._id).then(
        (res) => {
          console.log("contract res", res);
          let association = res.map((data) => {
            let tableRow = {
              associated_item: "Contract",
              name: (
                <span
                  onClick={() => {
                    if (
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.contracts.view_contract.view
                    ) {
                      setHeaderChanger({
                        header: "contract",
                        headerItem: "2",
                        headerItemName: "contract",
                        stateSetter: data,
                      });
                      getTopPath(data);
                      // navigate(`/contract-setup/view/invoicing-profile`, {
                      //   state: data,
                      // });
                    }
                  }}
                  style={{
                    color:
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.contracts.view_contract.view
                        ? "var(--color-solid-darkblue)"
                        : "var(--color-solid-black)",
                    fontWeight: "100",
                    cursor:
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.contracts.view_contract.view
                        ? "pointer"
                        : "default",
                  }}
                >
                  {data.contract_name}
                </span>
              ),
              association_date: moment(data.createdAt).format("DD-MMM-YY"),
            };
            // return tableRow;
            allAssociations.push(tableRow);
          });
        }
      );
      setAssociationData(allAssociations);
    }
  }, [state]);

  const handleRadioClick = (value) => {
    console.log("status checked", value);
    if (
      value !== "Active" &&
      state.data.supplier_status === "Active" &&
      associationData.length > 0
    ) {
      popupModalInstance
        .infoModal(ModalMessage.Supplier_Status_Change)
        .then(async (userChoice) => {
          console.log("userChoice", userChoice);
          if (userChoice === "ok") {
            setStatus(value);
            state.data.supplier_status !== value
              ? setDisable("")
              : setDisable(disableVal);
          }
        });
    } else {
      popupModalInstance
        .infoModal(
          value === "Active"
            ? ModalMessage.Supplier_Status_Change_To_Active
            : ModalMessage.Supplier_Status_Change_Confirm
        )
        .then(async (userChoice) => {
          console.log("userChoice", userChoice);
          if (userChoice === "ok") {
            setStatus(value);
            state.data.supplier_status !== value
              ? setDisable("")
              : setDisable(disableVal);
          }
        });
    }
  };

  const supplierDetails = () => {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 18,
      },
    };
    return (
      <div>
        {state && state.flag === "create" ? (
          <Row>
            <Col span={24}>
              <div className="org-view-div">
                <div className="m-20">
                  <Row align="middle" type="flex">
                    <Col span={8}>
                      <Form.Item
                        {...formItemLayout}
                        className="org-supplier-label"
                        name="supplier_name"
                        label="Enter Supplier Name"
                        colon={false}
                        rules={[
                          {
                            required: "true",
                            message: "Please enter supplier name",
                            pattern: /^(?!\s*$).+/,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter your title"
                          onChange={handleSupplierName}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {entityField === "upload" && (
                    <>
                      <div className="create-upload-entity-div">
                        <Row
                          align="middle"
                          type="flex"
                          style={{ padding: "10px 30px 0px" }}
                        >
                          <Col
                            span={24}
                            align="middle"
                            className="vertical-align"
                          >
                            <h4 className="entity-text">
                              Enter Supplier Details
                            </h4>
                          </Col>
                        </Row>
                        <hr className="mt-10 view-supplier-hr" />
                        <Row
                          align="middle"
                          gutter={[40, 16]}
                          justify="center"
                          className="mt-25"
                        >
                          <Col
                            span={8}
                            align="right"
                            className="vertical-align"
                          >
                            <div className="choose-input-div">
                              <Form.Item
                                rules={[
                                  {
                                    required: uploadRule,
                                    message: uploadRuleText,
                                  },
                                ]}
                              >
                                <Row type="flex" gutter={[64, 56]}>
                                  <Col
                                    span={24}
                                    align="middle"
                                    className="vertical-align"
                                  >
                                    <span className="upload-note">
                                      Click below to enter Supplier Details
                                      through Excel Upload
                                    </span>
                                  </Col>
                                  <Col span={24} align="middle">
                                    <Upload
                                      {...props}
                                      customRequest={dummyRequest}
                                    >
                                      <Col
                                        span={10}
                                        align="middle"
                                        className="vertical-align"
                                      >
                                        <ExcelButton />
                                      </Col>
                                    </Upload>
                                  </Col>
                                  <Col
                                    span={24}
                                    className="vertical-align"
                                    align="middle"
                                  >
                                    <div>
                                      <span className="upload-note">
                                        Download &nbsp;&nbsp;
                                        <Link
                                          to={supplierEntityTemplate}
                                          download="Supplier_Entities_Template"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Supplier Entities Excel Template
                                        </Link>
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </Form.Item>
                            </div>
                          </Col>
                          <Col span={8} align="left" className="vertical-align">
                            <div className="choose-input-div">
                              <Row
                                type="flex"
                                gutter={
                                  supplierEntityFile.length > 0
                                    ? [64, 92]
                                    : [64, 56]
                                }
                              >
                                <Col
                                  span={24}
                                  align="middle"
                                  className="vertical-align"
                                >
                                  <span className="upload-note">
                                    Click below to enter Supplier Details
                                    through Online Mode
                                  </span>
                                </Col>
                                <Col span={24} align="middle">
                                  <Col
                                    span={10}
                                    align="middle"
                                    className="vertical-align"
                                  >
                                    <TableButton
                                      onClick={() => {
                                        setSupplierEntityFile([]);
                                        setEntityField("table");
                                      }}
                                    />
                                  </Col>
                                </Col>
                                <Col span={24}>
                                  <div style={{ height: 46 }}></div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                  {entityField === "table" && (
                    <>
                      <div className="create-table-entity-div">
                        <Row
                          align="middle"
                          type="flex"
                          style={{ padding: "10px 30px 0px" }}
                        >
                          <Col span={19} className="vertical-align">
                            <h4 className="entity-text">
                              Supplier Entity Details
                            </h4>
                          </Col>
                        </Row>
                        <hr className="mt-10 view-supplier-hr" />
                        <Row
                          style={{
                            padding: "19px 0px 29px 15px",
                          }}
                        >
                          <p className="upload-note">
                            If you want to enter Supplier Entity details by
                            uploading your file, then&nbsp;&nbsp;
                            <a onClick={() => setEntityField("upload")}>
                              click here
                            </a>
                          </p>
                        </Row>
                      </div>

                      <div className="mt-20">
                        <Row align="middle" type="flex">
                          <Col span={16} offset={4}>
                            <Form.List name="supplier_entities">
                              {() => (
                                <Table
                                  dataSource={dataSource}
                                  columns={columns}
                                  scroll={{
                                    x: "calc(300px + 50%)",
                                    y: 400,
                                  }}
                                  pagination={false}
                                  id={setupDataTableForm}
                                  className={dataTable}
                                />
                              )}
                            </Form.List>
                          </Col>
                        </Row>
                        {state && state.flag !== "view" && (
                          <div className="text-center mt-20">
                            <Button
                              onClick={handleAdd}
                              shape="round"
                              className="add-btn mb-10"
                              size="medium"
                              style={{
                                width: 90,
                              }}
                            >
                              <PlusOutlined style={{ color: "#9b9b9b" }} />
                            </Button>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={24}>
              <div className="org-view-div">
                <div className="m-20">
                  <Row align="middle" type="flex">
                    <Col span={8}>
                      <Form.Item
                        {...formItemLayout}
                        className="org-supplier-label"
                        name="supplier_name"
                        label="Supplier Name"
                        colon={false}
                        rules={[
                          {
                            required: "true",
                            message: "Please enter supplier name",
                            pattern: /^(?!\s*$).+/,
                          },
                        ]}
                      >
                        <Input
                          disabled={state.flag === "view"}
                          onChange={handleSupplierName}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {state.flag === "edit" &&
                    state.data &&
                    state.data.supplier_status !== "In Draft" && (
                      <>
                        <Row align="middle" type="flex">
                          <Col span={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              name="supplier_status"
                              label="Supplier Status"
                              colon={false}
                            >
                              <div className="association-radio-status">
                                <Radio.Group
                                  disabled={state.flag === "view"}
                                  buttonStyle="solid"
                                  value={status}
                                >
                                  <Radio
                                    value={"Active"}
                                    onClick={(e) =>
                                      handleRadioClick(e.target.value)
                                    }
                                  >
                                    Active
                                  </Radio>
                                  <Radio
                                    value={"Inactive"}
                                    onClick={(e) =>
                                      handleRadioClick(e.target.value)
                                    }
                                  >
                                    Inactive
                                  </Radio>
                                </Radio.Group>
                              </div>
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                  <Row align="middle" type="flex">
                    <Col span={16} offset={4}>
                      <Form.List
                        name="supplier_entities_1"
                        initialValue={dataSourceClone}
                      >
                        {() => (
                          <Table
                            dataSource={dataSourceClone}
                            columns={columns}
                            scroll={{
                              x: "calc(300px + 50%)",
                              y: 400,
                            }}
                            pagination={false}
                            id={setupDataTableForm}
                            className={`mt-20 ${dataTable}`}
                          />
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                </div>
                {state && state.flag !== "view" && (
                  <div className="text-center plus-div">
                    <Button
                      onClick={handleAdd}
                      shape="round"
                      className="add-btn mb-10"
                      size="medium"
                      style={{
                        width: 90,
                      }}
                    >
                      <PlusOutlined style={{ color: "#9b9b9b" }} />
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  const Associations = () => {
    return (
      <DataTable
        type="association"
        dataTableData={associationData}
        logData={state.data}
      />
    );
  };

  const EntityLog = () => {
    return <DataTable type="entityLog" logData={state.data} />;
  };

  const items = [
    {
      key: "1",
      label: `Supplier Details`,
      children: supplierDetails(),
      show: true,
    },
    {
      key: "divider1",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: state.flag !== "create",
    },
    {
      key: "2",
      label: `Associations`,
      children: Associations(),
      show: state.flag !== "create",
    },
    {
      key: "divider2",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: state.flag === "view",
    },
    {
      key: "3",
      label: `Entity Log`,
      children: EntityLog(),
      show: state.flag === "view",
    },
    {
      key: "divider3",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: true,
    },
    {
      key: "4",
      label: `Notes And Comments`,
      children: (
        <NotesAndComments
          detailData={state.data}
          viewedFile={viewedFile}
          comments={comments}
          url={url}
          setUrl={setUrl}
          setFile={setSupFile}
          setFileKeysToDelete={setFileKeysToDelete}
          setViewedFile={setViewedFile}
          setComments={setComments}
          flag="setup"
          setDisable={setDisable}
        />
      ),
      show: true,
    },
  ].filter((items) => items.show);

  useEffect(() => {
    console.log("dataSourceClone", dataSourceClone);
  }, [dataSourceClone]);

  // form submit
  const onFinish = async (values) => {
    values["author"] =
      userData && userData[0] && userData[0].id
        ? userData[0].id
        : userData[0]._id
        ? userData[0]._id
        : "";
    values["author_name"] =
      userData && userData[0] && userData[0].first_name
        ? userData[0].first_name
        : "";
    values["comments"] = comments.length !== 0 ? comments : [];

    const uploadForm = new FormData();
    if (supFile && supFile.length > 0) {
      for (let i = 0; i < supFile.length; i++) {
        uploadForm.append("notesCommentsFiles", supFile[i].originFileObj);
      }
    }
    if (supplierEntityFile && supplierEntityFile.length > 0) {
      for (let i = 0; i < supplierEntityFile.length; i++) {
        uploadForm.append(
          "supplierEntityFile",
          supplierEntityFile[i].originFileObj
        );
      }
    }

    // create
    if (state.flag === "create") {
      console.log("cv", values);
      stateButton.button === 1
        ? setSaveTableLoader(true)
        : setTableLoader(true);
      Object.keys(values).forEach(
        (key) => values[key] === undefined && delete values[key]
      );
      values["updated_by"] = userData[0].id || userData[0]._id;
      console.log("valuess", values);
      if (entityField === "upload") {
        values["supplier_entities"] = [];
        values["supplier_entity_type"] = supplierEntityFile ? "File" : "Array";
        values["supplier_entities_file"] = supplierEntityFile
          ? supplierEntityFile.originFileObj
          : {};

        console.log("valueding", values);

        // save button handler
        if (stateButton.button === 1) {
          if (fileKeysToDelete.length !== 0) {
            fileKeysToDelete.map(async (fileKey) => {
              await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
            });
          }
          setSaveTableLoader(true);
          values["supplier_status"] = "In Draft";
          if (supId === "") {
            values["created_by"] = userData[0].id || userData[0]._id;
            uploadForm.append("bodyData", JSON.stringify(values));
            await suppliersCreateApi(uploadForm).then((res) => {
              console.log("resss", res);
              if (res.status === 304) {
                setSaveTableLoader(false);
                setTableLoader(false);
                popupModalInstance.errorModalOfSupplier(res.message);
              } else {
                if (res.status === 201) {
                  setTableLoader(false);
                  setSaveTableLoader(false);
                  setSupId(res.data["id"] ? res.data["id"] : res.data["_id"]);
                  let ssLog = {
                    ss_id: res.data["id"] ? res.data["id"] : res.data["_id"],
                    ss_name: values["supplier_name"],
                    code: "supplier_setup",
                    author: userData[0].id || userData[0]._id,
                    action: "view",
                    notes: "Created New Supplier",
                    updated_on: new Date(),
                  };
                  createActivityLogApi(ssLog).then((res) => {});
                  popupModalInstance.successModal(
                    values.supplier_name,
                    ModalMessage.SS_Create_Save,
                    ""
                  );
                } else {
                  setTableLoader(false);
                  setSaveTableLoader(false);
                  Modal.error({
                    title: "Error on create",
                    className: "popup-model-success",
                    centered: true,
                    icon: false,
                    content: (
                      <>
                        <div style={{ fontWeight: 600 }} className="mb-10">
                          Supplier creation failed for following reasons:
                        </div>
                        {res.message.map((err, index) => {
                          return (
                            <div>
                              {index + 1}.&nbsp; {err}
                            </div>
                          );
                        })}
                      </>
                    ),
                  });
                }
              }
            });
          } else {
            uploadForm.append("bodyData", JSON.stringify(values));
            await editSupplierApi(supId, uploadForm).then((res) => {
              // console.log("res-submit", res);
              if (res.status === 201) {
                setTableLoader(false);
                setSaveTableLoader(false);
                let ssLog = {
                  ss_id: res.data["id"] ? res.data["id"] : res.data["_id"],
                  ss_name: values["supplier_name"],
                  code: "supplier_setup",
                  author: userData[0].id,
                  action: "edit",
                  notes: "Edited Supplier",
                  updated_on: new Date(),
                };
                createActivityLogApi(ssLog).then((res) => {
                  console.log("LogRes: ", res);
                });
                popupModalInstance.successModal(
                  values.supplier_name,
                  ModalMessage.SS_Create_Save,
                  ""
                );
              } else {
                setTableLoader(false);
                Modal.error({
                  title: "Error on update",
                  className: "popup-model-success",
                  centered: true,
                  icon: false,
                  content: (
                    <>
                      <div style={{ fontWeight: 600 }} className="mb-10">
                        Supplier Update failed for following reasons:
                      </div>
                      {res.message.map((err, index) => {
                        return (
                          <div>
                            {index + 1}.&nbsp; {err}
                          </div>
                        );
                      })}
                    </>
                  ),
                });
              }
            });
          }
        }

        // submit button handler
        if (stateButton.button === 2) {
          console.log("file-up-values", values);
          console.log("supid", supId);
          setTableLoader(true);
          values["supplier_status"] = "Active";
          if (supId === "") {
            values["created_by"] = userData[0].id || userData[0]._id;
            uploadForm.append("bodyData", JSON.stringify(values));
            popupModalInstance
              .infoModal(ModalMessage.SS_Create_Submit)
              .then(async (userChoice) => {
                if (userChoice === "ok") {
                  if (fileKeysToDelete.length !== 0) {
                    fileKeysToDelete.map(async (fileKey) => {
                      await deleteS3FileApi({
                        fileKey: fileKey,
                        flag: "setup",
                      });
                    });
                  }
                  await suppliersCreateApi(uploadForm).then((res) => {
                    if (res.status === 304) {
                      setSaveTableLoader(false);
                      setTableLoader(false);
                      popupModalInstance.errorModalOfSupplier(res.message);
                    } else {
                      if (res.status === 201) {
                        setTableLoader(false);
                        setSupId(
                          res.data && res.data["id"]
                            ? res.data["id"]
                            : res.data["_id"]
                        );
                        console.log("resssss", res);
                        let ssLog = {
                          ss_id:
                            res.data && res.data["id"]
                              ? res.data && res.data["id"]
                              : res.data && res.data["_id"],
                          ss_name: values["supplier_name"],
                          code: "supplier_setup",
                          author: userData[0].id,
                          action: "view",
                          notes: "Created New Supplier",
                          updated_on: new Date(),
                        };
                        createActivityLogApi(ssLog).then((res) => {});
                        navigate(`/system-setup/?back=org-ss`);
                      } else {
                        setTableLoader(false);
                        Modal.error({
                          title: "Error on create",
                          className: "popup-model-success",
                          centered: true,
                          icon: false,
                          content: (
                            <>
                              <div
                                style={{ fontWeight: 600 }}
                                className="mb-10"
                              >
                                Supplier creation failed for following reasons:
                              </div>
                              {res.message.map((err, index) => {
                                return (
                                  <div>
                                    {index + 1}.&nbsp; {err}
                                  </div>
                                );
                              })}
                            </>
                          ),
                        });
                      }
                    }
                  });
                }
                if (userChoice === "cancel") {
                  setTableLoader(false);
                }
              });
          } else {
            uploadForm.append("bodyData", JSON.stringify(values));
            popupModalInstance
              .infoModal(ModalMessage.SS_Edit_Submit)
              .then(async (userChoice) => {
                if (userChoice === "ok") {
                  if (fileKeysToDelete.length !== 0) {
                    fileKeysToDelete.map(async (fileKey) => {
                      await deleteS3FileApi({
                        fileKey: fileKey,
                        flag: "setup",
                      });
                    });
                  }
                  await editSupplierApi(supId, uploadForm).then((res) => {
                    // console.log("res-submit", res);
                    if (res.status === 201) {
                      setTableLoader(false);
                      let ssLog = {
                        ss_id: res.data["id"]
                          ? res.data["id"]
                          : res.data["_id"],
                        ss_name: values["supplier_name"],
                        code: "supplier_setup",
                        author: userData[0].id,
                        action: "edit",
                        notes: "Edited Supplier",
                        updated_on: new Date(),
                      };
                      createActivityLogApi(ssLog).then((res) => {
                        console.log("LogRes: ", res);
                      });
                      navigate(`/system-setup/?back=org-ss`);
                    } else {
                      setTableLoader(false);
                      Modal.error({
                        title: "Error on update",
                        className: "popup-model-success",
                        centered: true,
                        icon: false,
                        content: (
                          <>
                            <div style={{ fontWeight: 600 }} className="mb-10">
                              Supplier Update failed for following reasons:
                            </div>
                            {res.message.map((err, index) => {
                              return (
                                <div>
                                  {index + 1}.&nbsp; {err}
                                </div>
                              );
                            })}
                          </>
                        ),
                      });
                    }
                  });
                }
              });
          }
        }
      } else {
        values["supplier_entity_type"] = "array";
        const updatedData = dataSourceClone.map(({ key, ...rest }) => rest);
        values["supplier_entities"] = updatedData;
        // save button handler
        if (stateButton.button === 1) {
          values["supplier_status"] = "In Draft";
          if (fileKeysToDelete.length !== 0) {
            fileKeysToDelete.map(async (fileKey) => {
              await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
            });
          }
          if (supId === "") {
            values["created_by"] = userData[0].id || userData[0]._id;
            uploadForm.append("bodyData", JSON.stringify(values));
            await suppliersCreateApi(uploadForm).then((res) => {
              if (res.status === 304) {
                setSaveTableLoader(false);
                setTableLoader(false);
                popupModalInstance.errorModalOfSupplier(res.message);
              } else {
                if (res.status === 201) {
                  setTableLoader(false);
                  setSaveTableLoader(false);
                  setSupId(
                    res.data && res.data["id"]
                      ? res.data["id"]
                      : res.data["_id"]
                  );
                  let ssLog = {
                    ss_id: res.data["id"] ? res.data["id"] : res.data["_id"],
                    ss_name: values["supplier_name"],
                    code: "supplier_setup",
                    author: userData[0].id || userData[0]._id,
                    action: "view",
                    notes: "Created New Supplier",
                    updated_on: new Date(),
                  };
                  createActivityLogApi(ssLog).then((res) => {});
                  popupModalInstance.successModal(
                    values.supplier_name,
                    ModalMessage.SS_Create_Save,
                    ""
                  );
                } else {
                  setTableLoader(false);
                  setSaveTableLoader(false);
                  Modal.error({
                    title: "Error on create",
                    className: "popup-model-success",
                    centered: true,
                    icon: false,
                    content: (
                      <>
                        <div style={{ fontWeight: 600 }} className="mb-10">
                          Supplier creation failed for following reasons:
                        </div>
                        {res.message.map((err, index) => {
                          return (
                            <div>
                              {index + 1}.&nbsp; {err}
                            </div>
                          );
                        })}
                      </>
                    ),
                  });
                }
              }
            });
          } else {
            uploadForm.append("bodyData", JSON.stringify(values));
            await editSupplierApi(supId, uploadForm).then((res) => {
              // console.log("res-submit", res);
              if (res.status === 201) {
                setTableLoader(false);
                setSaveTableLoader(false);
                let ssLog = {
                  ss_id: res.data["id"] ? res.data["id"] : res.data["_id"],
                  ss_name: values["supplier_name"],
                  code: "supplier_setup",
                  author: userData[0].id,
                  action: "edit",
                  notes: "Edited Supplier",
                  updated_on: new Date(),
                };
                createActivityLogApi(ssLog).then((res) => {
                  console.log("LogRes: ", res);
                });
                popupModalInstance.successModal(
                  values.supplier_name,
                  ModalMessage.SS_Create_Save,
                  ""
                );
              } else {
                setTableLoader(false);
                Modal.error({
                  title: "Error on update",
                  className: "popup-model-success",
                  centered: true,
                  icon: false,
                  content: (
                    <>
                      <div style={{ fontWeight: 600 }} className="mb-10">
                        Supplier Update failed for following reasons:
                      </div>
                      {res.message.map((err, index) => {
                        return (
                          <div>
                            {index + 1}.&nbsp; {err}
                          </div>
                        );
                      })}
                    </>
                  ),
                });
              }
            });
          }
        }

        // submit button handler
        if (stateButton.button === 2) {
          values["supplier_status"] = "Active";
          if (supId === "") {
            values["created_by"] = userData[0].id || userData[0]._id;
            uploadForm.append("bodyData", JSON.stringify(values));
            popupModalInstance
              .infoModal(ModalMessage.SS_Create_Submit)
              .then(async (userChoice) => {
                if (userChoice === "ok") {
                  if (fileKeysToDelete.length !== 0) {
                    fileKeysToDelete.map(async (fileKey) => {
                      await deleteS3FileApi({
                        fileKey: fileKey,
                        flag: "setup",
                      });
                    });
                  }
                  await suppliersCreateApi(uploadForm).then((res) => {
                    if (res.status === 304) {
                      setSaveTableLoader(false);
                      setTableLoader(false);
                      popupModalInstance.errorModalOfSupplier(res.message);
                    } else {
                      if (res.status === 201) {
                        setTableLoader(false);
                        setSupId(
                          res.data && res.data["id"]
                            ? res.data["id"]
                            : res.data["_id"]
                        );
                        let ssLog = {
                          ss_id: res.data["id"]
                            ? res.data["id"]
                            : res.data["_id"],
                          ss_name: values["supplier_name"],
                          code: "supplier_setup",
                          author: userData[0].id || userData[0]._id,
                          action: "view",
                          notes: "Created New Supplier",
                          updated_on: new Date(),
                        };
                        createActivityLogApi(ssLog).then((res) => {});
                        navigate(`/system-setup/?back=org-ss`);
                      } else {
                        setTableLoader(false);
                        Modal.error({
                          title: "Error on create",
                          className: "popup-model-success",
                          centered: true,
                          icon: false,
                          content: (
                            <>
                              <div
                                style={{ fontWeight: 600 }}
                                className="mb-10"
                              >
                                Supplier creation failed for following reasons:
                              </div>
                              {res.message.map((err, index) => {
                                return (
                                  <div>
                                    {index + 1}.&nbsp; {err}
                                  </div>
                                );
                              })}
                            </>
                          ),
                        });
                      }
                    }
                  });
                }
                if (userChoice === "cancel") {
                  setTableLoader(false);
                }
              });
          } else {
            uploadForm.append("bodyData", JSON.stringify(values));
            popupModalInstance
              .infoModal(ModalMessage.SS_Edit_Submit)
              .then(async (userChoice) => {
                if (userChoice === "ok") {
                  if (fileKeysToDelete.length !== 0) {
                    fileKeysToDelete.map(async (fileKey) => {
                      await deleteS3FileApi({
                        fileKey: fileKey,
                        flag: "setup",
                      });
                    });
                  }
                  await editSupplierApi(supId, uploadForm).then((res) => {
                    // console.log("res-submit", res);
                    if (res.status === 201) {
                      setTableLoader(false);
                      let ssLog = {
                        ss_id: res.data["id"]
                          ? res.data["id"]
                          : res.data["_id"],
                        ss_name: values["supplier_name"],
                        code: "supplier_setup",
                        author: userData[0].id,
                        action: "edit",
                        notes: "Edited Supplier",
                        updated_on: new Date(),
                      };
                      createActivityLogApi(ssLog).then((res) => {
                        console.log("LogRes: ", res);
                      });
                      navigate(`/system-setup/?back=org-ss`);
                    } else {
                      setTableLoader(false);
                      Modal.error({
                        title: "Error on update",
                        className: "popup-model-success",
                        centered: true,
                        icon: false,
                        content: (
                          <>
                            <div style={{ fontWeight: 600 }} className="mb-10">
                              Supplier Update failed for following reasons:
                            </div>
                            {res.message.map((err, index) => {
                              return (
                                <div>
                                  {index + 1}.&nbsp; {err}
                                </div>
                              );
                            })}
                          </>
                        ),
                      });
                    }
                  });
                }
              });
          }
        }
      }
    }

    // edit
    if (state.flag === "edit") {
      stateButton.button === 1
        ? setSaveTableLoader(true)
        : setTableLoader(true);

      Object.keys(values).forEach(
        (key) => values[key] === undefined && delete values[key]
      );

      console.log("se1", values.supplier_entities_1);
      const updatedValues = { ...values };
      updatedValues["supplier_status"] =
        state.data.supplier_status === "In Draft"
          ? stateButton.button === 1
            ? "In Draft"
            : "Active"
          : stateButton.button === 1
          ? "In Draft"
          : status;
      if (updatedValues.supplier_entities_1) {
        console.log("aaa");
        updatedValues["supplier_entities"] = [
          ...updatedValues["supplier_entities_1"].filter(
            (item) => item !== undefined
          ),
        ];
        console.log("se", updatedValues.supplier_entities);
        if (!updatedValues.hasOwnProperty("supplier_entities")) {
          updatedValues["supplier_entities"] =
            state.data.supplier_entities || undefined;
        }
        delete updatedValues["supplier_entities_1"];
      }
      console.log("bbb");
      console.log("Updated values:", updatedValues);
      updatedValues["updated_by"] = userData[0].id;
      updatedValues["supplier_entity_type"] = "array";
      const updatedData = dataSourceClone.map(({ key, ...rest }) => rest);
      updatedValues["supplier_entities"] = updatedData;
      // save button handler
      if (stateButton.button === 1) {
        if (fileKeysToDelete.length !== 0) {
          fileKeysToDelete.map(async (fileKey) => {
            await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
          });
        }
        uploadForm.append("bodyData", JSON.stringify(updatedValues));
        await editSupplierApi(state.data._id, uploadForm)
          .then((res) => {
            console.log("res-submit", res);
            if (res.status === 201) {
              setTableLoader(false);
              setSaveTableLoader(false);
              let ssLog = {
                ss_id: res.data["id"] ? res.data["id"] : res.data["_id"],
                ss_name: updatedValues["supplier_name"],
                code: "supplier_setup",
                author: userData[0].id || userData[0]._id,
                action: "edit",
                notes: "Edited Supplier",
                updated_on: new Date(),
              };
              createActivityLogApi(ssLog).then((res) => {
                console.log("LogRes: ", res);
              });
              popupModalInstance.successModal(
                updatedValues.supplier_name,
                ModalMessage.SS_Create_Save,
                ""
              );
            } else {
              setTableLoader(false);
              Modal.error({
                title: "Error on update",
                className: "popup-model-success",
                centered: true,
                icon: false,
                content: (
                  <>
                    <div style={{ fontWeight: 600 }} className="mb-10">
                      Supplier Update failed for following reasons:
                    </div>
                    {res.message.map((err, index) => {
                      return (
                        <div>
                          {index + 1}.&nbsp; {err}
                        </div>
                      );
                    })}
                  </>
                ),
              });
            }
          })
          .catch((err) => {
            console.log("errrr", err);
          });
      }

      // submit button handler
      if (stateButton.button === 2) {
        console.log("val", updatedValues);
        uploadForm.append("bodyData", JSON.stringify(updatedValues));
        popupModalInstance
          .infoModal(ModalMessage.SS_Edit_Submit)
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              if (fileKeysToDelete.length !== 0) {
                fileKeysToDelete.map(async (fileKey) => {
                  await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                });
              }
              await editSupplierApi(state.data._id, uploadForm).then((res) => {
                console.log("res-submit", res);
                if (res.status === 201) {
                  setTableLoader(false);
                  setSaveTableLoader(false);
                  let ssLog = {
                    ss_id: res.data["id"] ? res.data["id"] : res.data["_id"],
                    ss_name: updatedValues["supplier_name"],
                    code: "supplier_setup",
                    author: userData[0].id || userData[0]._id,
                    action: "edit",
                    notes: "Edited Supplier",
                    updated_on: new Date(),
                  };
                  createActivityLogApi(ssLog).then((res) => {
                    console.log("LogRes: ", res);
                  });
                  navigate(`/system-setup/?back=org-ss`);
                } else {
                  setTableLoader(false);
                  Modal.error({
                    title: "Error on update",
                    className: "popup-model-success",
                    centered: true,
                    icon: false,
                    content: (
                      <>
                        <div style={{ fontWeight: 600 }} className="mb-10">
                          Supplier Update failed for following reasons:
                        </div>
                        {res.message.map((err, index) => {
                          return (
                            <div>
                              {index + 1}.&nbsp; {err}
                            </div>
                          );
                        })}
                      </>
                    ),
                  });
                }
              });
            }
            if (userChoice === "cancel") {
              setTableLoader(false);
            }
          });
      }
    }

    // clone
    if (state.flag === "clone") {
      setTableLoader(true);
      console.log("se1", values.supplier_entities_1);
      const updatedValues = { ...values };
      if (updatedValues.supplier_entities_1) {
        updatedValues["supplier_entities"] = [
          ...updatedValues["supplier_entities_1"],
        ];
        delete updatedValues["supplier_entities_1"];
      }
      console.log("bbb");
      console.log("Updated values:", updatedValues);
      updatedValues["updated_by"] = userData[0].id || userData[0]._id;
      const updatedData = dataSourceClone.map(({ key, ...rest }) => rest);
      updatedValues["supplier_entities"] = updatedData;
      // save button handler
      if (stateButton.button === 1) {
        setSaveTableLoader(true);
        updatedValues["supplier_status"] = "Draft";

        // Check and assign supplier_entities
        if (!updatedValues.hasOwnProperty("supplier_entities")) {
          updatedValues["supplier_entities"] =
            (state && state.data && state.data.supplier_entities) || undefined;
        }

        // Remove undefined values from the values object
        Object.keys(values).forEach(
          (key) => values[key] === undefined && delete values[key]
        );

        if (fileKeysToDelete.length !== 0) {
          fileKeysToDelete.map(async (fileKey) => {
            await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
          });
        }
        if (supId === "") {
          updatedValues["created_by"] = userData[0].id || userData[0]._id;
          uploadForm.append("bodyData", JSON.stringify(updatedValues));
          await suppliersCreateApi(uploadForm).then((res) => {
            if (res.status === 304) {
              setSaveTableLoader(false);
              setTableLoader(false);
              popupModalInstance.errorModalOfSupplier(res.message);
            } else {
              setSupId(
                res.data && res.data["id"] ? res.data["id"] : res.data["_id"]
              );
              let ssLog = {
                ss_id: res.data["id"] ? res.data["id"] : res.data["_id"],
                ss_name: updatedValues["supplier_name"],
                code: "supplier_setup",
                author: userData[0].id || userData[0]._id,
                action: "view",
                notes: "Cloned Supplier",
                updated_on: new Date(),
              };
              createActivityLogApi(ssLog);
              setTableLoader(false);
              setSaveTableLoader(false);
              popupModalInstance.successModal(
                updatedValues.supplier_name,
                ModalMessage.SS_Clone_Save,
                ``
              );
            }
          });
        } else {
          uploadForm.append("bodyData", JSON.stringify(updatedValues));
          await editSupplierApi(supId, uploadForm).then((res) => {
            // console.log("res-submit", res);
            if (res.status === 201) {
              let ssLog = {
                ss_id: res.data["id"] ? res.data["id"] : res.data["_id"],
                ss_name: updatedValues["supplier_name"],
                code: "supplier_setup",
                author: userData[0].id || userData[0]._id,
                action: "edit",
                notes: "Edited Supplier",
                updated_on: new Date(),
              };
              createActivityLogApi(ssLog).then((res) => {
                console.log("LogRes: ", res);
              });
              setTableLoader(false);
              setSaveTableLoader(false);
              popupModalInstance.successModal(
                updatedValues.supplier_name,
                ModalMessage.SS_Create_Save,
                ""
              );
            } else {
              setTableLoader(false);
            }
          });
        }
      }
      // submit button handler
      if (stateButton.button === 2) {
        setTableLoader(true);
        updatedValues["supplier_status"] = "Active";

        // Check and assign supplier_entities
        if (!updatedValues.hasOwnProperty("supplier_entities")) {
          updatedValues["supplier_entities"] =
            (state && state.data && state.data.supplier_entities) || undefined;
        }

        // Remove undefined values from the values object
        Object.keys(values).forEach(
          (key) => values[key] === undefined && delete values[key]
        );

        if (supId === "") {
          updatedValues["created_by"] = userData[0].id || userData[0]._id;
          uploadForm.append("bodyData", JSON.stringify(updatedValues));
          popupModalInstance
            .infoModal(ModalMessage.SS_Clone_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                if (fileKeysToDelete.length !== 0) {
                  fileKeysToDelete.map(async (fileKey) => {
                    await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                  });
                }
                suppliersCreateApi(uploadForm).then((res) => {
                  if (res.status === 304) {
                    setSaveTableLoader(false);
                    setTableLoader(false);
                    popupModalInstance.errorModalOfSupplier(res.message);
                  } else {
                    let ssLog = {
                      ss_id: res.data["id"] ? res.data["id"] : res.data["_id"],
                      ss_name: updatedValues["supplier_name"],
                      code: "supplier_setup",
                      author: userData[0].id || userData[0]._id,
                      action: "view",
                      notes: "Cloned Supplier",
                      updated_on: new Date(),
                    };
                    createActivityLogApi(ssLog).then((res) => {
                      console.log("LogRes: ", res);
                    });
                    setTableLoader(false);
                    setSaveTableLoader(false);
                    navigate(`/system-setup/?back=org-ss`);
                  }
                });
              }
              if (userChoice === "cancel") {
                setTableLoader(false);
              }
            });
        } else {
          uploadForm.append("bodyData", JSON.stringify(updatedValues));
          popupModalInstance
            .infoModal(ModalMessage.SS_Edit_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                if (fileKeysToDelete.length !== 0) {
                  fileKeysToDelete.map(async (fileKey) => {
                    await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                  });
                }
                await editSupplierApi(supId, uploadForm).then((res) => {
                  // console.log("res-submit", res);
                  if (res.status === 201) {
                    let ssLog = {
                      ss_id: res.data["id"] ? res.data["id"] : res.data["_id"],
                      ss_name: updatedValues["supplier_name"],
                      code: "supplier_setup",
                      author: userData[0].id || userData[0]._id,
                      action: "edit",
                      notes: "Edited Supplier",
                      updated_on: new Date(),
                    };
                    createActivityLogApi(ssLog).then((res) => {
                      console.log("LogRes: ", res);
                    });
                    setTableLoader(false);
                    navigate(`/system-setup/?back=org-ss`);
                  } else {
                    setTableLoader(false);
                  }
                });
              }
            });
        }
      }
    }
  };

  // form error capturing
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };

  useEffect(() => {
    document.title = "Supplier Setup | Judo";
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleTabChange = (key) => {
    setLeTabKey(key);
  };

  const saveNotesCommentsData = async () => {
    let data = {
      author:
        userData && userData[0] && userData[0].id
          ? userData[0].id
          : userData[0]._id
          ? userData[0]._id
          : "",
      author_name:
        userData && userData[0] && userData[0].first_name
          ? userData[0].first_name
          : "",
      file_notes_data: state.data.file_notes_data,
      comments: comments,
    };

    // Prepare form data
    const uploadForm = new FormData();
    if (supFile && supFile.length > 0) {
      for (let i = 0; i < supFile.length; i++) {
        uploadForm.append("file", supFile[i].originFileObj);
      }
    }
    uploadForm.append("uploadForm", JSON.stringify(data)); // Attach other form
    console.log("formdata", uploadForm);
    setNotesSaveLoader(true);

    if (fileKeysToDelete.length !== 0) {
      fileKeysToDelete.map(async (fileKey) => {
        await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
      });
    }

    await updateNotesCommentsApi(
      state.data && state.data._id,
      uploadForm,
      "supplier"
    ).then((res) => {
      if (res) setNotesSaveLoader(false);
    });
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <div className="system-setup1">
          <Form
            name="create_supplier"
            onFinish={onFinish}
            onKeyDown={handleKeyDown}
            onFinishFailed={onFinishFailed}
            initialValues={{
              ["supplier_name"]:
                state && state.data && state.data.supplier_name,
            }}
          >
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
              <Col
                className="vertical-align"
                xs={24}
                sm={state.flag === "view" ? 24 : 20}
                md={state.flag === "view" ? 24 : 21}
                lg={state.flag === "view" ? 24 : 21}
                xl={state.flag === "view" ? 24 : 18}
              >
                <HeaderCard
                  mainMenu="Organization Setup"
                  subMenu="Supplier Setup"
                  //   itemName={
                  //     state.data.supplier_name || state.flag === "create"
                  //       ? "New Supplier Setup"
                  //       : "Clone Supplier Setup"
                  //   }
                  itemName={
                    state.flag === "create"
                      ? "New Supplier Setup"
                      : state.flag === "clone"
                      ? "Clone Supplier Setup"
                      : // : state.flag === "edit"
                        // ? "Edit Supplier Setup"
                        // : state.flag === "view"
                        // ? "View Supplier Setup"
                        state.data?.supplier_name
                  }
                  itemStatus={state.data.supplier_status}
                  goBackLink="/system-setup/?back=org-ss"
                  state={state.data}
                  showDetails={state.flag === "view" || state.flag === "edit"}
                />
              </Col>
              {state.flag !== "view" && (
                <>
                  <Col
                    className="vertical-align"
                    xs={8}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <CancelButton
                      onClick={() => navigate(`/system-setup/?back=org-ss`)}
                    />
                  </Col>
                  <Col
                    className="vertical-align"
                    xs={8}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <SaveButton
                      onClick={() => (stateButton.button = 1)}
                      type="primary"
                      htmlType="submit"
                      loading={saveTableLoader}
                      disabled={
                        state.flag === "create"
                          ? supplierName
                            ? false
                            : true
                          : state.flag === "edit" || state.flag === "clone"
                          ? state.data &&
                            state.data.supplier_status !== "In Draft"
                          : false
                      }
                    />
                  </Col>
                  <Col
                    className="vertical-align"
                    xs={8}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <SubmitButton
                      onClick={() => (stateButton.button = 2)}
                      type="primary"
                      htmlType="submit"
                      loading={tableLoader}
                      disabled={
                        state.flag === "create"
                          ? ((supplierAddress &&
                              supplierCountry &&
                              supplierEntity) ||
                              supplierEntityFile.length > 0) &&
                            supplierName
                            ? false
                            : true
                          : state.flag === "edit"
                          ? state.data &&
                            state.data.supplier_status === "In Draft"
                            ? ((supplierAddress &&
                                supplierCountry &&
                                supplierEntity) ||
                                supplierEntityFile.length > 0) &&
                              supplierName
                              ? false
                              : true
                            : disable
                          : ((supplierAddress &&
                              supplierCountry &&
                              supplierEntity) ||
                              supplierEntityFile.length > 0) &&
                            supplierName
                          ? false
                          : true
                      }
                    />
                  </Col>
                </>
              )}
            </Row>
            <div className="price-profile-content m-10">
              <Row type="flex" gutter={[14, 0]} className="m-0">
                <Col span={24}>
                  <Tabs
                    items={items}
                    onChange={handleTabChange}
                    tabBarExtraContent={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: -6,
                        }}
                      >
                        {state.flag === "view" && leTabKey === "4" && (
                          <SaveButton
                            loading={notesSaveLoader}
                            onClick={() => {
                              saveNotesCommentsData();
                            }}
                            style={{ width: 140 }}
                          />
                        )}
                      </div>
                    }
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      ) : (
        <NotAuthorized redirect={`/system-setup/?back=org-ss`} />
      )}
    </Layout>
  );
}

export default SupplierSetupController;
