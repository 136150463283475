import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Tabs,
  Modal,
  Image,
  Table,
  Card,
  Menu,
  Empty,
  Skeleton,
  message,
  Upload,
  Divider,
  Button,
  Switch,
} from "antd"; //antd components
import axios from "axios";
import {
  RightOutlined,
  SwapRightOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import account_tree from "../../assets/images/icons/account_tree.svg";
import folder_active from "../../assets/images/icons/folder.svg";
import folder_inactive from "../../assets/images/icons/folder_inactive.svg";
import adminPanel_active from "../../assets/images/icons/admin_panel_active.png";
import adminPanel_inactive from "../../assets/images/icons/admin_panel_settings.png";
import approval_active from "../../assets/images/icons/approval_flow_icon.svg";
import approval_inactive from "../../assets/images/icons/approval_flow_icon_active.svg";
import contract_doc_active from "../../assets/images/icons/contract_doc_icon.svg";
import contract_doc_inactive from "../../assets/images/icons/contract_doc_icon_active.svg";
import chat from "../../assets/images/icons/chat.svg";
import chat_active from "../../assets/images/icons/chat_active.svg";
import log from "../../assets/images/icons/sow.svg";
import log_active from "../../assets/images/icons/sow_selected.svg";
import visibility from "../../assets/images/icons/visibility.svg";
import Downloading from "../../assets/images/icons/Downloadimg.svg";
import check_box from "../../assets/images/icons/check_box.svg";
import Group from "../../assets/images/icons/Group.svg";
import UploadButtonIcon from "../../assets/images/icons/upload_icon_blue.svg";
import CompareIcon from "../../assets/images/icons/compare_arrows_blue.svg";
import VersionIcon from "../../assets/images/icons/version_icon.svg";
import dotPointer from "../../assets/images/icons/dot_pointer.svg";
import "../../assets/style/contractfile.css";
import approve from "../../assets/images/icons/approve.svg";
import reject from "../../assets/images/icons/reject.svg";
import { getMetadataApi } from "../../api/contractMetadata";
import { getDagByContractIdApi } from "../../api/dagApi";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  RoleAccessAtom,
  userDataAtom,
  SupplierTreeDataAtom,
} from "../../store/store";
import { onApproveApi, onRejectApi, onReturnApi } from "../../api/workflowApi";
import {
  getSingleContractApi,
  getAggregateValueApi,
  getApproverListFromEmailList,
  getContractParent,
  updateContractDocsApi,
} from "../../api/contractsApi";
import moment from "moment";
import ContractTree from "./ContractTree";
import AggregateValue from "./AggregateValue";
import { airflowUrl } from "../../utils/request";
import { airflowCredential } from "../../utils/common";
import FlowDiagram from "../FlowDiagram";
import { getContractWorkflowApi } from "../../api/contractWorkflowApi";
import { getAllInvoicingProfileApi } from "../../api/invoicingProfileApi";
import { listCostCenter } from "../../api/costCenterApi";
import { getAllPricingProfileApi } from "../../api/pricingProfileApi";
import { getAllFxTableApi } from "../../api/fxTableApi";
import { getSingleSupplierDataApi } from "../../api/suppliersApi";
import {
  ApproveButton,
  RejectButton,
  ReturnButton,
  CancelButton,
  OkButton,
  UploadButton,
  CompareButton,
  SaveButton,
} from "../GlobalButton";
import dayjs from "dayjs";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import arrow_left from "../../assets/images/icons/arrow_left.svg";
import CryptoJS, { kdf } from "crypto-js";
import CompareDocs from "../CompareDocs";
import ReactDiffViewer from "react-diff-viewer-continued";
import { diffLines } from "diff";
import DataTable from "../Tables/DataTable";
import NotesAndComments from "../NotesAndComments";
import {
  deleteS3FileApi,
  getFileApi,
  updateNotesCommentsApi,
} from "../../api/commonApi";
import CommercialTable from "./CommercialTable";

function ContractFile({
  selected,
  pipelinePath,
  backButton,
  finalPath,
  changeHomeTab,
  supplierPipeline,
}) {
  const [selectedFile, setSelectedFile] = useState(selected);
  const { TextArea } = Input;
  // State variable to hold selected file id
  const [dagID, setDagID] = useState();
  // State variable to hold all priceing profile data
  const [metaData, setMetadata] = useState([]);
  // State variable to hold reject modal visibility
  const [rejectModel, setRejectModel] = useState(false);
  // State variable to hold return modal visibility
  const [returnModel, setReturnModel] = useState(false);
  // State variable to hold approval modal visibility
  const [approveModel, setApproveModel] = useState(false);
  // State variable to hold commercial data and description
  const [dataSourceCom, setDataSourceCom] = useState([]);
  // State variable to hold single contract commercial data
  const [getCommercial, setCommercial] = useState([]);
  // State variable to hold button loading visibility
  const [loader, setLoader] = useState(false);
  // Variable to hold roles for logged user
  const roleUser = useRecoilState(RoleAccessAtom);
  // Variable to hold logged user data
  const userData = useRecoilState(userDataAtom);
  const supplierTreeData = useRecoilValue(SupplierTreeDataAtom);
  // State variable to hold aggregate data
  const [contractData, setContractData] = useState({
    contracts: {},
    mode: "view",
    contractType: selectedFile && selectedFile.contract_type,
    startDate: selectedFile && selectedFile.start_date,
    endDate: selectedFile && selectedFile.end_date,
  });
  // State variable to hold workflow data
  const [flowdata, setFlowdata] = useState();

  // uploaded contract file
  const [contractFile, setContractFile] = useState([]);
  // state for store new comments
  const [comments, setComments] = useState([]);
  const [fileKeysToDelete, setFileKeysToDelete] = useState([]);

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [breadcrumbsClick, setBreadcrumbsClick] = useState(false);
  // state varaiable hold the days value
  const [days, setDays] = useState({});
  // state varaiable hold the cost value
  const [cost, setCost] = useState({});
  // state varaible hold the year list object
  const [yearsList, setYearsList] = useState({});
  // state varaiable hold the tabler loader saver
  const [tableLoader, setTableLoader] = useState(true);
  // state variable to check if the logged in user is an approver for contract
  const [isApprover, setIsApprover] = useState(false);
  const [approverID, setApproverID] = useState();
  const [url, setUrl] = useState([
    {
      fileType: "docx",
    },
  ]);
  const [viewedFile, setViewedFile] = useState(false);
  const SECRET_KEY = "mysecretkey";
  // State variable to hold the active inner contract tab key
  const [contractTabKey, setContractTabKey] = useState("1");
  // State variable to hold upload contract document Modal visibility status
  const [uploadContractDoc, setUploadContractDoc] = useState(false);
  const [contractDocData, setContractDocData] = useState([]);
  const [contractNewDocData, setContractNewDocData] = useState([]);
  const [uploadForm] = Form.useForm();
  const [selectedRows, setSelectedRows] = useState(0);
  const [compareModal, setCompareModal] = useState(false);
  const [viewingNotesComments, setViewingNotesComments] = useState(false);
  const [viewingContractDoc, setViewingContractDoc] = useState(false);
  const [notesSaveLoader, setNotesSaveLoader] = useState(false);
  const [contractDocFileList, setContractDocFileList] = useState([]);
  const [disableUpload, setDisableUpload] = useState(true);
  const [newDocFileUpload, setNewDocFileUpload] = useState([]);
  const contractDocDataRef = useRef(contractDocData);
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [splitView, setSplitView] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [highlightLines, setHighlightLines] = useState([]);
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [changedLines, setChangedLines] = useState([]);
  const viewerRef = useRef(null);

  const [commercialRateCard, setCommercialRateCard] = useState();
  const rateCardTemplate = useMemo(() => {
    if (!commercialRateCard || commercialRateCard.length === 0)
      return {
        key: 0,
        "Resource Id": "RE_1",
        "Start Date": undefined,
        "End Date": undefined,
        Days: undefined,
        Cost: undefined,
      };

    return commercialRateCard.reduce(
      (acc, curr) => {
        Object.keys(curr)
          .filter((column) => column !== "Item Id")
          .forEach((key) => {
            acc[key] = undefined;
          });

        return acc;
      },
      {
        key: 0,
        "Resource Id": "RE_1",
        "Start Date": undefined,
        "End Date": undefined,
        Days: undefined,
        Cost: undefined,
      }
    );
  }, [commercialRateCard]);

  const commercialRef = useRef([]);
  const yearsListRef = useRef({});
  const daysRef = useRef({});
  const costsRef = useRef({});
  const contractStartDateRef = useRef(
    selectedFile.start_date ? dayjs(selectedFile.start_date) : undefined
  );
  const contractEndDateRef = useRef(
    selectedFile.end_date ? dayjs(selectedFile.end_date) : undefined
  );

  const viewFile = async (fileData, view_tab, upload_period) => {
    console.log("fileData", fileData);

    const handleOldUploadPeriod = async () => {
      try {
        const res = await getFileApi({
          fileKey: fileData.file_name_on_s3
            ? fileData.file_name_on_s3
            : fileData.file_content,
          flag: "contract",
        });
        console.log("resss", res);
        setUrl([
          {
            uri: res.url,
            fileType: res.fileType,
            fileName: fileData.file_name,
          },
        ]);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    };

    const handleNewUploadPeriod = () => {
      const getDataByViewTab = () => {
        const dataRef =
          view_tab === "contract_doc" && contractDocDataRef.current;

        return dataRef.find((data) =>
          view_tab === "contract_doc"
            ? fileData.file_name === data.file_name
            : fileData.file_name === data.document_name.props.children
        );
      };

      const clickedFile = getDataByViewTab();
      if (clickedFile && clickedFile.file_content) {
        const fileUrl = URL.createObjectURL(clickedFile.file_content);
        const fileName =
          view_tab === "contract_doc"
            ? clickedFile.file_name
            : clickedFile.document_name.props.children;
        const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);

        setUrl([
          {
            uri: fileUrl,
            fileType,
            fileName,
            newUpload: true,
          },
        ]);
      } else {
        console.log("No file content found for the clicked file.");
      }
    };

    if (upload_period === "old") {
      await handleOldUploadPeriod();
    } else if (upload_period === "new") {
      handleNewUploadPeriod();
    }
  };

  useEffect(() => {
    contractDocDataRef.current = contractDocData;
  }, [contractDocData]);

  // console.log("supplierTreeData ******", supplierTreeData);

  const getAllFxTableOnlyApi = async () => {
    try {
      const response = await axios.get("/api/fxProfiles");
      return response.data;
    } catch (error) {
      console.error("Error fetching FX Profiles:", error);
      throw error;
    }
  };

  useEffect(() => {
    // console.log("breadcrumbs", breadcrumbs);
    const encryptedUserData = sessionStorage.getItem("headerKey");
    if (encryptedUserData) {
      const bytes = CryptoJS.AES.decrypt(encryptedUserData, SECRET_KEY);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // console.log("decryptedData", decryptedData);
      // console.log("decryptedData.headerItem", decryptedData.headerItem);
      decryptedData.headerItem === "2" || decryptedData.headerItem === "4"
        ? setBreadcrumbsClick(true)
        : setBreadcrumbsClick(false);
    }
  }, [breadcrumbs]);

  // Function to handle get all api data to set particular state(metaData, priceing profile, supplierName)
  useEffect(() => {
    //For getting breadcrumbs path, we use this to get the Supplier Name
    const getBreadcrumbs = (data, supplierName, contractName) => {
      // console.log("bfeubyu", data);
      let path = [];
      const findInArray = (arr) => {
        for (const item of arr) {
          // console.log("item ,", item);
          // console.log("supplierName", supplierName);
          if (item.supplier_name === supplierName) {
            path.push({
              contract_names: item.supplier_name,
              contract_id: item._id,
              contract_types: item.contract_type,
            });
            return findInChildren(item.children);
          }
        }
        return false;
      };
      const findInChildren = (children) => {
        for (const child of children) {
          // console.log("child ,", child);
          path.push({
            contract_names: child.contract_name,
            contract_id: child._id,
            contract_types: child.contract_type,
          });
          if (
            child.contract_name === contractName ||
            (child.children && findInChildren(child.children))
          ) {
            return true;
          }
          path.pop();
        }
        return false;
      };
      if (!findInArray(data)) {
        return path.concat({ contract_names: contractName });
      }
      return path;
    };
    // console.log("supplierTreeData", supplierTreeData);
    const contractSupplier = supplierTreeData.find(
      (supplier) => supplier._id === selectedFile.supplier_id
    );
    if (!contractSupplier) {
      getSingleSupplierDataApi(selectedFile.supplier_id).then((res) => {
        getContractParent(selectedFile._id).then((resdata) => {
          // console.log("resdata", resdata);
          const supplierName = { contract_names: res.supplier_name };
          const contractNames = resdata.map((parentdata) => ({
            contract_names: parentdata.contract_name,
          }));
          setBreadcrumbs([supplierName, ...contractNames]);
        });
      });
    } else {
      // console.log("supplierTreeData ******", supplierTreeData);
      // console.log("selectedFile.contract_name", selectedFile.contract_name);
      const contractBreadcumbs = getBreadcrumbs(
        supplierPipeline ? supplierPipeline : supplierTreeData,
        contractSupplier.supplier_name,
        selectedFile.contract_name
      );
      setBreadcrumbs(contractBreadcumbs);
    }

    getMetadataApi(selectedFile && selectedFile._id).then((res) => {
      // console.log("response%%%%", res);
      res.metadata_details.financial.map((data, index) => {
        // console.log("data", data);
        if (data === "Invoicing Profile") {
          getAllInvoicingProfileApi().then((resp) => {
            let a = resp.filter(
              (date) =>
                (date._id ? date._id : date.id) ===
                res.metadata_description.financial[index]
            );
            res.metadata_description.financial[index] =
              a[0].invoice_profile_name;
          });
        } else if (data === "Cost Center") {
          listCostCenter().then((resp) => {
            let c = resp.filter(
              (date) =>
                (date.id ? date.id : date._id) ===
                res.metadata_description.financial[index]
            );
            res.metadata_description.financial[index] = c[0].cc_name;
          });
        } else if (data === "Pricing Profile") {
          getAllPricingProfileApi().then((resp) => {
            // console.log("resp", resp);
            // console.log(
            //   "res.metadata_description.financial[index];",
            //   res.metadata_description.financial[index]
            // );
            let c = resp.filter(
              (date) =>
                (date._id ? date._id : date.id) ===
                res.metadata_description.financial[index]
            );
            console.log("c", c);
            res.metadata_description.financial[index] = c[0].price_profile_name;
          });
        } else if (data === "Fx Profile") {
          getAllFxTableApi().then((resp) => {
            // console.log("resp", resp);
            // console.log(
            //   "res.metadata_description.financial[index];",
            //   res.metadata_description.financial[index]
            // );
            let c = resp.filter(
              (date) =>
                (date._id ? date._id : date.id) ===
                res.metadata_description.financial[index]
            );
            // console.log("fx", c);
            res.metadata_description.financial[index] = c[0].fx_table_name;
          });
        }
      });
      setMetadata(res);
    });

    getContractWorkflowApi(selectedFile && selectedFile._id).then((res) => {
      setFlowdata(res.contract_workflow_metadata);
    });

    getAggregateValueApi(
      selectedFile && selectedFile._id,
      selectedFile && selectedFile.contract_type && selectedFile.contract_type
    ).then((res) => {
      setContractData({ ...contractData, contracts: res.docs[0] });
    });

    getSingleContractApi(selectedFile && selectedFile._id).then((res) => {
      if (res) {
        setCommercial(res.docs);
      }
    });

    let ratecardData = [];
    if (
      selectedFile &&
      selectedFile.commercial_data &&
      selectedFile.commercial_data[0] &&
      selectedFile.commercial_data[0].ratetable_data &&
      selectedFile.commercial_data[0].ratetable_data.length !== 0
    ) {
      const keys =
        selectedFile.commercial_data[0].ratetable_data[0].rt_items_desc;
      ratecardData =
        selectedFile.commercial_data[0].ratetable_data[0].rt_items_detail.map(
          (detail) => {
            let dataObj = {};
            for (let i = 0; i < keys.length; i++) {
              dataObj[keys[i]] = detail[i];
            }
            return dataObj;
          }
        );

      console.log("ratecardData: ", ratecardData);
    }

    setCommercialRateCard(ratecardData);
  }, [selectedFile]);

  // Function to get the particular file id to set the tag state value
  useEffect(() => {
    getDagByContractIdApi(selectedFile && selectedFile._id)
      .then((res) => {
        setDagID(res.id);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [selectedFile]);

  useEffect(() => {
    // console.log("selectedFile", selectedFile);
    // console.log(
    //   "Formatted Date:",
    //   moment(selectedFile.updatedAt).format("DD/MM/YYYY")
    // );
    document.title = `${selectedFile.contract_name} | ${
      pipelinePath ? "Contract Pipeline" : "Contract Tree"
    } | Judo`;
  }, [selectedFile]);

  useEffect(() => {
    const hasEmailId = (item) => {
      return item.emailId.includes(userData[0].email);
    };

    const hasNonEmailIds = (emailArray) => {
      return emailArray.some(
        (email) => !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim())
      );
    };

    Object.values((flowdata && flowdata) || {}).some((level) => {
      if (level.status === "Current Approver") {
        level.approverList.every(async (item, key) => {
          // console.log("approverKey", key);
          if (item.status === "Current Approver") {
            if (hasEmailId(item)) {
              setIsApprover(true);
              level.approvalType !== "OR"
                ? setApproverID(level.approverId[key])
                : setApproverID(level.approverId[0]);
            } else if (hasNonEmailIds(item.emailId)) {
              getApproverListFromEmailList(item.emailId).then(
                (approverList) => {
                  approverList.map((user) => {
                    if (user.email === userData[0].email) {
                      setIsApprover(true);
                      level.approvalType !== "OR"
                        ? setApproverID(level.approverId[key])
                        : setApproverID(level.approverId[0]);
                    }
                  });
                }
              );
            }
          }
        });
      }
    });
  }, [flowdata]);

  // function handle the  convert value into number for after days is set
  const convertValuesToNumber = (obj) => {
    for (const year in obj) {
      for (const month in obj[year]) {
        obj[year][month] = Number(obj[year][month]);
      }
    }
    return obj;
  };

  // Fucntion call for once change the commercial data , the commercial data and desc set the column state
  useEffect(() => {
    if (getCommercial && getCommercial.length !== 0) {
      // console.log("getCommercial: ", getCommercial);
      setTableLoader(true);
      let ratecardData = [];
      if (
        getCommercial[0] &&
        getCommercial[0].commercial_data &&
        getCommercial[0].commercial_data.length !== 0 &&
        getCommercial[0].commercial_data[0] &&
        getCommercial[0].commercial_data[0].ratetable_data &&
        getCommercial[0].commercial_data[0].ratetable_data.length !== 0
      ) {
        const keys =
          getCommercial[0].commercial_data[0].ratetable_data[0].rt_items_desc;
        ratecardData =
          getCommercial[0].commercial_data[0].ratetable_data[0].rt_items_detail.map(
            (detail) => {
              let dataObj = {};
              for (let i = 0; i < keys.length; i++) {
                dataObj[keys[i]] = detail[i];
              }
              return dataObj;
            }
          );
      }

      setCommercialRateCard(ratecardData);

      let sowData = [];
      if (
        getCommercial.length !== 0 &&
        getCommercial[0].commercial_data.length !== 0
      ) {
        for (
          let i = 0;
          i < getCommercial[0].commercial_data[0].commercial_details.length;
          i++
        ) {
          let detail =
            getCommercial[0].commercial_data[0].commercial_details[i];
          let dataObj = {
            created_on: getCommercial[0].created_on,
            contract_status: getCommercial[0].contract_status,
          };
          for (
            let j = 0;
            j < getCommercial[0].commercial_data[0].commercial_desc.length;
            j++
          ) {
            dataObj[getCommercial[0].commercial_data[0].commercial_desc[j]] =
              detail[j];
          }
          sowData.push(dataObj);
        }
      }

      let finalSowData = [];
      let initialKeysArr = [];
      sowData &&
        sowData.length !== 0 &&
        sowData.map((t1, index) => {
          initialKeysArr.push(index);
          Object.assign(t1, { key: index });
          t1["Start Date"] = dayjs(t1["Start Date"]);
          t1["End Date"] = dayjs(t1["End Date"]);
          finalSowData.push({
            ...t1,
            ...(ratecardData &&
              ratecardData.length !== 0 &&
              ratecardData.find((t2) => {
                return t2["Item Id"] === t1["RtItem Id"];
              })),
          });
        });

      // Filter out in-active items
      const filteredItems = finalSowData.filter(
        (item) => item.Status !== "In-Active"
      );

      // Group items by Resource Id
      const groupedItems = filteredItems.reduce((groups, item) => {
        const resourceId = item["Resource Id"];
        if (!groups[resourceId]) {
          groups[resourceId] = [];
        }
        groups[resourceId].push(item);
        return groups;
      }, {});

      // Transform grouped items
      let transformedItems = Object.values(groupedItems || {}).map((group) => {
        const resourceId = group[0]["Resource Id"];
        // Find farthest start date and closest end date
        let startDate = group[0]["Start Date"];
        let endDate = group[0]["End Date"];

        for (const item of group) {
          if (item["Start Date"] < startDate) {
            startDate = item["Start Date"];
          }
          if (item["End Date"] > endDate) {
            endDate = item["End Date"];
          }
        }

        // Combine "Days" for each year and month
        const combinedDays = {};
        for (const item of group) {
          const days = item.Days;
          for (const year in days) {
            if (!combinedDays[year]) {
              combinedDays[year] = {};
            }
            for (const month in days[year]) {
              combinedDays[year][month] =
                (combinedDays[year][month] || 0) + parseInt(days[year][month]);
            }
          }
        }

        return {
          ...group[0],
          "Start Date": startDate,
          "End Date": endDate,
          Days: combinedDays,
          "Resource Id": resourceId,
          Status: "Active", // Since we filtered out in-active items
        };
      });

      transformedItems = transformedItems.map((data, index) => {
        return {
          ...data,
          key: index,
        };
      });
      // console.log("transformedItems", transformedItems);

      let initialYearsList = {};
      transformedItems &&
        transformedItems.length !== 0 &&
        transformedItems.map((data) => {
          const startDate = new Date(data["Start Date"]);
          const endDate = new Date(data["End Date"]);
          const startYear = startDate.getFullYear();
          const endYear = endDate.getFullYear();
          let output = {};
          let currentYear = startYear;
          let currentMonth = startDate.getMonth(); // 0 (January) to 11 (December)
          while (
            currentYear < endYear ||
            (currentYear === endYear && currentMonth <= endDate.getMonth())
          ) {
            const monthAbbreviation = dayjs()
              .month(currentMonth)
              .format("MMM")
              .toLowerCase();
            if (!output[currentYear]) {
              output[currentYear] = [];
            }
            output[currentYear].push(monthAbbreviation);
            if (currentMonth === 11) {
              // December, move to next year
              currentMonth = 0;
              currentYear++;
            } else {
              currentMonth++;
            }
          }
          initialYearsList = {
            ...initialYearsList,
            [data["key"]]: output,
          };
        });
      // console.log("initialYearsList", initialYearsList);
      setYearsList(initialYearsList);

      yearsListRef.current = initialYearsList;

      transformedItems &&
        transformedItems.length !== 0 &&
        transformedItems.map((data) => {
          delete data["Item Id"];
          delete data["contract_status"];
        });

      setDataSourceCom(
        transformedItems && transformedItems.length !== 0 && transformedItems
      );

      commercialRef.current =
        transformedItems && transformedItems.length !== 0 && transformedItems;

      setDays({
        ...days,
        ...transformedItems.map(
          (data, index) => (index = convertValuesToNumber(data["Days"]))
        ),
      });

      daysRef.current = {
        ...daysRef.current,
        ...transformedItems.map(
          (data, index) => (index = convertValuesToNumber(data["Days"]))
        ),
      };

      let costObj = {};
      for (const item of transformedItems) {
        const key = item["key"];
        const price = item["Price"];
        const days = item["Days"];

        if (!costObj[key]) {
          costObj[key] = {};
        }
        for (const year in days) {
          if (!costObj[key][year]) {
            costObj[key][year] = {};
          }
          for (const month in days[year]) {
            const daysValue = days[year][month];
            if (!costObj[key][year][month]) {
              costObj[key][year][month] = Number(
                item["Rate Period"]
                  ? item["Rate Period"] !== "Yearly Rate"
                    ? daysValue * price
                    : Number.isInteger((daysValue / 12) * price)
                    ? (daysValue / 12) * price
                    : ((daysValue / 12) * price).toFixed(2)
                  : daysValue * price
              );
            } else {
              costObj[key][year][month] += Number(
                item["Rate Period"]
                  ? item["Rate Period"] !== "Yearly Rate"
                    ? daysValue * price
                    : Number.isInteger((daysValue / 12) * price)
                    ? (daysValue / 12) * price
                    : ((daysValue / 12) * price).toFixed(2)
                  : daysValue * price
              );
            }
          }
        }
      }
      setCost(costObj);

      const updatedCommercialData =
        commercialRef.current &&
        commercialRef.current.map((item) => {
          const costData = costObj[item.key];
          return {
            ...item,
            Cost: costData,
          };
        });

      // Update the commercialRef.current with the added Cost keys for each row
      commercialRef.current = updatedCommercialData;

      costsRef.current = costObj;

      setTableLoader(false);
    }
  }, [getCommercial]);

  const onClickBreadcrumb = (crumb) => {
    if (crumb.contract_types !== "Supplier") {
      getSingleContractApi(crumb.contract_id).then((res) => {
        // console.log("res ***", res);
        setSelectedFile(res.docs[0]);

        // console.log("Selected fileeeeeeeeeeee", res.docs[0]);
      });
    } else if (crumb.contract_types === "Supplier") {
      changeHomeTab();
    }
  };

  // Fucntion call for set document and notes details
  useEffect(() => {
    if (
      selectedFile &&
      selectedFile.contract_doc_data &&
      selectedFile.contract_doc_data.length !== 0
    ) {
      setContractDocData(
        selectedFile.contract_doc_data
          .map((contractDocData, index) => ({
            key: index,
            version: (
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    color: "var(--color-solid-darkerblue)",
                    fontWeight: 600,
                  }}
                >
                  {contractDocData.version}
                </span>
                <Image
                  src={VersionIcon}
                  preview={false}
                  height={20}
                  width={20}
                  style={{ marginTop: -6, marginLeft: 8 }}
                />
              </div>
            ),
            file_content: contractDocData.file_name_on_s3,
            file_name: contractDocData.file_name,
            last_updated_contract: (
              <SwapRightOutlined className="contract-arrow" />
            ),
            updated_on: (
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    fontWeight: 600,
                    color: "var(--color-solid-darkergrey)",
                  }}
                >
                  Updated On&nbsp;:&nbsp;
                </span>
                <span
                  style={{
                    fontWeight: 600,
                    color: "var(--color-solid-darkestblue)",
                  }}
                >
                  {contractDocData.updated_on}
                </span>
              </div>
            ),
            updated_by: (
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    fontWeight: 600,
                    color: "var(--color-solid-darkergrey)",
                  }}
                >
                  Updated By&nbsp;:&nbsp;
                </span>
                <span
                  style={{
                    fontWeight: 600,
                    color: "var(--color-solid-darkestblue)",
                  }}
                >
                  {contractDocData.updated_by}
                </span>
              </div>
            ),
            updated_by_id: contractDocData.updated_by_id,
            action: (
              <span
                style={{
                  fontWeight: 600,
                  color: "var(--color-solid-darkerblue)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  viewFile(contractDocData, "contract_doc", "old");
                  setViewedFile(true);
                }}
              >
                View
              </span>
            ),
          }))
          .reverse()
      );
    }
  }, []);

  /* Fucntion to handle sent the data to reject api
   * @Param values -  form data
   */
  const onReject = async (values) => {
    let data = {
      contract_id: selectedFile._id ? selectedFile._id : selectedFile.id,
      contract_name: selectedFile.contract_name,
      author: userData && userData[0] && userData[0].id,
      author_name: userData && userData[0] && userData[0].first_name,
      comments: comments,
      email: userData && userData[0] && userData[0].email,
      notes: values.description,
      updated_on: new Date(),
    };

    // Prepare form data
    const days = new FormData();
    days.append("file", contractFile && contractFile.originFileObj);
    days.append("days", JSON.stringify(data)); // Attach other form
    // console.log("formdata", days);

    await onRejectApi(days).then((res) => {
      setLoader(false);
      Modal.success({
        title: "Success",
        className: "popup-model-success",
        centered: true,
        icon: false,
        content: <div>Rejected.</div>,
        onOk() {
          setRejectModel(false);
          window.location.reload();
        },
      });
    });
  };

  /* Fucntion to handle sent the data to return api
   * @Param values -  form data
   */
  const onReturn = (values) => {
    let data = {
      contract_id: selectedFile._id ? selectedFile._id : selectedFile.id,
      contract_name: selectedFile.contract_name,
      author: userData && userData[0] && userData[0].id,
      author_name: userData && userData[0] && userData[0].first_name,
      comments: comments,
      email: userData && userData[0] && userData[0].email,
      notes: values.description,
      updated_on: new Date(),
    };

    // Prepare form data
    const days = new FormData();
    days.append("file", contractFile && contractFile.originFileObj);
    days.append("days", JSON.stringify(data)); // Attach other form
    // console.log("formdata", days);

    onReturnApi(days).then((res) => {
      setLoader(false);
      Modal.success({
        title: "Success",
        className: "popup-model-success",
        centered: true,
        icon: false,
        content: <div>Returned.</div>,
        onOk() {
          setReturnModel(false);
          window.location.reload();
        },
      });
    });
  };

  /* Fucntion to handle sent the data to approval api
   * @Param values -  form data
   */
  const onApprove = async (values) => {
    let _dagRunId;
    let data = {
      contract_id: selectedFile._id ? selectedFile._id : selectedFile.id,
      contract_name: selectedFile.contract_name,
      author: userData && userData[0] && userData[0].id,
      author_name: userData && userData[0] && userData[0].first_name,
      comments: comments,
      email: userData && userData[0] && userData[0].email,
      notes: values.description,
      updated_on: new Date(),
    };

    // console.log("contractFile", contractFile);
    // console.log(
    //   "contractFile List",
    //   contractFile && contractFile.map((file) => file.originFileObj)
    // );

    // Prepare form data
    const days = new FormData();
    // days.append(
    //   "file",
    //   contractFile && contractFile.map((file) => file.originFileObj)
    // );
    for (let i = 0; i < contractFile.length; i++) {
      days.append("file", contractFile[i].originFileObj);
    }
    days.append("days", JSON.stringify(data)); // Attach other form
    // console.log("formdata", days);

    await onApproveApi(days).then((res) => {
      setLoader(false);
      Modal.success({
        title: "Success",
        className: "popup-model-success",
        centered: true,
        icon: false,
        content: <div>Approved.</div>,
        onOk() {
          setApproveModel(false);
          window.location.reload();
        },
      });
    });

    // get DagRunId
    await axios
      .get(`${airflowUrl}/dags/${dagID}/dagRuns`, {
        auth: {
          username: airflowCredential.username,
          password: airflowCredential.password,
        },
      })
      .then((res) => {
        let dagRes = res.data.dag_runs;
        let dagrunID = dagRes[dagRes.length - 1];
        _dagRunId = dagrunID.dag_run_id;
      })
      .catch((err) => {
        console.log("err", err);
      });

    // update Dag Taskwait status
    // get approver-email from global state
    // let approver_email = userData && userData[0] && userData[0].email;
    // let approver_email_split = approver_email.split("@")[0];
    // let airflow_task_level = selectedFile.current_owner.substring(11);
    let airflow_task_level = approverID.substring(11);
    // console.log("airflow_task_level", airflow_task_level);
    let _url = `${airflowUrl}/dags/${dagID}/updateTaskInstancesState`;
    let bodyData = {
      dry_run: false,
      task_id: `task_${airflow_task_level}_wait`,
      dag_run_id: _dagRunId,
      include_upstream: false,
      include_downstream: false,
      include_future: false,
      include_past: false,
      new_state: "success",
    };

    await axios
      .post(_url, bodyData, {
        auth: {
          username: airflowCredential.username,
          password: airflowCredential.password,
        },
      })
      .catch((err) => console.log(err));
  };

  /* Function to handle error for if the request fails from onFinished method
   */
  const onFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // State variable to hold  manu name for basic , scope , financial..
  const [menuState, setMenuState] = useState("contract");
  // Statae variable to hold contract and aggregate manu name
  const [commercialMenuState, setCommercialMenuState] = useState("contract");

  // props for antd upload component
  const contractDocProps = {
    name: "file",
    listType: "picture",
    showUploadList: true,
    // accept: ".docx,.doc",
    onChange(info) {
      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          setNewDocFileUpload(info.fileList[0]);
          setDisableUpload(false);
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
    // beforeUpload(file) {
    //   const acceptedFormats = [
    //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //     "application/msword",
    //   ];
    //   const formatCheck = acceptedFormats.includes(file.type);

    //   if (!formatCheck) {
    //     message.error({
    //       content: "You can only upload DOCX or DOC files.",
    //       top: 150,
    //     });
    //     return Upload.LIST_IGNORE;
    //   }

    //   return formatCheck;
    // },
    onRemove() {
      setNewDocFileUpload();
      setDisableUpload(true);
    },
  };

  // req functionality for antd upload component
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  useEffect(() => {
    console.log("viewedFile", viewedFile);
  }, [viewedFile]);

  const versionHistoryColumns = [
    {
      title: "Document Type",
      dataIndex: "document_type",
      key: "document_type",
      ellipsis: true,
      render: () => (
        <p
          style={{
            fontSize: "15px",
            padding: "0px 8px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {" "}
          Commercial <Image Group src={Group} preview={false}></Image>
        </p>
      ),
    },
    {
      title: "Document Name",
      dataIndex: "document_name",
      key: "document_name",
      ellipsis: true,
    },
    {
      title: "Version Number",
      dataIndex: "version_number",
      key: "version_number",
      ellipsis: true,
    },
    {
      title: "Version Date",
      dataIndex: "version_date",
      key: "version_date",
      ellipsis: true,
    },
    {
      title: "Uploaded By",
      dataIndex: "uploaded_by",
      key: "uploaded_by",
      ellipsis: true,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: () => (
        <>
          <div
            style={{
              justifyContent: "space-evenly",
              display: "flex",
            }}
          >
            <Image src={visibility} preview={false} />
            <Image src={Downloading} preview={false} />
            <Image src={check_box} preview={false} />
          </div>
        </>
      ),
    },
  ];

  const onUploadFinish = (values) => {
    console.log("uploadValues: ", values);
    const newKey = contractDocData.length;
    const newVersionNumber =
      contractDocData.length === 0 ? 1 : contractDocData.length + 1;

    let newUpload = {
      key: newKey,
      version: (
        <div style={{ display: "flex" }}>
          <span
            style={{ color: "var(--color-solid-darkerblue)", fontWeight: 600 }}
          >{`Version ${newVersionNumber}`}</span>
          <Image
            src={VersionIcon}
            preview={false}
            height={20}
            width={20}
            style={{ marginTop: -6, marginLeft: 8 }}
          />
        </div>
      ),
      file_content:
        values.uploaded_doc.fileList.length !== 0
          ? values.uploaded_doc.fileList[0].originFileObj
          : "",
      file_name: values.uploaded_doc.file.name,
      last_updated_contract: <SwapRightOutlined className="contract-arrow" />,
      updated_on: (
        <div style={{ display: "flex" }}>
          <span
            style={{ fontWeight: 600, color: "var(--color-solid-darkergrey)" }}
          >
            Updated On&nbsp;:&nbsp;
          </span>
          <span
            style={{ fontWeight: 600, color: "var(--color-solid-darkestblue)" }}
          >{`${dayjs(new Date()).format("DD/MM/YYYY")}`}</span>
        </div>
      ),
      updated_by: (
        <div style={{ display: "flex" }}>
          <span
            style={{ fontWeight: 600, color: "var(--color-solid-darkergrey)" }}
          >
            Updated By&nbsp;:&nbsp;
          </span>
          <span
            style={{ fontWeight: 600, color: "var(--color-solid-darkestblue)" }}
          >{`${userData[0].first_name + " " + userData[0].last_name}`}</span>
        </div>
      ),
      updated_by_id: userData[0].id ? userData[0].id : userData[0]._id,
      action: (
        <span
          style={{
            fontWeight: 600,
            color: "var(--color-solid-darkerblue)",
            cursor: "pointer",
          }}
          onClick={() => {
            viewFile(
              { file_name: values.uploaded_doc.file.name },
              "contract_doc",
              "new"
            );
            // setViewedFile(true);
          }}
        >
          View
        </span>
      ),
    };
    setContractDocData((prev) => [newUpload, ...prev]);
    setContractNewDocData((prev) => [newUpload, ...prev]);
    setContractDocFileList((docFileList) => [...docFileList, newDocFileUpload]);
    setUploadContractDoc(false);
    setDisableUpload(true);
    uploadForm.resetFields();
  };

  const onUploadFinishFailed = (err) => {
    console.log("uploadErr: ", err);
  };

  const updateCommercialData = (
    commercialTableData,
    yearsListData,
    daysData,
    costsData
  ) => {
    commercialRef.current = commercialTableData;
    yearsListRef.current = yearsListData;
    daysRef.current = daysData;
    costsRef.current = costsData;
    // aggregateContractDataRef.current = {
    //   ...aggregateContractDataRef.current,
    //   cost: costsData,
    //   days: daysData,
    // };
    // console.log("commercialRef.current: ", commercialRef.current);
    // console.log("daysRef.current: ", daysRef.current);
    // console.log("costsRef.current: ", costsRef.current);
  };

  // Function to handle contract info tap data
  const contractInfoTab = () => {
    //Menu Items
    const ContractMenuContent = () => {
      const formItemLayout = {
        labelCol: {
          span: 24,
        },
        wrapperCol: {
          span: 20,
        },
      };
      return (
        <div>
          <Row>
            <Col span={24}>
              <div id="view-contract-screen" className="contract-info-view">
                <Form>
                  <Row align="middle" type="flex">
                    {metaData &&
                      metaData.length !== 0 &&
                      metaData.metadata_details.contract.map((data, index) => {
                        return (
                          <Col xs={24} md={24} lg={12} xl={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              labelAlign="left"
                              label={data.toUpperCase()}
                              colon={false}
                              name={data}
                            >
                              <Input
                                defaultValue={
                                  data === "Contract Start Date" ||
                                  data === "Contract End Date"
                                    ? dayjs(
                                        metaData.metadata_description.contract[
                                          index
                                        ]
                                      ).format("DD MMM YYYY")
                                    : metaData.metadata_description.contract[
                                        index
                                      ]
                                }
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        );
                      })}
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      );
    };

    const ScopeMenuContent = () => {
      const formItemLayout = {
        labelCol: {
          span: 24,
        },
        wrapperCol: {
          span: 20,
        },
      };
      return (
        <div>
          <Row>
            <Col span={24}>
              <div id="view-contract-screen" className="contract-info-view">
                <Form>
                  <Row align="middle" type="flex">
                    {metaData &&
                      metaData.length !== 0 &&
                      metaData.metadata_details.scope.map((data, index) => {
                        return (
                          <Col xs={24} md={24} lg={12} xl={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              labelAlign="left"
                              label={data.toUpperCase()}
                              colon={false}
                              name={data}
                            >
                              <Input
                                defaultValue={
                                  metaData.metadata_description.scope[index]
                                }
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        );
                      })}
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      );
    };

    const PeopleMenuContent = () => {
      const formItemLayout = {
        labelCol: {
          span: 24,
        },
        wrapperCol: {
          span: 20,
        },
      };
      return (
        <div>
          <Row>
            <Col span={24}>
              <div id="view-contract-screen" className="contract-info-view">
                <Form>
                  <Row align="middle" type="flex">
                    {metaData &&
                      metaData.length !== 0 &&
                      metaData.metadata_details.people.map((data, index) => {
                        return (
                          <Col xs={24} md={24} lg={12} xl={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              labelAlign="left"
                              label={data.toUpperCase()}
                              colon={false}
                              name={data}
                            >
                              <Input
                                defaultValue={
                                  metaData.metadata_description.people[index]
                                }
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        );
                      })}
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      );
    };

    const FinancialMenuContent = () => {
      const formItemLayout = {
        labelCol: {
          span: 24,
        },
        wrapperCol: {
          span: 20,
        },
      };
      return (
        <div>
          {console.log("metadata", metaData)}
          <Row>
            <Col span={24}>
              <div id="view-contract-screen" className="contract-info-view">
                <Form>
                  <Row align="middle" type="flex">
                    {metaData &&
                      metaData.length !== 0 &&
                      metaData.metadata_details.financial.map((data, index) => {
                        return (
                          <Col xs={24} md={24} lg={12} xl={8}>
                            <Form.Item
                              {...formItemLayout}
                              className="org-supplier-label"
                              labelAlign="left"
                              label={data.toUpperCase()}
                              colon={false}
                              name={data}
                            >
                              <Input
                                defaultValue={
                                  metaData.metadata_description.financial[index]
                                }
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        );
                      })}
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      );
    };

    const ViewLatestContract = () => {
      return (
        <div>
          <Row>
            <Col span={24}>
              <div
              // className="contract-info-view"
              // style={
              //   menuState === "approval"
              //     ? { overflow: "hidden" }
              //     : { overflow: "auto" }
              // }
              >
                <div style={{ padding: "0px 20px" }}>
                  <DocViewer
                    className="doc-viewer"
                    pluginRenderers={DocViewerRenderers}
                    documents={url}
                    style={{ height: 470 }}
                    config={{
                      header: {
                        disableHeader: false,
                        disableFileName: false,
                        retainURLParams: false,
                      },
                      pdfZoom: {
                        defaultZoom: 0.7, // 1 as default,
                      },
                      pdfVerticalScrollByDefault: true, // false as default
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    };

    function getItem(label, key, icon, children, type) {
      return {
        key,
        icon,
        children,
        label,
        type,
      };
    }

    const menuitems = [
      getItem(
        "About the Contract",
        "1",
        menuState === "contract" ? (
          <Image
            src={dotPointer}
            preview={false}
            style={{ paddingRight: 20 }}
          />
        ) : (
          ""
        )
      ),
      getItem(
        "About the Scope",
        "2",
        menuState === "scope" ? (
          <Image
            src={dotPointer}
            preview={false}
            style={{ paddingRight: 20 }}
          />
        ) : (
          ""
        )
      ),
      getItem(
        "About the People",
        "3",
        menuState === "people" ? (
          <Image
            src={dotPointer}
            preview={false}
            style={{ paddingRight: 20 }}
          />
        ) : (
          ""
        )
      ),
      getItem(
        "About the Financials",
        "4",
        menuState === "financial" ? (
          <Image
            src={dotPointer}
            preview={false}
            style={{ paddingRight: 20 }}
          />
        ) : (
          ""
        )
      ),
      getItem(
        "View Latest Contract",
        "5",
        menuState === "view" ? (
          <Image
            src={dotPointer}
            preview={false}
            style={{ paddingRight: 20 }}
          />
        ) : (
          ""
        )
      ),
    ];

    return (
      <div>
        <div className={pipelinePath ? "pipeline-org-view" : "org-view-div"}>
          <div className="m-10">
            <Row type="flex">
              <Col md={4} lg={4} xl={3}>
                <Menu
                  onClick={(key) => {
                    if (key.key === "1") {
                      setMenuState("contract");
                    } else if (key.key === "2") {
                      setMenuState("scope");
                    } else if (key.key === "3") {
                      setMenuState("people");
                    } else if (key.key === "4") {
                      setMenuState("financial");
                    } else if (key.key === "5") {
                      viewFile(contractDocData[0], "contract_doc", "old");
                      setMenuState("view");
                    }
                  }}
                  defaultSelectedKeys={["1"]}
                  mode="vertical"
                  items={menuitems}
                />
              </Col>
              <Col md={20} lg={20} xl={21}>
                {menuState === "contract" ? (
                  <ContractMenuContent />
                ) : menuState === "scope" ? (
                  <ScopeMenuContent />
                ) : menuState === "people" ? (
                  <PeopleMenuContent />
                ) : menuState === "financial" ? (
                  <FinancialMenuContent />
                ) : menuState === "view" ? (
                  <>
                    {contractDocData && contractDocData.length > 0 ? (
                      <ViewLatestContract />
                    ) : (
                      <div className="mt-30">
                        <Empty description="No documents to view" />
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  // Fucntion to handle commercial tap data
  const commercialTab = () => {
    const ContractMenuContent = () => {
      if (commercialRef.current && commercialRef.current.length !== 0) {
        return (
          <div className="m-20">
            <CommercialTable
              state={{ mode: "view" }} // Initialized with a dummy value
              rateCardData={{
                assembledData:
                  commercialRateCard && commercialRateCard.length !== 0
                    ? [...commercialRateCard]
                    : [],
                disassembledData: {
                  keys:
                    getCommercial[0] &&
                    getCommercial[0].commercial_data &&
                    getCommercial[0].commercial_data[0] &&
                    getCommercial[0].commercial_data[0].ratetable_data &&
                    getCommercial[0].commercial_data[0].ratetable_data
                      .length !== 0 &&
                    getCommercial[0].commercial_data[0].ratetable_data[0]
                      .rt_items_desc,
                  values:
                    getCommercial[0] &&
                    getCommercial[0].commercial_data &&
                    getCommercial[0].commercial_data[0] &&
                    getCommercial[0].commercial_data[0].ratetable_data &&
                    getCommercial[0].commercial_data[0].ratetable_data
                      .length !== 0 &&
                    getCommercial[0].commercial_data[0].ratetable_data[0]
                      .rt_items_detail,
                },
              }}
              rateCardTemplate={rateCardTemplate}
              parentCommercialRef={commercialRef}
              parentYearsListRef={yearsListRef}
              parentDaysRef={daysRef}
              parentCostsRef={costsRef}
              contractStartDateRef={contractStartDateRef}
              contractEndDateRef={contractEndDateRef}
              onUpdateCommercialData={updateCommercialData}
            />
          </div>
        );
      } else {
        return (
          <Row>
            <Col span={24}>
              <div className="contract-info-view">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{
                    height: 100,
                  }}
                  description={
                    <span>
                      No Commercial Data for{" "}
                      <span style={{ fontWeight: 600 }}>
                        {selectedFile.contract_name}
                      </span>
                    </span>
                  }
                ></Empty>
              </div>
            </Col>
          </Row>
        );
      }
    };

    const AggregateMenuContent = () => {
      return (
        <div>
          <Row>
            <Col span={24}>
              <div className="contract-info-view">
                <AggregateValue contractData={contractData} />
              </div>
            </Col>
          </Row>
        </div>
      );
    };

    const VersionHistory = () => {
      return (
        <Row className="mt-10">
          <DataTable
            type="view-contract"
            dataTableData={contractDocData}
            colData={versionHistoryColumns}
          />
        </Row>
      );
    };

    function getItem(label, key, icon, children, type) {
      return {
        key,
        icon,
        children,
        label,
        type,
      };
    }

    const menuitems = [
      getItem(
        "Contract Value",
        "1",
        commercialMenuState === "contract" ? (
          <Image
            src={dotPointer}
            preview={false}
            style={{ paddingRight: 20 }}
          />
        ) : (
          ""
        )
      ),
      getItem(
        "Aggregate Value",
        "2",
        commercialMenuState === "aggregate" ? (
          <Image
            src={dotPointer}
            preview={false}
            style={{ paddingRight: 20 }}
          />
        ) : (
          ""
        )
      ),
      getItem(
        "Version History",
        "3",
        commercialMenuState === "version" ? (
          <Image
            src={dotPointer}
            preview={false}
            style={{ paddingRight: 20 }}
          />
        ) : (
          ""
        )
      ),
    ];

    return (
      <div>
        <Row>
          <Col span={24}>
            <div
              className={pipelinePath ? "pipeline-org-view" : "org-view-div"}
            >
              <div className="m-10">
                <Row type="flex">
                  <Col md={4} lg={4} xl={3}>
                    <Menu
                      onClick={(key) => {
                        if (key.key === "1") {
                          setCommercialMenuState("contract");
                        } else if (key.key === "2") {
                          setCommercialMenuState("aggregate");
                        } else if (key.key === "3") {
                          setCommercialMenuState("version");
                        }
                      }}
                      defaultSelectedKeys={["1"]}
                      mode="vertical"
                      items={menuitems}
                    />
                  </Col>
                  <Col md={20} lg={20} xl={21}>
                    {commercialMenuState === "contract" ? (
                      <ContractMenuContent />
                    ) : commercialMenuState === "aggregate" ? (
                      <AggregateMenuContent />
                    ) : commercialMenuState === "version" ? (
                      <VersionHistory />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const approvalFlowChart = () => {
    return (
      <div className={pipelinePath ? "pipeline-org-view" : "org-view-div"}>
        <Card id="flowchart-card" className="setup-header-card2">
          <Row align="middle" type="flex" gutter={[24, 0]} className="m-0">
            <Col className="p-0 vertical-align" span={24}>
              <div className="white-card">
                <div className="flow-chart-div">
                  <FlowDiagram
                    flowData={flowdata && flowdata}
                    from={
                      pipelinePath ? "contract-pipeline-view" : "contract-view"
                    }
                    contract_id={selectedFile._id}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    );
  };

  const contractDocColumns = [
    {
      title: "Version",
      dataIndex: "version",
      ellipsis: true,
      width: 200,
    },
    {
      title: "Last Updated Contract",
      dataIndex: "last_updated_contract",
      ellipsis: true,
      width: 170,
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      ellipsis: true,
      width: 350,
    },
    {
      title: "Updated On",
      dataIndex: "updated_on",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      ellipsis: true,
      width: 100,
    },
  ];

  // rowSelection object indicates the need for Contract Doc row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled:
        selectedRows.length >= 2 &&
        !selectedRows.map((row) => row.key).includes(record.key),
      name: record.name,
    }),
  };

  useEffect(() => {
    console.log("url: ", url);
  }, [url]);

  useEffect(() => {
    // Compute the changed lines
    const diff = diffLines(text1, text2);
    const changes = [];
    let leftLineNumber = 0;
    let rightLineNumber = 0;

    diff.forEach((part) => {
      const lines = part.value.split("\n");
      lines.pop(); // Remove the last empty element due to split on '\n'

      lines.forEach((line, index) => {
        if (part.added || part.removed) {
          changes.push({
            left: part.removed ? leftLineNumber + 1 + index : null,
            right: part.added ? rightLineNumber + 1 + index : null,
          });
        }
      });

      if (!part.added) {
        leftLineNumber += lines.length;
      }
      if (!part.removed) {
        rightLineNumber += lines.length;
      }
    });

    if (changes.length > 0) {
      const firstChange = changes[0];
      const highlights = [];
      if (firstChange.left !== null) highlights.push(`L-${firstChange.left}`);
      if (firstChange.right !== null) highlights.push(`R-${firstChange.right}`);
      setHighlightLines(highlights);
      // setTimeout(() => scrollToHighlight(firstChange), 0);
    }

    setChangedLines(changes);
    if (text1.length !== 0 && text2.length !== 0) {
      const table = viewerRef.current.querySelector("table");
      if (table) {
        const rows = table.querySelectorAll("tr");
        console.log("rows:", rows);
      }
    }
  }, [text1, text2]);

  const handleNext = () => {
    if (currentLineIndex < changedLines.length - 1) {
      const nextIndex = currentLineIndex + 1;
      setCurrentLineIndex(nextIndex);
      const line = changedLines[nextIndex];
      const highlights = [];
      if (line.left !== null) highlights.push(`L-${line.left}`);
      if (line.right !== null) highlights.push(`R-${line.right}`);
      setHighlightLines(highlights);
    }
  };

  const handlePrevious = () => {
    if (currentLineIndex > 0) {
      const prevIndex = currentLineIndex - 1;
      setCurrentLineIndex(prevIndex);
      const line = changedLines[prevIndex];
      const highlights = [];
      if (line.left !== null) highlights.push(`L-${line.left}`);
      if (line.right !== null) highlights.push(`R-${line.right}`);
      setHighlightLines(highlights);
    }
  };

  const scrollToLine = (highlight) => {
    if (viewerRef.current) {
      const table = viewerRef.current.querySelector("table");
      if (table) {
        const rows = Array.from(table.querySelectorAll("tr"));
        rows.shift(); // Remove the first row if it's a header row

        const [side, lineNumber] = highlight[0].split("-");
        const targetLineNumber = parseInt(lineNumber) - 1;

        for (const row of rows) {
          const cells = row.cells;
          let cell;

          if (side === "L") {
            cell = cells[0];
          } else if (side === "R") {
            cell = cells[3];
          }

          if (cell) {
            const pre = cell.childNodes[0];
            if (pre && pre.childNodes[0]) {
              const textContent = pre.childNodes[0].textContent.trim();
              if (parseInt(textContent, 10) === targetLineNumber) {
                row.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
                break;
              }
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (highlightLines.length !== 0) scrollToLine(highlightLines);
  }, [highlightLines]);

  const contractDoc = () => {
    const formItemStyle = {
      border: "1px solid var(--color-solid-blue)",
      borderRadius: 8,
      height: 32,
    };

    const versionContainerStyle = {
      borderRadius: 10,
      height: "calc(100vh - 250px)",
      whiteSpace: "pre-wrap",
      overflowY: "scroll",
    };

    const getVersionNumber = () => {
      return [
        Math.min(
          selectedRows.length !== 0 &&
            selectedRows[0] &&
            selectedRows[0].version.props.children[0].props.children.split(
              " "
            )[1],
          selectedRows.length > 1 &&
            selectedRows[1].version.props.children[0].props.children.split(
              " "
            )[1]
        ),
        Math.max(
          selectedRows.length !== 0 &&
            selectedRows[0] &&
            selectedRows[0].version.props.children[0].props.children.split(
              " "
            )[1],
          selectedRows.length > 1 &&
            selectedRows[1].version.props.children[0].props.children.split(
              " "
            )[1]
        ),
      ];
    };

    const diffViewerStyles = {
      wordAdded: {
        padding: "0px 5px",
        borderRadius: "5px",
      },
      wordRemoved: {
        padding: "0px 5px",
        borderRadius: "5px",
      },
      // highlightedLine: {
      //   background: "rgba(255, 235, 59, 0.3)",
      // },
    };

    return (
      <div className={pipelinePath ? "pipeline-org-view" : "org-view-div"}>
        <div style={{ padding: "0px 20px" }}>
          {!viewedFile ? (
            <>
              <Row className="mt-10 mb-15" justify="end">
                <Col span={3} style={{ marginRight: 10 }}>
                  <CompareButton
                    disabled={selectedRows.length === 2 ? false : true}
                    onClick={() => {
                      setCompareModal(true);
                    }}
                  />
                </Col>
                <Col span={3}>
                  <UploadButton onClick={() => setUploadContractDoc(true)} />
                  <Modal
                    title={
                      <div style={{ display: "flex" }}>
                        <Image
                          src={UploadButtonIcon}
                          preview={false}
                          style={{ paddingRight: 10 }}
                        />
                        <p>Upload Document</p>
                      </div>
                    }
                    width={400}
                    centered
                    open={uploadContractDoc}
                    footer={null}
                    onCancel={() => setUploadContractDoc(false)}
                  >
                    <Form
                      form={uploadForm}
                      className="mt-30 create-role"
                      onFinish={onUploadFinish}
                      onFinishFailed={onUploadFinishFailed}
                      autoComplete="off"
                    >
                      <Row gutter={[16, 8]}>
                        <Col span={24}>
                          <Form.Item
                            name="document_type"
                            label="Document Type"
                            labelCol={{ span: 9 }}
                            labelAlign="left"
                            colon={false}
                          >
                            <Input
                              readOnly
                              style={formItemStyle}
                              defaultValue="Contract"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[16, 8]}>
                        <Col span={24}>
                          <Form.Item
                            name="uploaded_doc"
                            label="Upload Document"
                            labelCol={{ span: 24 }}
                            labelAlign="left"
                            colon={false}
                          >
                            <Upload
                              name="uploaded_document"
                              {...contractDocProps}
                              customRequest={dummyRequest}
                              headers={{
                                "Access-Control-Allow-Origin": "*",
                              }}
                              maxCount={1}
                              className="custom-upload-wrapper"
                            >
                              <Col span={24}>
                                <Button
                                  style={{
                                    ...formItemStyle,
                                    fontSize: 13,
                                    color: "var(--color-solid-darkerblue)",
                                    width: "100%",
                                  }}
                                >
                                  Browse
                                </Button>
                              </Col>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row type="flex" align="middle">
                        <Col
                          span={24}
                          align="middle"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 20,
                          }}
                        >
                          <Col span={8} style={{ marginRight: 5 }}>
                            <CancelButton
                              onClick={() => setUploadContractDoc(false)}
                            />
                          </Col>
                          <Col span={8} style={{ marginLeft: 5 }}>
                            <UploadButton
                              htmlType="submit"
                              disabled={disableUpload}
                            />
                          </Col>
                        </Col>
                      </Row>
                    </Form>
                  </Modal>
                  <Modal
                    title={
                      <>
                        <div style={{ display: "flex" }}>
                          <Image
                            src={CompareIcon}
                            preview={false}
                            style={{ paddingRight: 10 }}
                          />
                          <p>Compare versions of documents</p>
                        </div>
                      </>
                    }
                    width="98%"
                    centered
                    open={compareModal}
                    footer={null}
                    onCancel={() => {
                      setText1("");
                      setText2("");
                      setChangedLines([]);
                      setHighlightLines([]);
                      setCurrentLineIndex(0);
                      setCompareModal(false);
                    }}
                  >
                    <Row
                      type="flex"
                      align="middle"
                      justify="start"
                      className="mb-10"
                      gutter={[0, 0]}
                    >
                      <Col span={9} offset={11}>
                        <div style={{ display: "flex" }}>
                          <Button
                            className="move-up-btn"
                            style={{ marginLeft: "4%", marginRight: 15 }}
                            icon={<CaretLeftOutlined />}
                            onClick={handlePrevious}
                            disabled={currentLineIndex <= 0}
                            title="Highlight Previous Change"
                          />
                          <Button
                            className="move-down-btn"
                            icon={<CaretRightOutlined />}
                            onClick={handleNext}
                            disabled={
                              currentLineIndex >= changedLines.length - 1
                            }
                            title="Highlight Next Change"
                          />
                        </div>
                      </Col>
                      <Col span={2} align="right">
                        <Switch
                          checkedChildren={
                            <p
                              style={{
                                fontWeight: 600,
                                fontSize: 10,
                              }}
                            >
                              Unified View
                            </p>
                          }
                          unCheckedChildren={
                            <p
                              style={{
                                fontWeight: 600,
                                fontSize: 10,
                              }}
                            >
                              Split View
                            </p>
                          }
                          onChange={() => setSplitView(!splitView)}
                          onClick={() => setSplitView(!splitView)}
                        />
                      </Col>
                      <Col span={2} align="right">
                        <Switch
                          checkedChildren={
                            <p style={{ fontWeight: 600, fontSize: 10 }}>
                              Dark Mode
                            </p>
                          }
                          unCheckedChildren={
                            <p style={{ fontWeight: 600, fontSize: 10 }}>
                              Light Mode
                            </p>
                          }
                          onChange={() => setDarkMode(!darkMode)}
                          onClick={() => setDarkMode(!darkMode)}
                        />
                      </Col>
                    </Row>
                    <div
                      style={{
                        border: "1px solid var(--color-solid-darkergrey)",
                        ...versionContainerStyle,
                      }}
                      ref={viewerRef}
                    >
                      {text1.length !== 0 && text2.length !== 0 ? (
                        <ReactDiffViewer
                          styles={diffViewerStyles}
                          oldValue={text1}
                          newValue={text2}
                          splitView={splitView}
                          leftTitle={
                            splitView ? (
                              <p
                                style={{
                                  fontWeight: 600,
                                  fontSize: 13,
                                  paddingLeft: 10,
                                }}
                              >{`Version ${getVersionNumber()[0]}`}</p>
                            ) : (
                              <p
                                style={{
                                  fontWeight: 600,
                                  fontSize: 13,
                                  paddingLeft: 10,
                                }}
                              >{`Unified view of Version ${
                                getVersionNumber()[0]
                              } and Version ${getVersionNumber()[1]}`}</p>
                            )
                          }
                          rightTitle={
                            <p
                              style={{
                                fontWeight: 600,
                                fontSize: 13,
                                paddingLeft: 10,
                              }}
                            >{`Version ${getVersionNumber()[1]}`}</p>
                          }
                          useDarkTheme={darkMode}
                          highlightLines={highlightLines}
                          compareMethod={"diffWords"}
                        />
                      ) : (
                        <Skeleton active paragraph={{ rows: 13 }} />
                      )}
                    </div>
                    <Row
                      type="flex"
                      align="middle"
                      justify="center"
                      className="mt-20"
                    >
                      <Col span={2} style={{ marginLeft: 5 }}>
                        <OkButton
                          onClick={() => {
                            setText1("");
                            setText2("");
                            setChangedLines([]);
                            setHighlightLines([]);
                            setCurrentLineIndex(0);
                            setCompareModal(false);
                          }}
                        />
                      </Col>
                    </Row>
                  </Modal>
                </Col>
              </Row>
              <Row
                style={{
                  borderTop: "2px solid var(--color-solid-lightgrey)",
                }}
              >
                <Col span={24}>
                  <Table
                    pagination={false}
                    showHeader={false}
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                    }}
                    columns={contractDocColumns}
                    dataSource={contractDocData}
                    scroll={{ y: "calc(100vh - 235px)" }}
                  />
                </Col>
              </Row>
            </>
          ) : (
            url.length !== 0 &&
            !url[0].newUpload && (
              <>
                <div
                  className="cursor-pointer m-10"
                  onClick={() => {
                    setViewedFile(false);
                    setUrl([
                      {
                        fileType: "docx",
                      },
                    ]);
                  }}
                >
                  <Image
                    src={arrow_left}
                    style={{ marginLeft: 10, width: 25 }}
                    preview={false}
                  />
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: 13,
                      paddingLeft: 20,
                      color: "var(--color-solid-darkergrey)",
                    }}
                  >
                    &nbsp;{url && url[0] && url[0].fileName}
                  </span>
                </div>
                <div style={{ padding: "0px 20px" }}>
                  <DocViewer
                    className="doc-viewer"
                    pluginRenderers={DocViewerRenderers}
                    documents={url}
                    style={{ height: "calc(100vh - 230px)" }}
                    config={{
                      header: {
                        disableHeader: true,
                        disableFileName: true,
                        retainURLParams: false,
                      },
                      pdfZoom: {
                        defaultZoom: 0.7, // 1 as default,
                      },
                      pdfVerticalScrollByDefault: true, // false as default
                    }}
                  />
                </div>
              </>
            )
          )}
        </div>
      </div>
    );
  };

  // Function to handle the change the comments
  const notesAndComments = () => {
    return (
      <NotesAndComments
        detailData={selectedFile}
        viewedFile={viewedFile}
        comments={comments}
        url={url}
        setUrl={setUrl}
        setFile={setContractFile}
        setFileKeysToDelete={setFileKeysToDelete}
        pipelinePath={pipelinePath}
        setViewedFile={setViewedFile}
        setComments={setComments}
        flag="contract"
      />
    );
  };

  const EntityLog = () => {
    return (
      <div className={pipelinePath ? "pipeline-org-view" : "org-view-div"}>
        <div style={{ padding: "0px 20px" }}>
          <DataTable type="entityLog" logData={selectedFile} />
        </div>
      </div>
    );
  };

  const TabsIcon = (tab) => {
    const TabsIconHelper = (title) => {
      return (
        <div className="tabs-ss-title">
          <img src={title.state.icon} /> <p>{title.state.text}</p>
        </div>
      );
    };
    return (
      <>
        <div className="tabs-title-main">
          {tab.state.selected === "1" ? (
            tab.state.contractTabKey === "1" ? (
              <TabsIconHelper
                state={{ icon: folder_active, text: "Contract Info" }}
              />
            ) : (
              <TabsIconHelper
                state={{ icon: folder_inactive, text: "Contract Info" }}
              />
            )
          ) : tab.state.selected === "2" ? (
            tab.state.contractTabKey === "2" ? (
              <TabsIconHelper
                state={{
                  icon: adminPanel_active,
                  text: "Commercial",
                }}
              />
            ) : (
              <TabsIconHelper
                state={{
                  icon: adminPanel_inactive,
                  text: "Commercial",
                }}
              />
            )
          ) : tab.state.selected === "3" ? (
            tab.state.contractTabKey === "3" ? (
              <TabsIconHelper
                state={{
                  icon: approval_inactive,
                  text: "Approval Flow Chart",
                }}
              />
            ) : (
              <TabsIconHelper
                state={{
                  icon: approval_active,
                  text: "Approval Flow Chart",
                }}
              />
            )
          ) : tab.state.selected === "4" ? (
            tab.state.contractTabKey === "4" ? (
              <TabsIconHelper
                state={{
                  icon: contract_doc_inactive,
                  text: "Contract Doc",
                }}
              />
            ) : (
              <TabsIconHelper
                state={{
                  icon: contract_doc_active,
                  text: "Contract Doc",
                }}
              />
            )
          ) : tab.state.selected === "5" ? (
            tab.state.contractTabKey === "5" ? (
              <TabsIconHelper
                state={{
                  icon: chat_active,
                  text: "Notes And Comments",
                }}
              />
            ) : (
              <TabsIconHelper
                state={{
                  icon: chat,
                  text: "Notes And Comments",
                }}
              />
            )
          ) : tab.state.selected === "6" ? (
            tab.state.contractTabKey === "6" ? (
              <TabsIconHelper
                state={{
                  icon: log_active,
                  text: "Entity Log",
                }}
              />
            ) : (
              <TabsIconHelper
                state={{
                  icon: log,
                  text: "Entity Log",
                }}
              />
            )
          ) : (
            ""
          )}
        </div>
      </>
    );
  };

  // Variable to hold all tap data
  const items = [
    {
      key: "1",
      label: <TabsIcon state={{ contractTabKey, selected: "1" }} />,
      children: contractInfoTab(),
    },
    {
      key: "divider1",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
    },
    {
      key: "2",
      label: <TabsIcon state={{ contractTabKey, selected: "2" }} />,
      children: commercialTab(),
    },
    {
      key: "divider2",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
    },
    {
      key: "3",
      label: <TabsIcon state={{ contractTabKey, selected: "3" }} />,
      children: approvalFlowChart(),
    },
    {
      key: "divider3",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
    },
    {
      key: "4",
      label: <TabsIcon state={{ contractTabKey, selected: "4" }} />,
      children: contractDoc(),
    },
    {
      key: "divider4",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
    },
    {
      key: "5",
      label: <TabsIcon state={{ contractTabKey, selected: "5" }} />,
      children: notesAndComments(),
    },
    {
      key: "divider5",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
    },
    {
      key: "6",
      label: <TabsIcon state={{ contractTabKey, selected: "6" }} />,
      children: EntityLog(),
    },
  ];

  const saveContractDocData = async () => {
    let contractDocDetails =
      contractDocData.length !== 0
        ? contractDocData.map((contractDoc) => {
            return {
              version: contractDoc.version.props.children[0].props.children,
              updated_by:
                contractDoc.updated_by.props.children[1].props.children,
              updated_by_id: contractDoc.updated_by_id,
              updated_on:
                contractDoc.updated_on.props.children[1].props.children,
              file_name: contractDoc.file_name,
            };
          })
        : [];

    let newDocData =
      contractNewDocData.length !== 0
        ? contractNewDocData.map((contractDoc) => {
            return {
              version: contractDoc.version.props.children[0].props.children,
              updated_by:
                contractDoc.updated_by.props.children[1].props.children,
              updated_by_id: contractDoc.updated_by_id,
              updated_on:
                contractDoc.updated_on.props.children[1].props.children,
              file_name: contractDoc.file_name,
            };
          })
        : [];

    let data = {
      contract_doc_details: contractDocDetails,
      contract_doc_data: newDocData,
    };

    console.log("data: ", data);

    // Prepare form data
    const uploadForm = new FormData();
    if (contractDocFileList && contractDocFileList.length > 0) {
      for (let i = 0; i < contractDocFileList.length; i++) {
        uploadForm.append("file", contractDocFileList[i].originFileObj);
      }
    }
    uploadForm.append("uploadForm", JSON.stringify(data));
    console.log("formdata", uploadForm);

    setNotesSaveLoader(true);
    await updateContractDocsApi(selectedFile._id, uploadForm).then((res) => {
      if (res) setNotesSaveLoader(false);
    });
  };

  const saveNotesCommentsData = async () => {
    console.log("notesComments");
    let data = {
      author:
        userData && userData[0] && userData[0].id
          ? userData[0].id
          : userData[0]._id
          ? userData[0]._id
          : "",
      author_name:
        userData && userData[0] && userData[0].first_name
          ? userData[0].first_name
          : "",
      file_notes_data: selectedFile.file_notes_data,
      comments: comments,
    };

    // Prepare form data
    const uploadForm = new FormData();
    if (contractFile && contractFile.length > 0) {
      for (let i = 0; i < contractFile.length; i++) {
        uploadForm.append("file", contractFile[i].originFileObj);
      }
    }
    uploadForm.append("uploadForm", JSON.stringify(data)); // Attach other form
    console.log("formdata", uploadForm);
    setNotesSaveLoader(true);

    if (fileKeysToDelete.length !== 0) {
      fileKeysToDelete.map(async (fileKey) => {
        await deleteS3FileApi({ fileKey: fileKey, flag: "contract" });
      });
    }

    await updateNotesCommentsApi(selectedFile._id, uploadForm, "contract").then(
      (res) => {
        if (res) setNotesSaveLoader(false);
      }
    );
  };

  return (
    <>
      <div className={pipelinePath ? "pipeline-view-div" : "contract-view-div"}>
        <Row
          align="middle"
          type="flex"
          gutter={[4, 16]}
          className="m-0"
          style={{ padding: "0px 15px 0px 15px" }}
        >
          <Col
            className="vertical-align"
            xs={24}
            sm={20}
            md={21}
            lg={21}
            xl={12}
          >
            <Card className="setup-header-card2">
              <Row align="top" type="flex" gutter={[24, 0]} className="m-0">
                <Col
                  className="p-0"
                  xs={24}
                  sm={24}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 24, offset: 0 }}
                  xl={{ span: 24, offset: 0 }}
                >
                  <Row
                    align="middle"
                    type="flex"
                    gutter={[24, 0]}
                    className="m-0"
                  >
                    <Col
                      className="p-0 vertical-align"
                      xs={24}
                      sm={24}
                      md={
                        (selectedFile &&
                          selectedFile.workflowData &&
                          selectedFile.workflowData.length !== 0 &&
                          selectedFile.workflowData[0].current_approver &&
                          selectedFile.workflowData[0].current_approver) ===
                        (userData && userData[0] && userData[0].email)
                          ? 18
                          : 24
                      }
                      lg={
                        (selectedFile &&
                          selectedFile.workflowData &&
                          selectedFile.workflowData.length !== 0 &&
                          selectedFile.workflowData[0].current_approver &&
                          selectedFile.workflowData[0].current_approver) ===
                        (userData && userData[0] && userData[0].email)
                          ? 18
                          : 24
                      }
                      xl={
                        (selectedFile &&
                          selectedFile.workflowData &&
                          selectedFile.workflowData.length !== 0 &&
                          selectedFile.workflowData[0].current_approver &&
                          selectedFile.workflowData[0].current_approver) ===
                        (userData && userData[0] && userData[0].email)
                          ? 24
                          : 24
                      }
                    >
                      <div className="white-card">
                        <Row
                          align="middle"
                          type="flex"
                          gutter={[24, 0]}
                          className="m-0"
                        >
                          <Col
                            className="p-0 vertical-align"
                            xs={24}
                            sm={24}
                            md={
                              (selectedFile &&
                                selectedFile.workflowData &&
                                selectedFile.workflowData.length !== 0 &&
                                selectedFile.workflowData[0].current_approver &&
                                selectedFile.workflowData[0]
                                  .current_approver) ===
                              (userData && userData[0] && userData[0].email)
                                ? 6
                                : 12
                            }
                            lg={
                              (selectedFile &&
                                selectedFile.workflowData &&
                                selectedFile.workflowData.length !== 0 &&
                                selectedFile.workflowData[0].current_approver &&
                                selectedFile.workflowData[0]
                                  .current_approver) ===
                              (userData && userData[0] && userData[0].email)
                                ? 6
                                : 12
                            }
                            xl={
                              (selectedFile &&
                                selectedFile.workflowData &&
                                selectedFile.workflowData.length !== 0 &&
                                selectedFile.workflowData[0].current_approver &&
                                selectedFile.workflowData[0]
                                  .current_approver) ===
                              (userData && userData[0] && userData[0].email)
                                ? 8
                                : 10
                            }
                          >
                            <span
                              className="role-name-text"
                              title={
                                selectedFile &&
                                selectedFile.contract_name &&
                                selectedFile.contract_name
                              }
                            >
                              {selectedFile && selectedFile.contract_name
                                ? selectedFile.contract_name
                                : selectedFile &&
                                  selectedFile[
                                    `${
                                      selectedFile &&
                                      selectedFile.contract_type &&
                                      selectedFile.contract_type.toLowerCase()
                                    }_name`
                                  ]}
                            </span>
                          </Col>
                          <Col
                            className="p-0 vertical-align"
                            xs={24}
                            sm={24}
                            md={
                              (selectedFile &&
                                selectedFile.workflowData &&
                                selectedFile.workflowData.length !== 0 &&
                                selectedFile.workflowData[0].current_approver &&
                                selectedFile.workflowData[0]
                                  .current_approver) ===
                              (userData && userData[0] && userData[0].email)
                                ? 18
                                : 12
                            }
                            lg={
                              (selectedFile &&
                                selectedFile.workflowData &&
                                selectedFile.workflowData.length !== 0 &&
                                selectedFile.workflowData[0].current_approver &&
                                selectedFile.workflowData[0]
                                  .current_approver) ===
                              (userData && userData[0] && userData[0].email)
                                ? 18
                                : 12
                            }
                            xl={
                              (selectedFile &&
                                selectedFile.workflowData &&
                                selectedFile.workflowData.length !== 0 &&
                                selectedFile.workflowData[0].current_approver &&
                                selectedFile.workflowData[0]
                                  .current_approver) ===
                              (userData && userData[0] && userData[0].email)
                                ? 16
                                : 14
                            }
                          >
                            <Row
                              align="middle"
                              type="flex"
                              gutter={[24, 0]}
                              className="m-0"
                            >
                              <Col
                                className="p-0 vertical-align text-center"
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <span id="contract-header-text">
                                  Last Update On: &nbsp;&nbsp;
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      color: "#000",
                                    }}
                                    title={
                                      selectedFile &&
                                      selectedFile.workflowData &&
                                      selectedFile.workflowData.length !== 0
                                        ? moment(
                                            selectedFile &&
                                              selectedFile.workflowData[0]
                                                .updated_on
                                          ).format("DD/MM/YYYY")
                                        : moment(
                                            selectedFile &&
                                              selectedFile.last_status_change_date
                                          ).format("DD/MM/YYYY")
                                    }
                                  >
                                    {selectedFile &&
                                    selectedFile.workflowData &&
                                    selectedFile.workflowData.length !== 0
                                      ? moment(
                                          selectedFile &&
                                            selectedFile.workflowData[0]
                                              .updated_on
                                        ).format("DD/MM/YYYY")
                                      : moment(
                                          selectedFile &&
                                            selectedFile.last_status_change_date
                                        ).format("DD/MM/YYYY")}
                                  </span>
                                </span>
                              </Col>
                              <Col
                                className="p-0 vertical-align text-end"
                                xs={24}
                                sm={24}
                                md={12}
                                lg={12}
                                xl={12}
                              >
                                <span id="contract-header-text">
                                  Status:&nbsp;&nbsp;
                                  <span
                                    style={{
                                      fontWeight: 600,
                                      color: "var(--color-solid-yellow)",
                                    }}
                                    title={
                                      selectedFile &&
                                      selectedFile.contract_status
                                    }
                                  >
                                    {selectedFile &&
                                      selectedFile.contract_status}
                                  </span>
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col
            className="vertical-align"
            xs={24}
            sm={20}
            md={21}
            lg={21}
            xl={12}
          >
            <Card className="setup-header-card">
              <Row type="flex" gutter={[8, 16]}>
                <Col
                  className="vertical-align"
                  xs={6}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={2}
                >
                  <Row type="flex" gutter={[16, 16]}>
                    <Col span={12}>
                      <Image
                        src={account_tree}
                        onClick={() => {
                          pipelinePath != undefined ? (
                            backButton()
                          ) : (
                            <ContractTree />
                          );
                        }}
                        style={{ width: 20 }}
                        preview={false}
                        className="cursor-pointer"
                      />
                    </Col>
                    <Col span={12}>
                      <span className="path-icon">
                        <RightOutlined />
                      </span>
                    </Col>
                  </Row>
                </Col>
                {breadcrumbsClick ? (
                  <Col xl={22} align="left">
                    <span
                      className="contract-breadcrumb"
                      title={"Suppliers > " + breadcrumbs.join(" > ")}
                    >
                      <span
                        className="path-text-pipeline"
                        style={{ cursor: "not-allowed" }}
                        // onClick={() => changeHomeTab()}
                      >
                        Suppliers
                      </span>
                      {breadcrumbs.length !== 0 ? (
                        breadcrumbs.map((crumb) => {
                          return (
                            <>
                              <span className="path-icon">
                                <RightOutlined />
                              </span>
                              <span
                                className="path-text-pipeline"
                                style={{ cursor: "not-allowed" }}
                                key={crumb.id}
                                disabled={breadcrumbsClick}
                                // onClick={() => onClickBreadcrumb(crumb)}
                              >
                                {crumb.contract_names}
                              </span>
                            </>
                          );
                        })
                      ) : (
                        <Skeleton.Input active={true} size={"small"} />
                      )}
                    </span>
                  </Col>
                ) : (
                  <Col xl={22} align="left">
                    <span
                      className="contract-breadcrumb"
                      title={"Suppliers > " + breadcrumbs.join(" > ")}
                    >
                      <span
                        className="path-text-pipeline"
                        onClick={() => changeHomeTab()}
                      >
                        Suppliers
                      </span>
                      {breadcrumbs.length !== 0 ? (
                        breadcrumbs.map((crumb) => {
                          return (
                            <>
                              <span className="path-icon">
                                <RightOutlined />
                              </span>
                              <span
                                className="path-text-pipeline"
                                key={crumb.id}
                                onClick={() => onClickBreadcrumb(crumb)}
                              >
                                {crumb.contract_names}
                              </span>
                            </>
                          );
                        })
                      ) : (
                        <Skeleton.Input active={true} size={"small"} />
                      )}
                    </span>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
        <Row type="flex" gutter={[14, 0]} className="mt-5 m-10">
          <Col span={24}>
            {isApprover ? (
              <Tabs
                id="contree-tab"
                className={
                  pipelinePath ? "pipeline-tree-view" : "contract-tree-view"
                }
                defaultActiveKey="1"
                onChange={(key) => setContractTabKey(key)}
                items={items}
                tabBarExtraContent={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: -6,
                    }}
                  >
                    {isApprover && (
                      <Col span={12}>
                        <RejectButton
                          type="primary"
                          htmlType="submit"
                          onClick={() => {
                            setRejectModel(true);
                          }}
                          // loading={loader}
                          disabled={!contractFile}
                        />
                      </Col>
                    )}
                    {isApprover && (
                      <Col span={12}>
                        <ReturnButton
                          type="primary"
                          htmlType="submit"
                          // loading={loader}
                          onClick={() => {
                            setReturnModel(true);
                          }}
                          disabled={!contractFile}
                        />
                      </Col>
                    )}
                    {isApprover && (
                      <Col span={12}>
                        <ApproveButton
                          type="primary"
                          htmlType="submit"
                          // loading={loader}
                          onClick={() => {
                            setApproveModel(true);
                          }}
                          disabled={!contractFile}
                        />
                      </Col>
                    )}

                    {returnModel && (
                      <Modal
                        title={
                          <span>
                            <Image src={reject} preview={false} /> &nbsp;&nbsp;
                            <span style={{ fontSize: "17px" }}>
                              Confirmation for Return
                            </span>
                          </span>
                        }
                        centered
                        open={returnModel}
                        onOk={() => setReturnModel(false)}
                        onCancel={() => setReturnModel(false)}
                        footer={null}
                      >
                        <div>
                          <Form
                            className="mt-10 create-role"
                            onFinish={onReturn}
                            onFinishFailed={onFailed}
                            autoComplete="off"
                          >
                            <Row gutter={[16, 8]}>
                              <Col span={24} className="mt-20">
                                <Form.Item
                                  name="description"
                                  labelCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter description!",
                                    },
                                  ]}
                                >
                                  <TextArea
                                    placeholder="description"
                                    rows={2}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row type="flex" align="middle">
                              <Col
                                span={24}
                                align="middle"
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "end",
                                  marginTop: 20,
                                }}
                              >
                                <Col span={6} style={{ marginRight: 5 }}>
                                  <CancelButton
                                    onClick={() => setReturnModel(false)}
                                  />
                                </Col>
                                <Col span={6} style={{ marginLeft: 5 }}>
                                  <ReturnButton
                                    loading={loader}
                                    htmlType="submit"
                                  />
                                </Col>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Modal>
                    )}
                    {rejectModel && (
                      <Modal
                        title={
                          <span>
                            <Image src={reject} preview={false} /> &nbsp;&nbsp;
                            <span style={{ fontSize: "17px" }}>
                              Confirmation for Reject
                            </span>
                          </span>
                        }
                        centered
                        open={rejectModel}
                        onOk={() => setRejectModel(false)}
                        onCancel={() => setRejectModel(false)}
                        footer={null}
                      >
                        <div>
                          <Form
                            className="mt-10 create-role"
                            onFinish={onReject}
                            onFinishFailed={onFailed}
                            autoComplete="off"
                          >
                            <Row gutter={[16, 8]}>
                              <Col span={24} className="mt-20">
                                <Form.Item
                                  name="description"
                                  labelCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter description!",
                                    },
                                  ]}
                                >
                                  <TextArea
                                    placeholder="description"
                                    rows={2}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row type="flex" align="middle">
                              <Col
                                span={24}
                                align="middle"
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "end",
                                  marginTop: 20,
                                }}
                              >
                                <Col span={6} style={{ marginRight: 5 }}>
                                  <CancelButton
                                    onClick={() => setRejectModel(false)}
                                  />
                                </Col>
                                <Col span={6} style={{ marginLeft: 5 }}>
                                  <RejectButton
                                    htmlType="submit"
                                    loading={loader}
                                  />
                                </Col>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Modal>
                    )}
                    {approveModel && (
                      <Modal
                        title={
                          <span>
                            <Image src={approve} preview={false} /> &nbsp;&nbsp;
                            <span style={{ fontSize: "17px" }}>
                              Confirmation for Approve
                            </span>
                          </span>
                        }
                        centered
                        open={approveModel}
                        onOk={() => setApproveModel(false)}
                        onCancel={() => setApproveModel(false)}
                        footer={null}
                      >
                        <div>
                          <Form
                            className="mt-10 create-role"
                            onFinish={onApprove}
                            onFinishFailed={onFailed}
                            autoComplete="off"
                          >
                            <Row gutter={[16, 8]}>
                              <Col span={24} className="mt-20">
                                <Form.Item
                                  name="description"
                                  // label="Description"
                                  labelCol={{ span: 24 }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please enter description!",
                                    },
                                  ]}
                                >
                                  <TextArea
                                    placeholder="description"
                                    rows={2}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row type="flex" align="middle">
                              <Col
                                span={24}
                                align="middle"
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  alignItems: "end",
                                  marginTop: 20,
                                }}
                              >
                                <Col span={6} style={{ marginRight: 5 }}>
                                  <CancelButton
                                    onClick={() => setApproveModel(false)}
                                  />
                                </Col>
                                <Col span={6} style={{ marginLeft: 5 }}>
                                  <ApproveButton
                                    htmlType="submit"
                                    loading={loader}
                                  />
                                </Col>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Modal>
                    )}
                  </div>
                }
              />
            ) : (
              <Tabs
                id="contree-tab"
                className={
                  pipelinePath ? "pipeline-tree-view" : "contract-tree-view"
                }
                defaultActiveKey="1"
                onChange={(key) => {
                  if (key === "4") {
                    setViewingContractDoc(true);
                  } else if (key === "5") {
                    setViewingNotesComments(true);
                  } else {
                    setViewingContractDoc(false);
                    setViewingNotesComments(false);
                  }
                  setContractTabKey(key);
                }}
                items={items}
                tabBarExtraContent={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: -6,
                    }}
                  >
                    {(viewingContractDoc || viewingNotesComments) && (
                      <SaveButton
                        loading={notesSaveLoader}
                        onClick={() => {
                          if (contractTabKey === "4") saveContractDocData();
                          else if (contractTabKey === "5")
                            saveNotesCommentsData();
                        }}
                        style={{ width: 140 }}
                      />
                    )}
                  </div>
                }
              />
            )}
          </Col>
        </Row>
      </div>

      {compareModal && (
        <CompareDocs
          oldVersion={
            selectedRows.reduce(
              (min, row) => (row.key < min.key ? row : min),
              selectedRows[0]
            ).file_content
          }
          newVersion={
            selectedRows.reduce(
              (max, row) => (row.key > max.key ? row : max),
              selectedRows[0]
            ).file_content
          }
          setText1={setText1}
          setText2={setText2}
        />
      )}
    </>
  );
}

export default ContractFile;
