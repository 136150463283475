import React, { useEffect, useState } from "react";
import {
  Image,
  Row,
  Col,
  Table,
  Radio,
  Modal,
  Input,
  Form,
  Layout,
  Tabs,
} from "antd"; //antd components
import moment from "moment";
import { RightOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import arrow_left from "../../assets/images/icons/arrow_left.svg";
import "../../assets/style/contractpipeline.css";
import "../../assets/style/setup.css";
import { removeUserFromGroupAPi } from "../../api/groupsApi";
import TopHeader from "../../components/Header";
import { createActivityLogApi } from "../../api/logApi";
import { userDataAtom, SupplierTreeDataAtom } from "../../store/store";
import { HeaderChangerAtom } from "../../store/store";
import PopupModal from "../../PopupModal";
import { getGroupByUser } from "../../api/groupsApi";
import { gettRootActionByUser } from "../../api/actionManagementApi";
import { getWorkflowByUser } from "../../api/workflowApi";
import { getContractByUser } from "../../api/contractsApi";
import ContractFile from "../Contract/ContractFile";
import { dataTable } from "../../utils/common";
import { associationCol } from "../../utils/tableColumns";
import HeaderCard from "../HeaderCard";
import DataTable from "../Tables/DataTable";

function ViewGroup() {
  const supplierTreeData = useRecoilValue(SupplierTreeDataAtom); //supplier tree data from global state
  // Variable to hold login user data
  const userData = useRecoilState(userDataAtom);
  // State variable to hold header data from global
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // Variable to hold header local value
  let header = headerChanger.header;
  // Variable to hold use to navigate the another page
  const navigate = useNavigate();
  // Variable to hold selected group data
  let { state } = useLocation();
  console.log("state: ", state);
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  const [selectedContract, setSelectedContract] = useState();
  // State variable to hold contract id and path
  const [finalPath, setFinalPath] = useState([]);
  // State variable to hold selected single group detail data
  const [tableData, setTableData] = useState([]);
  // State variable to hold selected single group data
  const [groupMembers, setGroupMembers] = useState([]);
  // State variable to hold add user model visibility status
  const [addUser, openAddUser] = useState(false);
  // State variable to hold delete model visiblity status
  const [deleteModel, openDeleteModel] = useState(false);
  // State variable to hold button loading visibility status
  const [loader, setLoader] = useState(false);
  // State variable to hold user enter the email id
  const [userEmail, setUserEmail] = useState("");
  // State avriable to hold sort order column data
  const [sortOrder, setSortOrder] = useState(null);
  // State avriable to hold sort order column index
  const [sortedColumn, setSortedColumn] = useState(null);
  // State variable to hold PopupModal component
  const popupModalInstance = PopupModal();
  // State variable to hold Group's status
  const [groupStatus, setGroupStatus] = useState(state.group_status);
  // State variable to hold Association data of this group
  const [associationData, setAssociationData] = useState([]);

  // Function to filter out duplicate objects based on a unique identifier
  const filterDuplicates = (array, type) => {
    const uniqueObjects = new Map();

    if (type === "normal") {
      array.forEach((item) => {
        const key = item.email;

        if (!uniqueObjects.has(key)) {
          uniqueObjects.set(key, item);
        }
      });

      return Array.from(uniqueObjects.values());
    } else if (type === "nested") {
      array.forEach((item) => {
        const key = item.lead[0].email;
        if (!uniqueObjects.has(key)) {
          uniqueObjects.set(key, item);
        }
      });

      return Array.from(uniqueObjects.values());
    }
  };

  // Function to handle call the groupMembersTableData when enter the page
  useEffect(() => {
    if (state.group_selection === "Departmental Group") {
      state.leads.forEach((leadObj) => {
        leadObj.lead = filterDuplicates(leadObj.lead, "normal");
      });

      if (state.leads[0] && state.leads[0].lead.length !== 0) {
        state.leads = filterDuplicates(state.leads, "nested");
      }
    }
    groupMembersTableData(state);
  }, [state]);

  // Variable to hold column data
  const isColumnSorted = (column) => sortedColumn === column;
  //  Function to handle sorting of table columns
  const handleTableChange = (_, __, sorter) => {
    if (sorter.column) {
      setSortedColumn(sorter.column.dataIndex);
      setSortOrder(sorter.order);
    } else {
      setSortedColumn(null);
      setSortOrder(null);
    }
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  // Variable to hold all column data
  const groupMemberColumns = [
    {
      title: renderColumnTitle("User Name", "user_name"),
      dataIndex: "user_name",
      align: "left",
      sorter: (a, b) => a.user_name.localeCompare(b.user_name),
      sortOrder: isColumnSorted("user_name") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Email", "email"),
      dataIndex: "email",
      align: "left",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortOrder: isColumnSorted("email") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Role", "role"),
      dataIndex: "role",
      align: "left",
      sorter: (a, b) => a.role.localeCompare(b.role),
      sortOrder: isColumnSorted("role") ? sortOrder : false,
    },
  ];

  /* Funtion to handle delete the user from group
   * @Param value - particular user data from the group
   */
  const deleteUser = (value) => {
    let data = {
      group_member: value.group_member,
      updated_by: userData[0].id,
      updated_on: new Date(),
    };
    if (value.group_member === userEmail) {
      removeUserFromGroupAPi(state._id, data).then((res) => {
        console.log("removeUserFromGroupAPi", res);
        let umLog = {
          ss_id: res.id ? res.id : res._id,
          ss_name: res.group_name,
          code: "groups",
          author: userData[0].id,
          action: "delete",
          notes: "Deleted User from Group",
          updated_on: new Date(),
        };
        createActivityLogApi(umLog).then((res) => {
          console.log("LogRes: ", res);
        });
        openDeleteModel(false);
        setLoader(false);
        Modal.success({
          className: "popup-model-success",
          centered: true,
          icon: false,
          content: <div>User Removed from this Group.</div>,
          onOk() {
            //  navigate(`/settings/?back=set-roles`);
            groupMembersTableData(state);
          },
        });
      });
    } else {
      Modal.warning({
        className: "popup-model-success",
        centered: true,
        icon: false,
        content: <div>User Email is Not Matched.</div>,
      });
    }
  };
  // Function to handle get the particular group detail and
  const groupMembersTableData = (rawData) => {
    const departmentMembers =
      rawData.department_members &&
      rawData.department_members.length !== 0 &&
      rawData.department_members[0].length !== 0
        ? rawData.department_members.map((member, index) => ({
            key: `department_member_${index}`,
            user_name: member[0].first_name + " " + member[0].last_name,
            email: member[0].email,
            role: "Department Member",
          }))
        : [];

    const leadChildren =
      rawData.leads &&
      rawData.leads.length !== 0 &&
      rawData.leads[0].lead.length !== 0
        ? rawData.leads.map((leadObj, index) => ({
            key: `lead_${index}`,
            user_name:
              leadObj.lead[0].first_name + " " + leadObj.lead[0].last_name,
            email: leadObj.lead[0].email,
            role: "Lead",
            children: leadObj.lead_members.map((lead, index) => ({
              key: `${lead.first_name + lead.last_name}_lead_members_${index}`,
              user_name: lead.first_name + " " + lead.last_name,
              email: lead.email,
              role: "Lead Member",
            })),
          }))
        : [];

    const children = departmentMembers.concat(leadChildren);

    let memberData =
      rawData.group_selection === "Functional Group"
        ? rawData.group_members.map((data, index) => ({
            key: index + 1,
            user_name: data[0].first_name + " " + data[0].last_name,
            email: data[0].email,
            role: data[0].roleDetail[0].role_name,
          }))
        : rawData.group_selection === "Departmental Group"
        ? [
            {
              key: "department_head",
              user_name:
                rawData.department_head[0].first_name +
                " " +
                rawData.department_head[0].last_name,
              email: rawData.department_head[0].email,
              role: "Department Head",
              children: children,
            },
          ]
        : [];

    setTableData(memberData);
  };

  const [responseData, setResponseData] = useState([]); //tree data
  useEffect(() => {
    // set supplier tree data from global state
    if (supplierTreeData.length) {
      setResponseData([...supplierTreeData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierTreeData]);

  // secondary function for set path of selected node
  const getPath = (model, id) => {
    let path,
      item = {
        id: model.id,
        name: model.contract_name
          ? model.contract_name
          : model[`${model.contract_type.toLowerCase()}_name`],
      };
    if (!model || typeof model !== "object") return;
    if (model.id === id) return [item];
    (model.children || []).some((child) => (path = getPath(child, id)));
    return path && [item, ...path];
  };

  const getTopPath = (contract) => {
    supplierTreeData.some((res) => {
      const pathVal = getPath(res, contract._id);
      if (pathVal) {
        setFinalPath([...pathVal]);
        return pathVal;
      }
      return false;
    });
    setSelectedContract(contract);
  };

  // Function call when click the back button
  const backButton = () => {
    setSelectedContract();
    setHeaderChanger({
      header: "systemSetup",
      headerItem: "2",
      headerItemName: "systemSetup",
    });
  };

  const changeHomeTab = () => {
    backButton();
  };

  useEffect(() => {
    let allAssociations = [];
    getGroupByUser(state.email).then((res) => {
      console.log("group res", res);
      res.map((data) => {
        let tableRow = {
          associated_item: "Group",
          name: (
            <span
              onClick={() => {
                setHeaderChanger({
                  header: "userManagement",
                  headerItem: "3",
                  headerItemName: "userManagement",
                  stateSetter: data,
                });
                navigate(`/user-management/groups/view`, {
                  state: {
                    groupId: data._id ? data._id : data.id,
                    groupName: data.group_name,
                  },
                });
              }}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {data.group_name}
            </span>
          ),
          association_date: moment(data.created_on).format("DD-MMM-YY"),
        };
        allAssociations.push(tableRow);
      });
    });
    gettRootActionByUser(state._id).then((res) => {
      console.log("action res", res);
      res.map((data) => {
        let tableRow = {
          associated_item: "Root Action",
          name: (
            <span
              onClick={() => {
                let finalPathOnModify = [
                  { id: "home", name: "home", type: "" },
                ];
                responseData.some((res) => {
                  const pathVal = getPath(res, data._id);
                  if (pathVal) {
                    finalPathOnModify = [...finalPathOnModify, ...pathVal];
                  }
                });
                setHeaderChanger({
                  header: "actionManagement",
                  headerItem: "1",
                  headerItemName: "actionManagement",
                  stateSetter: {
                    actionData: data,
                    finalPath: finalPathOnModify,
                  },
                });
                navigate(`/action-management/view-action`, {
                  state: { actionData: data, finalPath: finalPathOnModify },
                });
              }}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {data.action_name}
            </span>
          ),
          association_date: moment(data.created_on).format("DD-MMM-YY"),
        };
        allAssociations.push(tableRow);
      });
    });
    getContractByUser(state._id).then((res) => {
      console.log("contract res", res);
      res.map((data) => {
        let tableRow = {
          associated_item: "Contract",
          name: (
            <span
              onClick={() => {
                setHeaderChanger({
                  header: "contract",
                  headerItem: "2",
                  headerItemName: "contract",
                  stateSetter: data,
                });
                getTopPath(data);
              }}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {data.contract_name}
            </span>
          ),
          association_date: moment(data.createdAt).format("DD-MMM-YY"),
        };
        allAssociations.push(tableRow);
      });
    });
    getWorkflowByUser(state._id).then((res) => {
      console.log("wf res", res);
      res.map((data) => {
        let tableRow = {
          associated_item: "Workflow",
          name: (
            <span
              onClick={() => {
                setHeaderChanger({
                  header: "systemSetup",
                  headerItem: "2",
                  headerItemName: "systemSetup",
                  stateSetter: data,
                });
                navigate(`/contract-setup/view/workflow`, {
                  state: data,
                });
              }}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {data.workflow_name}
            </span>
          ),
          association_date: moment(data.createdAt).format("DD-MMM-YY"),
        };
        allAssociations.push(tableRow);
      });
    });
    setAssociationData(allAssociations);
  }, [state]);

  // const handleRadioClick = (value) => {
  //   console.log("status checked", value);
  //   if (
  //     value !== "Active" &&
  //     state.group_status === "Active" &&
  //     associationData.length > 0
  //   ) {
  //     popupModalInstance
  //       .infoModal(ModalMessage.User_Status_Change)
  //       .then(async (userChoice) => {
  //         console.log("userChoice", userChoice);
  //         if (userChoice === "ok") {
  //           setGroupStatus(value);
  //           state.group_status !== value
  //             ? setDisable("")
  //             : setDisable(disableVal);
  //         }
  //       });
  //   } else {
  //     popupModalInstance
  //       .infoModal(ModalMessage.User_Status_Change_Confirm)
  //       .then(async (userChoice) => {
  //         console.log("userChoice", userChoice);
  //         if (userChoice === "ok") {
  //           setGroupStatus(value);
  //           state.group_status !== value
  //             ? setDisable("")
  //             : setDisable(disableVal);
  //         }
  //       });
  //   }
  // };

  const GroupDetails = () => {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 18,
      },
    };
    return (
      <div className="org-view-div">
        <div className="m-20">
          <Form layout="vertical">
            <Row align="middle" type="flex">
              <Col span={12}>
                <Form.Item
                  {...formItemLayout}
                  className="org-supplier-label"
                  label="GROUP NAME"
                  colon={false}
                >
                  <Input disabled value={state.group_name} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  {...formItemLayout}
                  className="org-supplier-label"
                  label="GROUP STATUS"
                  colon={false}
                >
                  <div className="association-radio-status">
                    <Radio.Group buttonStyle="solid" value={groupStatus}>
                      <Radio value={"Active"}>Active</Radio>
                      <Radio value={"Suspended"}>Suspended</Radio>
                      <Radio value={"Terminated"}>Terminated</Radio>
                    </Radio.Group>
                  </div>
                </Form.Item>
              </Col>
              {tableData && tableData.length !== 0 && (
                <DataTable
                  type="group-member-view"
                  dataTableData={tableData}
                  colData={groupMemberColumns}
                  showSorterTooltip={false}
                  onChange={handleTableChange}
                  expandable={{ defaultExpandAllRows: true }}
                />
              )}
            </Row>
          </Form>
        </div>
      </div>
    );
  };

  const Associations = () => {
    return <DataTable type="association" dataTableData={associationData} />;
  };

  const items = [
    {
      key: "1",
      label: `Group Details`,
      children: GroupDetails(),
    },
    {
      key: "2",
      label: `Associations`,
      children: Associations(),
    },
  ];

  return (
    <Layout>
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        sticky=""
      />
      {!selectedContract && (
        <div className="system-setup1">
          <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
            <Col
              className="vertical-align"
              xs={24}
              sm={24}
              md={17}
              lg={17}
              xl={24}
            >
              <HeaderCard
                mainMenu="User Management"
                subMenu="Groups"
                itemName={state.group_name}
                // itemStatus={state.status}
                goBackLink="/user-management/?back=set-groups"
                // state={""}
                // showDetails={"create"}
              />
            </Col>
            {/* <Col
              className="vertical-align"
              xs={24}
              sm={24}
              md={1}
              lg={1}
              xl={2}
            >
              <AddUserButton type="primary" onClick={() => openAddUser(true)} />

              {addUser && (
                <Modal
                  title="Add User"
                  centered
                  open={addUser}
                  footer={null}
                  // onOk={handleOk}
                  // confirmLoading={confirmLoading}
                  onCancel={() => openAddUser(false)}
                >
                  <AddUserToGroup
                    openAddUser={openAddUser}
                    userData={groupMembers}
                    groupId={state.groupId}
                    groupMembersTableData={groupMembersTableData}
                  />
                </Modal>
              )}
            </Col> */}
          </Row>
          {/* <Modal
            title={
              <span>
                <Image src={delete_new} preview={false} /> &nbsp;&nbsp;
                <span style={{ fontSize: "17px" }}>
                  Confirmation to remove user from Group
                </span>
              </span>
            }
            open={deleteModel}
            onOk={deleteModel}
            onCancel={() => openDeleteModel(false)}
            footer={false}
          >
            <div>
              <div>
                Are you sure?. Did you like to remove this user from Group
                means, Please enter the user Email which one are you want
                remove.
              </div>
              <Form
                className="mt-10 create-role"
                onFinish={deleteUser}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row gutter={[16, 8]}>
                  <Col span={24}>
                    <Form.Item
                      name="group_member"
                      label="User Email"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter User Email!",
                        },
                      ]}
                    >
                      <Input placeholder="" className="" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" align="middle" className="mt-10">
                  <Col
                    span={24}
                    align="middle"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <Col span={6} style={{ marginRight: 5 }}>
                      <CancelButton onClick={() => openDeleteModel(false)} />
                    </Col>
                    <Col span={6} style={{ marginLeft: 5 }}>
                      <DeleteButton loading={loader} htmlType="submit" />
                    </Col>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal> */}
          <div className="price-profile-content m-10">
            <Row type="flex" gutter={[14, 0]} className="m-0">
              <Col span={24}>
                <Tabs items={items} />
              </Col>
            </Row>
          </div>
        </div>
      )}
      {selectedContract && (
        <div className="pipeline-click">
          <Row
            align="top"
            type="flex"
            gutter={[8, 0]}
            className="m-10"
            style={{ display: "none" }}
          >
            <Col className="" xs={24} sm={24} md={23} lg={23} xl={24}>
              <div className="pipeline-breadcrumb mt-10">
                <span>
                  <Image
                    src={arrow_left}
                    style={{ width: 25 }}
                    preview={false}
                    onClick={() => {
                      setHeaderChanger({
                        header: "systemSetup",
                        headerItem: "1",
                        headerItemName: "systemSetup",
                      });
                      setSelectedContract();
                    }}
                    className="cursor-pointer"
                  />
                  <span className="breadcrumb-path">
                    {finalPath &&
                      finalPath.map((item, i) => {
                        return (
                          <>
                            <span key={item.id} className="path-text-pipeline">
                              {item.name === "home" ? "Suppliers" : item.name}
                            </span>
                            <span
                              style={{
                                display:
                                  i === finalPath.length - 1
                                    ? "none"
                                    : "inline-block",
                              }}
                              className="path-icon"
                            >
                              <RightOutlined />
                            </span>
                          </>
                        );
                      })}
                  </span>
                </span>
              </div>
            </Col>
          </Row>
          <Row type="flex" gutter={[8, 0]}>
            <Col span={24}>
              <ContractFile
                pipelinePath={finalPath}
                backButton={backButton}
                selected={selectedContract}
                changeHomeTab={changeHomeTab}
              />
            </Col>
          </Row>
        </div>
      )}
    </Layout>
  );
}

export default ViewGroup;
