import { Tabs, Divider } from "antd";
import React, { useRef, useState, useEffect } from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import { Image } from "antd"; //antd components
import FolderImg from "../../assets/images/icons/folder.svg";
import ContractTree from "./ContractTree";
import ContractFile from "./ContractFile";
import {
  SupplierTreeDataAtom,
  userDataAtom,
  ContractAddTabAtom,
  RoleAccessAtom,
} from "../../store/store";
import { treeDataApi } from "../../api/suppliersApi";
import "../../assets/style/contract.css";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
// const Sortable = createSortable();

const DraggableTabNode = ({ className, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-node-key"],
  });
  // const cursorType = isDragging ? "grabbing" : "pointer";
  const cursorType = "grabbing";
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleX: 1,
      }
    ),
    transition,
    cursor: cursorType,
  };
  return React.cloneElement(props.children, {
    ref: setNodeRef,
    style,
    ...attributes,
    ...listeners,
  });
};
const ContractTreeTab = ({ changeScreenSize }) => {
  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const setSupplierTreeData = useSetRecoilState(SupplierTreeDataAtom);
  const roleUser = useRecoilState(RoleAccessAtom);
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom);
  const [contractAddTab, setContractAddTab] = useState([]);
  var arrayNewPans = useRef([]);
  var arrayNewPans1 = useRef([]);
  const [isFirstTabDraggable, setIsFirstTabDraggable] = useState(false);
  // useRecoilState(ContractAddTabAtom);
  // const [contractAddTab, setContractAddTab] =
  // useRecoilState(ContractAddTabAtom);
  const [activeKey, setActiveKey] = useState("1");
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setContractAddTab((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        if (activeIndex === 0 || overIndex === 0) {
          return prev;
        }
        let div = arrayMove(prev, activeIndex, overIndex);
        let newDiv = [];
        console.log("div=", div);
        for (let i = 0; i < div.length; i++) {
          if (!div[i].key.includes("divider")) {
            newDiv.push(div[i]);
            newDiv.push({
              key: "divider" + Math.random(),
              label: <Divider className="tab-divider" type="vertical" />,
              disabled: true,
            });
          }
        }
        arrayNewPans.current = newDiv;
        return newDiv;
      });
      console.log("contractAddTab=", contractAddTab);
    }
  };
  const [keyItems, setKeyItems] = useState();

  var resultString;

  const tabFun = () => {
    const initialTabs =
      sessionStorage.getItem("contractTab") &&
      sessionStorage.getItem("contractTab")
        ? JSON.parse(sessionStorage.getItem("contractTab"))
        : "";
    console.log("init", initialTabs);
    const lastTabKey = initialTabs[initialTabs.length - 1];
    setKeyItems(lastTabKey?.key);
    console.log("lastTabkey", lastTabKey?.key);
    let resultString = lastTabKey?.key;
    // let numbersOnly = res?.match(/\d+/g);
    // resultString = numbersOnly?.join("");
    console.log("resultString: ", resultString);
    // return res;
  };

  const addPane = (newPane) => {
    setItems([...items, newPane]);
    setActiveKey(newPane.key);
  };

  const [items, setItems] = useState([]);
  const newTabIndex = useRef(
    // sessionStorage.getItem("contractTab")
    //   ? JSON.parse(sessionStorage.getItem("contractTab"))
    //   : ""
    resultString ? parseInt(resultString) : 2
  );

  // useEffect(() => {
  //   // console.log("items length**", items.length, "len >1****", items.length > 1);
  //   // items.map((data) => {
  //   //   if (items.length > 1 && data["key"] !== "1") {
  //   //     return (data["closable"] = true);
  //   //   } else {
  //   //     return (data["closable"] = false);
  //   //   }
  //   // });
  //   console.log("Items: ", items);
  // }, [items]);

  // useEffect(() => {
  //   console.log("Tab Atom: ", contractAddTab);
  //   sessionStorage.setItem("contractTab", JSON.stringify(contractAddTab));
  //   tabFun();
  // }, [contractAddTab]);

  useEffect(() => {
    treeDataApi(userData[0].id, "ContractTree").then((res) => {
      console.log("tree res: ", res);
      setSupplierTreeData(res);
    });
    // suppliersTreeApi().then((res) => setSupplierTreeData(res));
    if (contractAddTab.length === 0) {
      setContractAddTab([
        ...contractAddTab,
        {
          label: (
            <div className="contracts-tab-title">
              <div className="tab-title-image">
                <Image src={FolderImg} width={17} height={27} preview={false} />
              </div>
              <span>Contracts</span>
            </div>
          ),
          children: (
            <ContractTree
              changeScreenSize={changeScreenSize}
              addPane={addPane}
              addContractTab={addContractTab}
            />
          ),
          key: "1",
          closable: false,
        },
        {
          key: "divider" + Math.random(),
          label: <Divider className="tab-divider" type="vertical" />,
          disabled: true,
        },
      ]);
      arrayNewPans.current = [
        ...contractAddTab,
        {
          label: (
            <div className="contracts-tab-title">
              <div className="tab-title-image">
                <Image src={FolderImg} width={17} height={27} preview={false} />
              </div>
              <span>Contracts</span>
            </div>
          ),
          children: (
            <ContractTree
              changeScreenSize={changeScreenSize}
              addPane={addPane}
              addContractTab={addContractTab}
            />
          ),
          key: "1",
          closable: false,
        },
        {
          key: "divider" + Math.random(),
          label: <Divider className="tab-divider" type="vertical" />,
          disabled: true,
        },
      ];
    } else {
      return;
    }
    // tabFun();
  }, [contractAddTab]);

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  const changeHomeTab = () => {
    console.log("activeKey ***", localStorage.getItem("activeKey"));
    const dataArray = arrayNewPans.current;
    const index = dataArray.findIndex(
      (item) => item.key === localStorage.getItem("activeKey")
    );

    if (index !== -1) {
      // Clone the object at index and update label and children with those from '1'
      const newDataArray = [...dataArray];
      newDataArray[index] = {
        ...newDataArray[index],
        label: dataArray.find((item) => item.key === "1").label,
        children: dataArray.find((item) => item.key === "1").children,
      };
      arrayNewPans.current = newDataArray;
      setContractAddTab(newDataArray);
      console.log(newDataArray);
    }
  };
  const add = () => {
    // let newActiveKey = "";
    // if (contractAddTab.length === 1) {
    //   newActiveKey = `${newTabIndex.current++}`;
    //   console.log("newActiveKey: ", newActiveKey);
    // } else if (contractAddTab.length > 1){
    //   newActiveKey = resultString;
    // }
    document.title = `Contracts | Contract Tree | Judo`;
    const newActiveKey = `${newTabIndex.current++}`;
    console.log("newActiveKey: ", newActiveKey);
    const newPanes1 = [...contractAddTab];
    let newPanes = [...arrayNewPans.current];
    console.log("newPanes: ", newPanes);
    if (arrayNewPans.current.length <= 10) {
      // if (contractAddTab.length <= 10) {
      // newPanes.push({
      //   key: "divider" + Math.random(),
      //   label: <Divider className="tab-divider" type="vertical" />,
      //   disabled: true,
      // });
      newPanes.push({
        label: (
          <div className="contracts-tab-title">
            <div className="tab-title-image">
              <Image src={FolderImg} width={17} height={27} preview={false} />
            </div>
            <span>Contracts</span>
          </div>
        ),
        children: (
          <ContractTree
            changeScreenSize={changeScreenSize}
            addPane={addPane}
            addContractTab={addContractTab}
          />
        ),
        key: newActiveKey,
        // closable: items.length >= 1,
      });
      newPanes.push({
        key: "divider" + Math.random(),
        label: <Divider className="tab-divider" type="vertical" />,
        disabled: true,
      });
      setContractAddTab([...newPanes]);
      arrayNewPans.current = newPanes;
      // setItems(newPanes);
      console.log("contractAddTab2=", contractAddTab);
      setActiveKey(newActiveKey);
    }
    // setContractAddTab([...newPanes]);
    // // setItems(newPanes);
    // setActiveKey(newActiveKey);
  };

  // useEffect(() => {
  // localStorage.setItem("contractTab", JSON.stringify(contractAddTab));
  // });

  const addContractTab = (item, finalPath) => {
    if (
      roleUser &&
      roleUser[0] &&
      roleUser[0].page_access.contracts.contract_tree.view
    ) {
      //item ->New coming data
      console.log("FinalPath in TreeTab: ", finalPath); //final path-> existing itrm in tree
      const newActiveKey = `newTab${newTabIndex.current++}`;
      let isNewItem = false;
      let existingTab;
      // const newPanes = [...items];
      let newPanes = [...arrayNewPans.current];
      // console.log("newPanes AddContractTab: ", newPanes);
      // let sessionTabs = JSON.parse(sessionStorage.getItem("contractTab"));
      // let newPanes = [...sessionTabs];
      console.log("newPanes getItem: ", newPanes, typeof newPanes);
      if (newPanes.length === 0) {
        newPanes.push({
          label: (
            <div className="contracts-tab-title">
              <div className="tab-title-image">
                <Image src={FolderImg} width={17} height={27} preview={false} />
              </div>
              <span>Contracts</span>
            </div>
          ),
          children: (
            <ContractTree
              changeScreenSize={changeScreenSize}
              addPane={addPane}
              addContractTab={addContractTab}
            />
          ),
          key: "1",
          closable: contractAddTab.length >= 1,
        });
        newPanes.push({
          key: "divider" + Math.random(),
          label: <Divider className="tab-divider" type="vertical" />,
          disabled: true,
        });
      }
      // if() "don't delete"
      let newItem;
      if (item.hasOwnProperty("_id")) {
        newItem = item._id;
        console.log("parent_cid=", item._id);
      }
      for (let i = 0; i < arrayNewPans.current.length; i++) {
        console.log("ss", arrayNewPans.current[i].hasOwnProperty("children"));
        if (arrayNewPans.current[i].hasOwnProperty("children")) {
          if (arrayNewPans.current[i].children.hasOwnProperty("props")) {
            if (
              arrayNewPans.current[i].children.props.hasOwnProperty("selected")
            ) {
              if (
                arrayNewPans.current[i].children.props.selected.hasOwnProperty(
                  "_id"
                )
              ) {
                let existingID =
                  arrayNewPans.current[i].children.props.selected._id;
                if (newItem === existingID) {
                  isNewItem = true;
                  existingTab = arrayNewPans.current[i].key;
                }
                console.log("existingID=", existingID);
              }
            }
          }
        }
      }
      if (!isNewItem || arrayNewPans.current.length === 0) {
        // newPanes.push({
        //   key: "divider" + Math.random(),
        //   label: <Divider className="tab-divider" type="vertical" />,
        //   disabled: true,
        // });
        newPanes.push({
          label: (
            <div className="contracts-tab-title">
              <div className="tab-title-image">
                <Image src={FolderImg} width={17} height={27} preview={false} />
              </div>
              <span>
                {item.contract_name
                  ? item.contract_name
                  : item[`${item.contract_type.toLowerCase()}_name`]}
              </span>
            </div>
          ),
          children: (
            <ContractFile
              selected={item}
              fromLocation={"contractTree"}
              finalPath={finalPath}
              changeHomeTab={changeHomeTab}
            />
          ),
          key: newActiveKey,
        });
        newPanes.push({
          key: "divider" + Math.random(),
          label: <Divider className="tab-divider" type="vertical" />,
          disabled: true,
        });
      }
      // isNewItem = true;
      console.log("Newpanes after push: ", newPanes);
      setContractAddTab([...newPanes]);
      arrayNewPans.current = newPanes;
      console.log("contractAddTab1=", contractAddTab);
      console.log("arrayNewPans=", arrayNewPans);
      if (isNewItem) {
        setActiveKey(existingTab);
      } else {
        setActiveKey(newActiveKey);
      }
      // setItems(newPanes);
    }
  };

  const remove = (targetKey) => {
    console.log("Target Key: ", targetKey);
    console.log("ContractTab remove: ", contractAddTab);
    let removeLastTab = false;
    let newActiveKey = activeKey;
    let newPanes = [...contractAddTab];
    if (targetKey === newPanes[newPanes.length - 2].key) {
      removeLastTab = true;
    }
    if (contractAddTab.length > 1) {
      console.log("newPanes: ", newPanes);
      let index = 0;
      if (targetKey === activeKey) {
        index = newPanes
          .map((ele) => {
            return ele.key;
          })
          .indexOf(targetKey);
      }
      for (let i = newPanes.length - 1; i > 0; i--) {
        if (newPanes[i].key === targetKey) {
          if (targetKey != newPanes[newPanes.length - 1].key) {
            newPanes.splice(i, 2);
          } else if (targetKey === newPanes[newPanes.length - 2].key) {
            newPanes.splice(i, 1);
          }
        }
      }
      console.log("index: ", index);
      if (!removeLastTab) {
        setActiveKey(
          targetKey === activeKey ? newPanes[index].key : newActiveKey
        );
      } else {
        setActiveKey(
          targetKey === activeKey ? newPanes[index - 2].key : newActiveKey
        );
      }
      setContractAddTab([...newPanes]);
      console.log("BeforeRemovearrayNewPans=", arrayNewPans);
      arrayNewPans.current = newPanes;
      console.log("RemovearrayNewPans=", arrayNewPans);
      // console.log("newPanes in remove: ", newPanes);
      // setItems(newPanes);
      // console.log("newActiveKey: ", newActiveKey);
    }
  };

  useEffect(() => {
    console.log("activeKey: ", activeKey);
    localStorage.setItem("activeKey", activeKey);
    const activeTab = arrayNewPans.current.find((tab) => tab.key === activeKey);
    const activeTabName = activeTab
      ? activeTab.label.props.children[1].props.children
      : "Contracts";
    document.title = `${
      activeTabName === "Contracts"
        ? "Contract Tree"
        : `${activeTabName} | Contract Tree`
    } | Judo`;
  }, [activeKey]);

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
  };

  return (
    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
      <createSortable
        items={contractAddTab.map((i) => i.key)}
        strategy={horizontalListSortingStrategy}
      >
        <Tabs
          className="contracts-tree-main"
          id="contracts-tree-main-tab"
          type="editable-card"
          onChange={onChange}
          activeKey={activeKey}
          onEdit={onEdit}
          items={contractAddTab}
          renderTabBar={(tabBarProps, DefaultTabBar) => (
            <DefaultTabBar {...tabBarProps}>
              {(node) => (
                <DraggableTabNode {...node.props} key={node.key}>
                  {node}
                </DraggableTabNode>
              )}
            </DefaultTabBar>
          )}
        />
      </createSortable>
    </DndContext>
  );
};
export default ContractTreeTab;
