import React, { useContext } from "react";
import "./breadcrumbs.css";
import { TabContext } from "./TabContext";
import { RightOutlined } from "@ant-design/icons";
import AccountTree from "../../assets/images/icons/account_tree.svg";
import FolderActive from "../../assets/images/icons/folder.svg";
import frameworkAgreement_active from "../../assets/images/icons/framework_agreement_selected.svg";
import supplement from "../../assets/images/icons/supplement_selected.svg";
import ccn from "../../assets/images/icons/ccn_selected.svg";
import sow from "../../assets/images/icons/sow_selected.svg";
import cr from "../../assets/images/icons/cr_selected.svg";
import others from "../../assets/images/icons/others.svg";

const Breadcrumbs = ({ tabBreadcrumbs, setTabBreadcrumbs }) => {
  const {
    tabs,
    setTabs,
    activeKey,
    breadcrumbs,
    setBreadcrumbs,
    setActiveKey,
    handleAddTab,
    handleRemoveTab,
  } = useContext(TabContext);

  const getNodeIcon = (nodeType) => {
    switch (nodeType) {
      case "SupplierTree":
        return (
          <img
            src={AccountTree}
            alt="Suppliers"
            style={{ marginRight: 8, width: 18, height: 18 }}
          />
        );
      case "Supplier":
        return (
          <img
            src={FolderActive}
            style={{ width: 14, height: 14, marginRight: 8 }}
            alt="Folder Icon"
          />
        );
      case "MSA":
        return (
          <img
            src={frameworkAgreement_active}
            style={{ width: 20, height: 16, marginRight: 7 }}
            alt="Folder Icon"
          />
        );
      case "Supplement" || "Sub-Supplement":
        return (
          <img
            src={supplement}
            style={{ width: 22, height: 22, marginRight: 6 }}
            alt="Folder Icon"
          />
        );
      case "CCN":
        return (
          <img
            src={ccn}
            style={{ width: 25, height: 25, marginRight: 6 }}
            alt="Folder Icon"
          />
        );
      case "SOW":
        return (
          <img
            src={sow}
            style={{ width: 20, height: 20, marginRight: 6 }}
            alt="Folder Icon"
          />
        );
      case "CR":
        return (
          <img
            src={cr}
            style={{ width: 20, height: 20, marginRight: 6 }}
            alt="Folder Icon"
          />
        );
      default:
        return (
          <img
            src={others}
            style={{ width: 15, height: 15, marginRight: 6 }}
            alt="Folder Icon"
          />
        );
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {tabBreadcrumbs.map((breadcrumb, index) => {
        const isLast = index === tabBreadcrumbs.length - 1;
        return (
          <span
            key={breadcrumb.title}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span
              className="breadcrumb-title"
              onClick={() => {
                if (isLast) {
                  return;
                }
                setTabBreadcrumbs((prev) => prev.slice(0, index + 1));
              }}
            >
              {getNodeIcon(
                breadcrumb.title === "Suppliers"
                  ? "SupplierTree"
                  : breadcrumb.apiArgs.type
              )}
              {breadcrumb.title}
            </span>
            {!isLast && (
              <RightOutlined style={{ margin: "0 8px", fontSize: 12 }} />
            )}
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
