import React from "react";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router";
import axios from "axios";
import key from "../store/AtomAndSelectorKeys";
import { refreshTokenApi } from "../api/usersApi";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const axiosInstanceUpload = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { "Content-Type": "multipart/form-data" },
});

axiosInstance.interceptors.request.use(async (config) => {
  let token = JSON.parse(localStorage.getItem(key.USER_TOKENS))?.access?.token;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});
axiosInstance.interceptors.response.use(null, ({ response }) => {
  if (response && response.data) {
    if (response.status === 401 || response.status === 403) {
      localStorage.clear();
      window.location = window.location.protocol + "//" + window.location.host;
    }
    return Promise.reject(response.data);
  }
});
let intervalCounter = false;
setInterval(() => {
  intervalCounter = true;
}, 1500000);
document.addEventListener("click", () => {
  if (intervalCounter) {
    intervalCounter = false;
    let refreshToken = JSON.parse(localStorage.getItem(key.USER_TOKENS))
      ?.refresh?.token;
    refreshTokenApi(refreshToken).then((tokens) =>
      localStorage.setItem(key.USER_TOKENS, JSON.stringify(tokens))
    );
  }
});
export const sessionStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }
    onSet((newValue) => {
      localStorage.setItem(key, JSON.stringify(newValue));
    });
  };
export const CheckAuth = () => {
  if (sessionStorage.getItem("isAuthorized")) {
    return true;
  }
  return false;
};

export const PrivateRoutes = () => {
  let isAuthenticated = JSON.parse(localStorage.getItem(key.USER_TOKENS))
    ?.access?.token;
  return <>{isAuthenticated ? <Outlet /> : <Navigate to="/" />}</>;
};

export const PublicRoutes = () => {
  let isAuthenticated = JSON.parse(localStorage.getItem(key.USER_TOKENS))
    ?.access?.token;
  return (
    <>{isAuthenticated ? <Navigate to="/user-dashboard" /> : <Outlet />}</>
  );
};

export const airflowCredential = {
  username: "admin",
  password: "admin",
};

export const dataTable = "data-table";
export const dataTableForm = "data-table-form";
export const setupDataTableForm = "setup-data-table-form";
